import useLangAuth from '@hooks/useLangAuth';
import useBlockScroll from '@utility/useBlockScroll';
import FocusTrap, { Props as FocusTrapProps } from 'focus-trap-react';
import { motion } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Close from '../../images/svg-icons/x-icon2.svg';
import Close2 from '../../images/svg-icons/x-icon2.svg';

type ModalProps = {
  children: React.ReactNode;
  close: () => void;
  show: boolean;
  blur?: 'always' | 'desktop' | 'mobile';
  blurIntensity?: '07' | null;
  className?: string;
  contentClassName?: string;
  animateSlide?: boolean;
  animateSlideY?: boolean;
  closeAriaLabel?: string;
  hideX?: boolean;
  focusTrapOptions?: Partial<FocusTrapProps['focusTrapOptions']>;
  eventCloseMenuOnClick?: () => void;
  isCloseIcon2?: boolean;
  isCloseIconSquare?: boolean;
  variantSessionExpired ?: boolean;
};

const variants = {
  initial: {
    x: '100%',
    opacity: 0,
  },
  enter: {
    x: '0%',
    opacity: 1,
  },
  exit: {
    x: '100%',
    opacity: 0,
  },
};

const variantsVertical = {
  initial: {
    y: '100%',
    opacity: 0,
  },
  enter: {
    y: '0%',
    opacity: 1,
  },
  exit: {
    y: '100%',
    opacity: 0,
  },
};

export const Modal = (props: ModalProps): React.ReactElement => {
  const closeButtonRef = useRef<HTMLButtonElement>();
  // const modalRef = useRef<HTMLDivElement>();
  useBlockScroll(props.show);

  const langKeys = ['CLOSE_MODAL'] as const;

  const labels = useLangAuth(langKeys);

  useEffect(() => {
    if (props.show)
      if (!props.hideX) {
        closeButtonRef.current?.focus();
      }
  }, [props.show]);

  const printVariants = () => {
    let variantsRet = {};
    if (props.animateSlide) variantsRet = variants;
    if (props.animateSlideY) variantsRet = variantsVertical;

    return variantsRet;
  };

  return (
    props.show && (
      <FocusTrap
        active={props.show}
        focusTrapOptions={{
          fallbackFocus: 'body',
          allowOutsideClick: true,
          ...props?.focusTrapOptions,
        }}
      >
        <motion.div
          role="dialog"
          aria-modal="true"
          className={'modal' + (props.className ? ' ' + props.className : '')}
          // ref={modalRef}
          variants={printVariants()}
          initial="initial"
          animate="enter"
          exit="exit"
          transition={{ ease: 'easeInOut', duration: 0.3 }}
        >
         {!props.variantSessionExpired && <CloseButton {...props} labels={labels} closeButtonRef={closeButtonRef}/>}
          <div className="modal__content-container">
            <div
              className={'modal__content ' + props.contentClassName}
              onClick={props.eventCloseMenuOnClick}
            >
               {props.variantSessionExpired && <CloseButton {...props} labels={labels} closeButtonRef={closeButtonRef}/>}
              {props.children}
            </div>
          </div>
        </motion.div>
      </FocusTrap>
    )
  );
};

type CloseButtonProps =  Pick<ModalProps,
  "hideX"| "isCloseIconSquare" | "closeAriaLabel" | "close" | "isCloseIcon2">

export const CloseButton = (
  props: CloseButtonProps & {
    labels?: Record<string[number], string>,
    closeButtonRef?: React.MutableRefObject<HTMLButtonElement>
  }): React.ReactElement =>{

  return (
    <button
      className={
        'button-no-back modal__close' +
        (props.hideX ? ' modal__close--hide' : '') +
        (props.isCloseIconSquare ? ' modal__close--filter' : '')
      }
      aria-label={(!props.hideX && props.closeAriaLabel) || props.labels?.CLOSE_MODAL}
      onClick={props.close}
      ref={props.closeButtonRef}>
      {(props.isCloseIcon2 || props.isCloseIconSquare) ? <Close2 /> : <Close />}
    </button>
  )
}

Modal.CloseButton = CloseButton

Modal.defaultProps = {
  contentClassName: '',
  blur: null,
  animateSlideY: false,
};
export default Modal;
