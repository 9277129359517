/*
 *** Create _cta.scss into src/css/partials_v3 and append to _custom.scss

 @import 'partials_v3/_cta.scss';

 */


import React, {
	forwardRef, Ref,
	useImperativeHandle,
	useState
} from 'react';
import ButtonV2 from "@components/UI/ButtonV2";
import { Redirect } from 'react-router-dom';
import {TCMSComponent} from "@components/CMSNewPages/CMSTypes";
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';

export enum ECTATypes{
	BUTTON="button",
	BUTTON_ARROW_RIGHT="button-arrow-right",
	BUTTON_ARROW_LEFT="button-arrow-left",
	LINK="link",
	LINK_ARROW_RIGHT="link-arrow-right",
	LINK_ARROW_LEFT="link-arrow-left",
}

type TCTATypes = `${ECTATypes}`

type CTAPropsCommon = {
	label?: string,
	type?: TCTATypes,
	children?: string | JSX.Element
	small?: boolean,
} & Partial<TCMSComponent>

type CTAProps = CTAPropsCommon & (
	| {
	action: string,
	target: "_self" | "_blank"
	onClick?: never,
}
	|{
	action?: never,
	target?: never
	onClick: ()=> void,
})


export interface CTAActions{
	triggerAction: ()=> void
}

/**
 * It's a CTA capable to change its aspect varying prop 'type'
 * and can perform an action using:
 * - action props as link to open a window OR
 * - onClick props as callback
 *
 * <b>CANNOT USE action and onClick props together, they are mutually exclusive </b>
 *
 * @param {string} label - Label to print
 * @param {TCTATypes} [type="button"] - Type as button or link variant (eventually with arrow icon)
 * @param {string} [action] - Link to open (externally o redirect depending of target)
 * @param {"_self" | "_blank"} [target="_self"] - Target of action (default redirect on top). On _self redirect pushing state
 * @param {onClick} [onClick] - Callback called when CTA is clicked
 * @param {Ref<CTAActions>} [ref] - Ref to CTA that encapsulate CTAActions
 * @returns {CTAActions} triggerAction
 * */
const CTA = (props: CTAProps, ref: Ref<CTAActions>)=>{
	const [bToRedirect, setToRedirect] = useState<boolean>(false)

	const triggerAction = ()=>{
		// debugger
		if(props?.action){
			switch (props.target) {
				case "_blank": return window.open(props?.action, "_blank")
				case "_self": return window.open(props?.action, "_self")
				default: return setToRedirect(true)
			}
		}

		if(props?.onClick) return props?.onClick()

	}

	useImperativeHandle(ref,() => ({
		triggerAction
	}))

	let propsBtn = null
	switch (props.type){
		case ECTATypes.BUTTON_ARROW_RIGHT 	:{ propsBtn = {variant:"primary", showArrowRight:true}; break }
		case ECTATypes.BUTTON_ARROW_LEFT 	:{ propsBtn = {variant:"primary", showArrowLeft:true}; break }
		case ECTATypes.LINK         		:{ propsBtn = {variant:"text-btn-no-arrow"}; break }
		case ECTATypes.LINK_ARROW_LEFT   	:{ propsBtn = {variant:"text-btn", showArrowLeft:true, 	leadingIcon: true }; break }
		case ECTATypes.LINK_ARROW_RIGHT   	:{ propsBtn = {variant:"text-btn", showArrowRight:true, leadingIcon: false }; break }
		case ECTATypes.BUTTON       		:
		default                     		:{ propsBtn = {variant:"primary"}; break }
	}

	if(bToRedirect) return <Redirect push={props?.action}/>
	if(props?.label === null && props?.action === null) return <></>
	return (
		<ButtonV2 className={"cta"} handleClick={()=>{buildClickDataTrackingObject(camelCase(props?.children?.props?.children));triggerAction()}} small={props.small}  {...propsBtn}>
			{props?.label}{props?.children}
		</ButtonV2>
	)
}


export default forwardRef(CTA);