export interface Row {
    extendedFields: ExtendedFieldsRow;
    title: string;
    contentId?: number;
}

interface Placements {
    [row: string]: Array<Row>;
}


export interface ExtendedFieldRowFilter {
    externalReference: string;
    value: string;
}
interface ExtendedFieldRowFilterNumber {
    value: number;
}

interface ExtendedFieldsRow {
    SubBrand?: Array<ExtendedFieldRowFilter>;
    Status?: Array<ExtendedFieldRowFilter>;
    L1?: Array<ExtendedFieldRowFilter>;
    ElementNumber?: Array<ExtendedFieldRowFilterNumber>;
    Tipology?: Array<ExtendedFieldRowFilter>;
    Ordinamento?: Array<ExtendedFieldRowFilter>;
    Duration?: Array<ExtendedFieldRowFilter>;
    Chip?: Array<ExtendedFieldRowFilter>;
    Badge?: Array<ExtendedFieldRowFilter>;
    title: string;
    content1?: any[];
    content2?: any[];
}

interface ExtendedFieldsHeader {
    data: string;
    alt: string;
}

interface ExtendedFieldsRes {
    MainColor: string;
    StartColor: string;
    EndColor: string;

    Logo?: Array<ExtendedFieldsHeader>;
    Banner?: Array<ExtendedFieldsHeader>;
}

export class CMSBrandInfo {
    pageLayout: 'Brand_PLP' | 'Brand_CLP' | 'Default_PLP';
    placements: Placements = { row1: [] };
    title: string;
    htmlDescription: string;
    extendedFields: ExtendedFieldsRes;
    l1?: string;
    contentId?: number
}

export class CMSCarouselSubscription {
    title: string;
    htmlDescription: string;
    extendedFields: ExtendedFieldsRow;
}