import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
const axios = require('axios');
import { getHeaders, scrollCourseDetailToTop } from '../../utility/Api';
import { showError } from './utilsActions';
import {
  TrainingPill,
  ProductCloseUp,
  ProductCMS,
  SORTBY_TP,
  ResponseGetProducts,
  ResponseGetProductsItems,
  TrainingPillResponse,
  SingleWeekSummary,
  ResponseGetFilterItems,
  INITIAL_FILTER_NOVELTIES_STORY_PLP,
} from '@model/ProductClass';
import {
  getTrainigPillsWeeklySummaryMock,
  handleTrainingPillsImages,
  sanitizePCHWraps,
  setWishListTPMock,
} from '@utility/TrainingPillsUtility';
import { queryParams, TP_CATALOGUE_PAGINATION } from '@utility/const';
import { isWishlistPage, styledLogUtagView, USER_URLS } from '@components/link-utils';
import { addPreviouslySearched } from '.';
import { getBrandIdFromL1Id, getL1IdFromBrandId, getLevelLabel } from '@utility/levelUtility';
import {
  getBrandCampaignMocoData,
  getBrandCampaignsMock,
  getBrandCollectionCatalogueMock,
  getCollectionInfoMock,
  getCollectionStoriesMock,
  getLatestCollectionsCatalogueMock,
  getNoveltiesMock,
  transformNoveltiesIntoTrainingPills,
} from '@utility/CollectionsUtility';
import {
  BrandCampaignsBannerResponse,
  BrandCampaignsByCollection,
  BrandCampaignsMap,
  CollectionInfoResponse,
  CollectionStories,
  CollectionStoriesResponse,
  LatestCollectionCarouselModel,
  LatestCollectionCarouselsModel,
  NoveltyByBrand,
} from '@model/collections/CollectionModel';
import history from '../../history';
import { store } from '../store';
import { HPCarouselCollections } from '@hooks/useHomepageCarousels';
import { triggerTrackEvent } from '@utility/analytics-utils';
import { LevelEnrichedMap } from '@model/CoursesClass';

export const getTrainingPillsHP = (minQuantity = 12) => {
  return dispatch => {
    if (minQuantity < 13) {
      minQuantity = 13;
    } else {
      minQuantity++;
    }

    let url = urlList.GET_TRAINING_PILLS_SEARCH;
    url += '?start=0';
    url += '&recordPerPage=' + minQuantity.toString();

    let payload: ResponseGetProductsItems = {
      sortings: SORTBY_TP.MOST_RECENT_SHIPMENT,
    };

    dispatch(request());
    axios({
      url,
      method: 'POST',
      headers: getHeaders(),
      data: payload,
    })
      .then(response => {
        let trainingPillsHP: TrainingPill[] = response.data?.searchFields || [];
        trainingPillsHP.forEach((pill, index) => {
          pill.positionInCarousel = index + 1;
        });
        trainingPillsHP = handleTrainingPillsImages(trainingPillsHP);

        dispatch(success(trainingPillsHP));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TP_HP_REQUEST,
    };
  }

  function success(trainingPillsHP) {
    return {
      type: actionTypes.GET_TP_HP_SUCCESS,
      trainingPillsHP,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TP_HP_FAILURE,
    };
  }
};

export const getTrainingPillDetail = (model: string, history) => {
  return (dispatch, getState) => {
    let url = urlList.GET_NOVELTY_DETAIL;
    url = url.replace('{modelId}', model);

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        // setTimeout(() => {
        // fetchTrainingPillsDetail().then(result => {
        // let response = { data: result };
        const trainingPillDetail: ProductCMS = response.data;

        //if training pill detail is empty && user is TP detail page
        if (
          !trainingPillDetail &&
          window.location.pathname === USER_URLS.DETAILS_TP.URL.replace(':tpId', model)
        ) {
          //product not found --> go to 404 page
          dispatch(success(model, false));
          return;
        }

        //replace <CRLF> characters with </br>
        if (trainingPillDetail?.keySellingPoints?.KeySellingPoint1) {
          trainingPillDetail.keySellingPoints.KeySellingPoint1 = sanitizePCHWraps(trainingPillDetail?.keySellingPoints?.KeySellingPoint1);
        }
        if (trainingPillDetail?.keySellingPoints?.KeySellingPoint2) {
          trainingPillDetail.keySellingPoints.KeySellingPoint2 = sanitizePCHWraps(trainingPillDetail?.keySellingPoints?.KeySellingPoint2);
        }
        if (trainingPillDetail?.keySellingPoints?.KeySellingPoint3) {
          trainingPillDetail.keySellingPoints.KeySellingPoint3 = sanitizePCHWraps(trainingPillDetail?.keySellingPoints?.KeySellingPoint3);
        }
        trainingPillDetail?.mocoFromSearch?.forEach(moco => {
          if (moco.sustainabilityDescription) {
            moco.sustainabilityDescription = sanitizePCHWraps(moco.sustainabilityDescription);
          }
        });

        dispatch(success(model, trainingPillDetail))
        // })
        // }, 3000);
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TP_DETAIL_REQUEST,
    };
  }

  function success(model: string, trainingPillDetail: ProductCMS | boolean) {
    return {
      type: actionTypes.GET_TP_DETAIL_SUCCESS,
      trainingPillDetail,
      model,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TP_DETAIL_FAILURE,
    };
  }
};

export const getTrainingPillCloseUp = (model: string) => {
  return dispatch => {
    let url = urlList.GET_TRAINING_PILL_BASE_URL;

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
      params: {
        tagRef: model,
      },
    })
      .then(response => {
        const trainingPillCloseUp: ProductCloseUp = response.data;
        console.log('trainingPillCloseUp', trainingPillCloseUp);

        dispatch(success(model, trainingPillCloseUp));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TP_CLOSEUP_REQUEST,
    };
  }

  function success(model: string, trainingPillCloseUp: ProductCloseUp) {
    return {
      type: actionTypes.GET_TP_CLOSEUP_REQUEST_SUCCESS,
      trainingPillCloseUp,
      model,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TP_CLOSEUP_REQUEST_FAILURE,
    };
  }
};

export const getTrainingPillAdvCampaign = (model: string) => {
  return dispatch => {
    let url = urlList.GET_TRAINING_PILL_BASE_URL;

    const searchParams = new URLSearchParams();
    searchParams.append('tagRef', model);
    searchParams.append('tagRef', 'adv');
    searchParams.append('tagRef', 'trainingpills');

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
      params: searchParams,
    })
      .then(response => {
        const trainingPilLAdvCampaign: ProductCloseUp[] = response.data;
        console.log(`trainingPilLAdvCampaign`, trainingPilLAdvCampaign);
        dispatch(success(model, trainingPilLAdvCampaign));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TP_ADVCAMPAIGN_REQUEST,
    };
  }

  function success(model: string, trainingPillAdvCampaign: ProductCloseUp[]) {
    return {
      type: actionTypes.GET_TP_ADVCAMPAIGN_REQUEST_SUCCESS,
      trainingPillAdvCampaign,
      model,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TP_ADVCAMPAIGN_REQUEST_FAILURE,
    };
  }
};

export const toggleWishTP = (
  trainingPill: TrainingPill,
  addToWishList: boolean,
  isFromTPDetail = false,
  mock = false
) => {
  let idTP = trainingPill.model;

  return (dispatch, getState) => {
    let url = addToWishList ? urlList.SAVE_WISHLIST_TP_URL : urlList.DELETE_WISHLIST_TP_URL;

    const payload = [idTP];

    const promiseSetWishListTP = mock
      ? setWishListTPMock()
      : axios({
        url,
        method: 'POST',
        headers: getHeaders(),
        data: payload,
      });

    return promiseSetWishListTP
      .then(response => {
        const product = !mock ? response.data : response;
        // userProfile = getState().user.userProfile;
        let labelL1Map: LevelEnrichedMap = getState().utils.labelL1Map;

        dispatch(
          updateTrainingPill(
            {
              ...trainingPill,
              wishlist: addToWishList,
            },
            isFromTPDetail
          )
        );

        if(!!addToWishList) {
          let utagEventData = {
            Events_ProdFavAdd: '1',
            Products_Id_Array: [trainingPill?.model?.toLowerCase()],
            Products_Category_Array: [(labelL1Map?.["brand_" + trainingPill?.brandString]?.label)?.toLowerCase()],
            Products_ModelName_Array: [trainingPill?.modelName?.toLowerCase()],
            Products_Language_Array: [undefined],
            Products_Mandatory_Array: [undefined]
          }

          triggerTrackEvent(utagEventData),
          styledLogUtagView("Add to favorite pill", utagEventData)
        }

        //update TP filter if the user is in wishlist page
        if (isWishlistPage(location.pathname)) {
          dispatch(setForceFilterUpdate(true));
        }

        return product;
      })
      .catch(err => {
        dispatch(showError(err));
        return Promise.reject(err);
      });
  };
};

export const getTrainingPillsBrandCarousel = (brands: string[] = []) => {
  return dispatch => {
    if (!brands) {
      console.error('getTrainingPillsBrandCarousel brands not populated');
      return;
    }

    let url = urlList.GET_TRAINING_PILLS_SEARCH;
    url += '?start=0';
    url += '&recordPerPage=13';

    let payload: ResponseGetProductsItems = {
      sortings: SORTBY_TP.MOST_RECENT_SHIPMENT,
      brands: brands.map(brand => {
        return {
          id: getBrandIdFromL1Id(brand),
          checked: true,
          disabled: false,
        };
      }),
    };

    dispatch(request());
    axios({
      url,
      method: 'POST',
      headers: getHeaders(),
      data: payload,
    })
      .then(response => {
        // setTimeout(() => {
        //     fetchTrainingPillsBrandCarousel().then(results => {
        //         for (let result of results.items) {
        //             result.brandName = brand;
        //         }
        //         let response = { data: results };
        let trainingPillsBrandCarousel: TrainingPill[] = response.data?.searchFields;
        trainingPillsBrandCarousel = handleTrainingPillsImages(trainingPillsBrandCarousel);

        dispatch(success(trainingPillsBrandCarousel));
        // })
        // }, 3000);
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TP_BRAND_REQUEST,
    };
  }

  function success(trainingPillsBrandCarousel) {
    return {
      type: actionTypes.GET_TP_BRAND_SUCCESS,
      trainingPillsBrandCarousel,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TP_BRAND_FAILURE,
    };
  }
};

export const resetTrainingPillsBrandCarousel = () => {
  return {
    type: actionTypes.RESET_TP_BRAND,
  };
};

export const setTPCatalogueTitle = (
  TPCatalogueTitle: string,
  TPCatalogueDescription: string = ''
) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_TP_CATALOGUE_TITLE,
      TPCatalogueTitle: TPCatalogueTitle,
      TPCatalogueDescription,
    });
  };
};

export const getTrainingPillsCatalogue = (
  filters: ResponseGetProducts,
  from: number,
  totalTrainingPills: number = null,
  searchText: string = '',
  isNovelties: boolean = false
) => {
  return dispatch => {
    let url = isNovelties ? urlList.GET_NOVELTIES_SEARCH : urlList.GET_TRAINING_PILLS_SEARCH;
    url += isNovelties ? '?startNumber=' + from : '?start=' + from;
    url += (isNovelties ? '&recordForPage=' : '&recordPerPage=') + TP_CATALOGUE_PAGINATION;

    if (searchText) {
      url += '&text=' + searchText;
    }

    let to = from + TP_CATALOGUE_PAGINATION;
    if (totalTrainingPills && to > totalTrainingPills) {
      to = totalTrainingPills;
    }

    let payload: ResponseGetProductsItems = filters.items;

    dispatch(
      isNovelties ? getNoveltiesCatalogueRequest(from) : getTrainingPillsCatalogueRequest(from)
    );
    axios({
      url,
      method: 'POST',
      headers: getHeaders(),
      data: payload,
    })
      .then(response => {
        // setTimeout(() => {
        //set mocked data
        // const results = cloneDeep(TP_CATALOGUE_MOCK);
        // results.items = results.items.slice(0, to - from);
        // results.items = results.items.map((a, i) => { return { ...a, model: a.model + '_' + (from + i) } });
        // let response = { data: results };

        let trainingPillsCatalogue: TrainingPill[] = response.data?.searchFields;

        if (!trainingPillsCatalogue) {
          dispatch(showError(''));
          dispatch(
            isNovelties ? getNoveltiesCatalogueFailure() : getTrainingPillsCatalogueFailure()
          );
        }

        if (isNovelties) {
          //convert novelties attributes into pills attributes
          // const mockTP = [
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/b0337399-b33f-4809-a19a-afe300f94244/9ea925d7-771e-44e6-9e1e-afe401745457/0RX7228__8198__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/b0337399-b33f-4809-a19a-afe300f94244/a1f4f308-0c15-425b-aac4-afe300f942b7/0RX7228__8198.jpg",
          //     code: "0RX72289999",
          //     modelName: "",
          //     advertising: false,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CASUAL CLASSIC",
          //     model: "",
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/8acded24-b1c8-4677-8746-afe300f92a73/95f35505-780c-49a5-a0eb-afe40174d857/0RX7227__8313__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/8acded24-b1c8-4677-8746-afe300f92a73/d14437b8-ff17-419c-9202-afe300f92ae6/0RX7227__8313.jpg",
          //     code: "0RX7227",
          //     modelName: "",
          //     advertising: true,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CASUAL CLASSIC",
          //     model: "",
          //     brandCampaign: ["Ray-Ban"]
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/c7e76e5e-98d7-422e-b194-afe30120df42/34507b29-def9-48d6-b02d-afe500088427/0RX7229__8210__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/c7e76e5e-98d7-422e-b194-afe30120df42/4534c338-9b41-4dfe-9a89-afe30120dfc5/0RX7229__8210.jpg",
          //     code: "0RX7229",
          //     modelName: "",
          //     advertising: true,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CLUBMASTER FAMILY",
          //     model: "",
          //     brandCampaign: ["Brand Campaign w/30 characters"]
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1cbe1710-6adf-4f65-9c15-afe40174f2bf/c645b4fe-9687-45c3-8385-afe40174f8a6/0RX7230__5204__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1cbe1710-6adf-4f65-9c15-afe40174f2bf/234c26c3-7cbd-4188-90f9-afe40174f325/0RX7230__5204.jpg",
          //     code: "0RX7230",
          //     modelName: "",
          //     advertising: true,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "LITEFORCE",
          //     model: "",
          //     brandCampaign: ["Ray-Ban", "Brand Campaign w/30 characters"]
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/a01ca8b5-9b39-4029-8a6f-afe300a4c345/130ab8ef-48cd-4b76-8ffc-afe400b30947/0RB4429__601_31__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/a01ca8b5-9b39-4029-8a6f-afe300a4c345/07e69466-ce35-4224-b1ed-afe300a4c3b2/0RB4429__601_31.jpg",
          //     code: "0RB4429",
          //     modelName: "",
          //     advertising: true,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CLUBMASTER FAMILY",
          //     model: "",
          //     brandCampaign: ["Brand Campaign w/30 characters", "Ray-Ban"]
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/9e4974ce-93c4-4ea3-b993-ae750175ccb3/361b97f5-b9ba-4b2f-80a4-ae7501856683/0RX6493__2944__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/9e4974ce-93c4-4ea3-b993-ae750175ccb3/ad307d45-16df-465c-a68f-ae750175cd69/0RX6493__2944.jpg",
          //     code: "0RX6493",
          //     modelName: "",
          //     advertising: true,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CASUAL CLASSIC",
          //     model: "",
          //     brandCampaign: ["Ray-Ban", "Ray-Ban", "Ray-Ban"]
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/89c2526a-4c1f-4678-90a8-ae7d00604ebc/8dca85c7-551f-4c71-9dc3-ae7d00b8bcd2/0RX5428__2034__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/89c2526a-4c1f-4678-90a8-ae7d00604ebc/ac621505-fee6-4b31-96b0-ae7d00604f94/0RX5428__2034.jpg",
          //     code: "0RX5428",
          //     modelName: "",
          //     advertising: false,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CASUAL CLASSIC",
          //     model: "",
          //     brandCampaign: ["Brand Campaign w/30 characters", "Brand Campaign w/30 characters", "Brand Campaign w/30 characters"]
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/efc990b9-8cf4-469e-9a9e-afe300f8ff77/4fddbb99-c900-4c1f-afdc-afe40173b9ba/0RX6513__3135__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/efc990b9-8cf4-469e-9a9e-afe300f8ff77/1aa89f25-78d9-401b-a30a-afe300f8ffc8/0RX6513__3135.jpg",
          //     code: "0RX6513",
          //     modelName: "",
          //     advertising: true,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "LITEFORCE",
          //     model: "",
          //     brandCampaign: ["Brand Campaign w/30 characters", "Brand Campaign w/30 characters", "Brand Campaign w/30 characters"]
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1e43b0c1-29a5-4018-a8f3-afe300a4b9bc/2a42b314-b541-41e4-8284-afe400b48017/0RB4428__894_57__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1e43b0c1-29a5-4018-a8f3-afe300a4b9bc/a08b049c-eda0-4e75-8bad-afe300a4ba45/0RB4428__894_57.jpg",
          //     code: "0RB4428",
          //     modelName: "",
          //     advertising: false,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CASUAL CLASSIC",
          //     model: ""
          //   },
          //   {
          //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/19c51e94-bcc2-43fa-ac32-afe300a51746/87ab8791-7cf0-4569-bf75-afe400ae1138/0RB2204__901_32__P21__shad__fr.png?imwidth=300",
          //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/19c51e94-bcc2-43fa-ac32-afe300a51746/6c68ae7b-97e8-4198-a6b0-afe300a517d3/0RB2204__901_32.jpg",
          //     code: "0RB2204",
          //     modelName: "",
          //     advertising: false,
          //     brandName: "Ray-Ban",
          //     brand: "RB",
          //     marketingTheme: "CASUAL CLASSIC",
          //     model: ""
          //   },
          // ]
          //const mockForBC = [...mockTP, ...trainingPillsCatalogue.slice(0, 10)]
          trainingPillsCatalogue = transformNoveltiesIntoTrainingPills(trainingPillsCatalogue);
        }

        //if the BE returns more than expected
        trainingPillsCatalogue = trainingPillsCatalogue?.slice(0, to - from); //should be useless
        const trainingPillsCatalogueTotalNumber: number =
          response.data?.responseFilter?.elementsNum;
        if (!isNovelties) {
          //do not modify novelties images
          trainingPillsCatalogue = handleTrainingPillsImages(trainingPillsCatalogue);
        }

        if (to > trainingPillsCatalogueTotalNumber) {
          to = trainingPillsCatalogueTotalNumber;
        }

        dispatch(
          isNovelties
            ? getNoveltiesCatalogueSuccess(
              trainingPillsCatalogue,
              from,
              to,
              trainingPillsCatalogueTotalNumber
            )
            : getTrainingPillsCatalogueSuccess(
              trainingPillsCatalogue,
              from,
              to,
              trainingPillsCatalogueTotalNumber
            )
        );
        // }, 3000);
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(isNovelties ? getNoveltiesCatalogueFailure() : getTrainingPillsCatalogueFailure());
      });
  };
};

export const getTrainingPillsCatalogueRequest = (trainingPillsCatalogueFromNumber: number) => {
  return {
    type: actionTypes.GET_TP_CATALOGUE_REQUEST,
    trainingPillsCatalogueFromNumber,
  };
};

export const getTrainingPillsCatalogueSuccess = (
  trainingPillsCatalogue: TrainingPill[],
  trainingPillsCatalogueFromNumber: number,
  trainingPillsCatalogueToNumber: number,
  trainingPillsCatalogueTotalNumber: number
) => {
  return {
    type: actionTypes.GET_TP_CATALOGUE_SUCCESS,
    trainingPillsCatalogue,
    trainingPillsCatalogueFromNumber,
    trainingPillsCatalogueToNumber,
    trainingPillsCatalogueTotalNumber,
  };
};

export const getTrainingPillsCatalogueFailure = () => {
  return {
    type: actionTypes.GET_TP_CATALOGUE_FAILURE,
  };
};

export const resetTrainingPillsCatalogue = () => {
  return {
    type: actionTypes.RESET_TP_CATALOGUE,
  };
};

export const resetSearchTrainingPillsCatalogue = (isNovelties: boolean = false) => {
  return (dispatch, getState) => {
    let searchProducts = isNovelties
      ? getState().search.searchNovelties
      : getState().search.searchTrainingPills;
    if (!searchProducts) {
      searchProducts = new TrainingPillResponse();
    }
    const to =
      TP_CATALOGUE_PAGINATION > searchProducts.elementsNum
        ? searchProducts.elementsNum
        : TP_CATALOGUE_PAGINATION;

    dispatch(
      isNovelties
        ? getNoveltiesCatalogueSuccess(searchProducts.items, 0, to, searchProducts.elementsNum)
        : getTrainingPillsCatalogueSuccess(searchProducts.items, 0, to, searchProducts.elementsNum)
    );
  };
};

export const getNoveltiesCatalogueRequest = (noveltiesCatalogueFromNumber: number) => {
  return {
    type: actionTypes.GET_NOVELTIES_CATALOGUE_REQUEST,
    noveltiesCatalogueFromNumber,
  };
};

export const getNoveltiesCatalogueSuccess = (
  noveltiesCatalogue: TrainingPill[],
  noveltiesCatalogueFromNumber: number,
  noveltiesCatalogueToNumber: number,
  noveltiesCatalogueTotalNumber: number
) => {
  return {
    type: actionTypes.GET_NOVELTIES_CATALOGUE_SUCCESS,
    noveltiesCatalogue,
    noveltiesCatalogueFromNumber,
    noveltiesCatalogueToNumber,
    noveltiesCatalogueTotalNumber,
  };
};

export const getNoveltiesCatalogueFailure = () => {
  return {
    type: actionTypes.GET_NOVELTIES_CATALOGUE_FAILURE,
  };
};

export const resetNoveltiesCatalogue = () => {
  return {
    type: actionTypes.RESET_NOVELTIES_CATALOGUE,
  };
};

export const openTrainingPillDetailModal = (
  showTrainingPillDetailModal: boolean = true,
  trainingPill: string = null,
  closeAll: boolean = false
) => {
  return dispatch => {
    dispatch(addPreviouslySearched());

    const url = USER_URLS.DETAILS_TP.URL.replace(':tpId', trainingPill);
    const alreadyInPage = window.location.pathname?.toLowerCase() === url.toLowerCase();

    if (showTrainingPillDetailModal && trainingPill && !alreadyInPage) {
      history.push(url);
    }

    if (!showTrainingPillDetailModal && alreadyInPage) {
      history.goBack();
    }
  };
};

export const getTrainigPillsWeeklySummary = () => {
  return dispatch => {
    let url = urlList.GET_TRAINING_PILLS_WEEK_SUMMARY_URL;
    dispatch(request());
    const mock: boolean = false;
    (mock
      ? getTrainigPillsWeeklySummaryMock()
      : axios({
        url,
        method: 'POST',
        headers: getHeaders(),
        data: [1, 4],
      })
    )
      .then(response => {
        let weeklySummary: SingleWeekSummary[] = response.data;
        dispatch(success(weeklySummary));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TP_SHIPMENT_DATES_REQUEST,
    };
  }

  function success(weeklySummary) {
    return {
      type: actionTypes.GET_TP_SHIPMENT_WEEKLY_SUMMARY_SUCCESS,
      weeklySummary,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TP_SHIPMENT_WEEKLY_SUMMARY_FAILURE,
    };
  }
};

export const updateTrainingPill = (newTp: TrainingPill, isFromTPDetail: boolean = false) => {
  return (dispatch, getState) => {
    //update TP wishlist carousel
    if (
      getState().trainingPills.isTrainingPillsWishlistCarouselCompleted &&
      window.location.pathname === USER_URLS.PROFILE.URL
    ) {
      dispatch(getTrainingPillsWishlistCarousel());
    }

    dispatch({
      type: actionTypes.UPDATE_TP,
      newTp,
      isFromTPDetail,
    });
  };
};

export const setForceFilterUpdate = (value: boolean) => {
  return dispatch => {
    dispatch({
      type: actionTypes.FORCE_FILTER_UPDATE,
      forceFilterUpdate: value,
    });
  };
};

export const getTrainingPillsWishlistCarousel = () => {
  return dispatch => {
    let url = urlList.GET_TRAINING_PILLS_SEARCH;
    url += '?start=0';
    url += '&recordPerPage=13';

    let payload: ResponseGetProductsItems = {
      sortings: SORTBY_TP.MOST_RECENT_SHIPMENT,
      status: [
        {
          id: 'wishlist',
          checked: true,
          disabled: false,
        },
      ],
    };

    dispatch(request());
    axios({
      url,
      method: 'POST',
      headers: getHeaders(),
      data: payload,
    })
      .then(response => {
        let trainingPillsHP: TrainingPill[] = response.data?.searchFields;
        trainingPillsHP = handleTrainingPillsImages(trainingPillsHP);

        dispatch(success(trainingPillsHP));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TP_WISH_CAROUSEL_REQUEST,
    };
  }

  function success(trainingPillsWishlistCarousel) {
    return {
      type: actionTypes.GET_TP_WISH_CAROUSEL_SUCCESS,
      trainingPillsWishlistCarousel,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TP_WISH_CAROUSEL_FAILURE,
    };
  }
};

export const setCollectionsBrandModalInfo = (
  show,
  collectionId = '',
  collectionName = '',
  collectionBrands = []
) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_COLLECTIONS_BRAND_MODAL_INFO,
      collectionsBrandModalInfo: {
        show: show,
        collectionName: collectionName,
        collectionId: collectionId,
        collectionBrands: collectionBrands,
      },
    });
  };
};

export const setStoryModalInfo = (show, story?) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_STORY_MODAL_INFO,
      storyModalInfo: {
        show: show,
        story: story,
      },
    });
  };
};

export const getCollectionInfo = (collectionId: string, brandId: string, language: string) => {
  return (dispatch, getState: typeof store.getState) => {
    const mock = false;

    if (language === '') {
      language = 'en';
    }

    if (!collectionId || !brandId || !language) {
      console.error('getCollectionInfo - collectionId or brandId or language not specified');
      return;
    }

    let url = urlList.GET_COLLECTION_INFO + '?release=' + collectionId + '&brand=' + brandId;

    dispatch(request(collectionId, brandId));

    (mock
      ? getCollectionInfoMock()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then(response => {
        let collection: CollectionInfoResponse = response.data?.[0];

        collection.release = collectionId;
        collection.brand = brandId;

        //replace <CRLF> characters with </br>
        if (collection.brandDescription) {
          collection.brandDescription = sanitizePCHWraps(collection.brandDescription);
        }

        console.log('getCollectionInfo', collection)
        dispatch(success(collection, collectionId, brandId, language))
      }).catch(err => {
        dispatch(showError(err));
        dispatch(failure(collectionId, brandId));
      });
  };

  function request(collectionId, brandId) {
    return {
      type: actionTypes.GET_COLLECTION_INFO_REQUEST,
      collectionId,
      brandId,
    };
  }

  function success(collection, collectionId, brandId, language) {
    return {
      type: actionTypes.GET_COLLECTION_INFO_SUCCESS,
      collection,
      collectionId,
      brandId,
      language,
    };
  }

  function failure(collectionId, brandId) {
    return {
      type: actionTypes.GET_COLLECTION_INFO_FAILURE,
      collectionId,
      brandId,
    };
  }
};

export const getBrandCampaigns = (brandId , collectionId) => {
  return (dispatch) => {
    const mock = false;
    let url = urlList.GET_BRAND_CAMPAIGNS+ '?release=' + collectionId + '&brand=' + brandId;
    dispatch(request(collectionId, brandId));
    (mock
      ? getBrandCampaignsMock()
      : axios({
          url,
          method: 'GET',
          headers: getHeaders(),
        })
    )
      .then(response => {
        let brandCampaignByCollectionResponse: BrandCampaignsByCollection[] = response.data;
        //console.log('🧩getBrandCampaigns', response.data, brandCampaignByCollectionResponse);
        response.data.forEach(bcampaign => {
          if(bcampaign.description){
            bcampaign.description = sanitizePCHWraps(bcampaign.description);
          }
        });
        dispatch(success(brandCampaignByCollectionResponse));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure(collectionId, brandId));
      });
    
      
  }
  function request(collectionId, brandId) {
    return {
      type: actionTypes.GET_BRAND_CAMPAIGNS_BY_COLLECTION_REQUEST,
      collectionId,
      brandId,
    };
  }

  function success(mapFromResponse) {
    return {
      type: actionTypes.GET_BRAND_CAMPAIGNS_BY_COLLECTION_SUCCESS,
      mapFromResponse
    };
  }
  function failure(collectionId, brandId) {
    return {
      type: actionTypes.GET_BRAND_CAMPAIGNS_BY_COLLECTION_FAILURE,
      collectionId,
      brandId,
    };
  }
}

export const getCollectionStories = (collectionId: string, brandId: string, language: string) => {
  return (dispatch, getState: typeof store.getState) => {
    const mock = false;

    if (language === '') {
      language = 'en';
    }

    if (!collectionId || !brandId || !language) {
      console.error('getCollection - collectionId or brandId or language not specified');
      return;
    }

    let url = urlList.GET_COLLECTION_STORIES + '?release=' + collectionId + '&brand=' + brandId;

    dispatch(request(collectionId, brandId));

    (mock
      ? getCollectionStoriesMock()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then(response => {
        let collectionResponse: CollectionStoriesResponse = response.data?.[0];

        let collection: CollectionStories = new CollectionStories();

        collection = {
          ...collectionResponse,
          stories: [],
        };

        collectionResponse.stories.forEach(story => {
          let novelties = [];
          //merge all novelties in a story
          story.noveltiesByBrands?.forEach(brandCollection => {
            novelties.push(...brandCollection.novelties);
          });

          //replace <CRLF> characters with </br>
          if (story.description) {
            story.description = sanitizePCHWraps(story.description);
          }

          // novelties = novelties.sort((a, b) => a.order - b.order);
          collection.stories.push({
            ...story,
            novelties: novelties,
          });
          //remove noveltiesByBrands because useless
          delete collection.stories[collection.stories.length - 1]?.noveltiesByBrands;
        });

        console.log('getCollectionStories', collection);

        dispatch(success(collection, collectionId, brandId, language));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure(collectionId, brandId));
      });
  };

  function request(collectionId, brandId) {
    return {
      type: actionTypes.GET_COLLECTION_STORIES_REQUEST,
      collectionId,
      brandId,
    };
  }

  function success(collection, collectionId, brandId, language) {
    return {
      type: actionTypes.GET_COLLECTION_STORIES_SUCCESS,
      collection,
      collectionId,
      brandId,
      language,
    };
  }

  function failure(collectionId, brandId) {
    return {
      type: actionTypes.GET_COLLECTION_STORIES_FAILURE,
      collectionId,
      brandId,
    };
  }
};

type getNoveltiesType =
  | {
    language: string;
    variant: 'carousel';
  }
  | {
    language: string;
    variant: 'carousel-cms';
    collections: HPCarouselCollections[];
  }
  | {
    language: string;
    variant: 'brand-page';
    brand: string;
  }
  | {
    language: string;
    variant: 'latest-collections-page';
  };
export const getNovelties = ({
  language,
  variant,
  brand,
  collectionId,
  pillModel,
  collections,
}: getNoveltiesType) => {
  return (dispatch, getState: typeof store.getState) => {
    const mock = false;
    let requestMethod = 'GET';
    let url = urlList.GET_LATEST_COLLECTIONS_URL;
    let payload;

    if (language === '') {
      language = 'en';
    }
    if (!language || !variant) {
      console.error('getNovelties - language or variant not specified', { language, variant });
      return;
    }

    //brands are always in uppercase
    brand = getBrandIdFromL1Id(brand)?.toUpperCase();

    switch (variant) {
      case 'carousel':
        //1 collection
        //max 5 novelties per collection
        url +=
          '?startCollection=0' +
          '&endCollection=1' +
          '&startNumber=0' +
          '&recordForPage=5' +
          // + "&houseBrand=true"
          '&byBrand=true';
        dispatch(requestLatestCollectionsCarousel());
        break;
      case 'carousel-cms':
        const mapCollectionBrand = {};
        collections?.forEach(collection => {
          const release = collection.release;
          if (!mapCollectionBrand[release]) {
            mapCollectionBrand[release] = [];
          }

          //prevent duplicates
          const index = mapCollectionBrand[release].findIndex(a => a.brand === collection.brand);
          if (index < 0) {
            mapCollectionBrand[release].push({ brand: collection.brand, order: collection.order });
          }
        });

        // mapCollectionBrand format = {
        // 0: [{brand: "RB", order: 1}, {brand: "OO", order: 1}],
        // 1: [{brand: "RB", order: 2}, {brand: "VO", order: 3}],
        // 2: [{brand: "AN", order: 15}]
        // }
        payload = { mapCollectionBrand: mapCollectionBrand };

        //get start and end collections
        const keys = Object.keys(mapCollectionBrand)?.map(a => +a);
        const startCollection = Math.min(...keys);
        const endCollection = Math.max(...keys) + 1;

        //variable number of collections
        //max 5 novelties per collection
        url =
          urlList.GET_LATEST_COLLECTIONS_DISTINCT_BY_BRAND_URL +
          '?startNumber=0' +
          '&recordForPage=5' +
          '&byBrand=true';
        requestMethod = 'POST';

        dispatch(requestLatestCollectionsCMSCarousel());
        break;
      case 'brand-page':
        if (!brand) {
          console.error('getNovelties - brand not specified', { brand });
          return;
        }
        //1 collection
        //no novelties
        url +=
          '?startCollection=0' +
          '&endCollection=1' +
          '&startNumber=0' +
          '&recordForPage=0' +
          '&brand=' +
          brand +
          '&byBrand=true';
        break;
      case 'latest-collections-page':
        //3 collections
        //no novelties
        url +=
          '?startNumber=0' +
          '&recordForPage=0' +
          '&byBrand=true';
        dispatch(requestLatestCollections());
        break;
    }

    const labelL1Map = getState().utils.labelL1Map;

    return (
      mock
        ? getNoveltiesMock()
        : axios({
          url,
          method: requestMethod,
          headers: getHeaders(),
          data: payload,
        })
    )
      .then(response => {
        let collections: LatestCollectionCarouselModel[] = manipulateCollectionsFromRequest(
          response.data,
          labelL1Map
        );

        switch (variant) {
          case 'carousel':
            //get just last collection
            const collection = collections[0];
            if (collection) {
              //if at least one house brand is present --> show only house brands
              //otherwise --> show all brands
              let houseBrands = collection.noveltiesByBrand?.filter(
                brandCollection => brandCollection.houseBrand
              );
              /* if (houseBrands?.length > 0) {
                 collection.noveltiesByBrand = houseBrands;
               }*/

              //get max 12 brand collections
              collection.noveltiesByBrand = collection.noveltiesByBrand?.slice(0, 12);
            }

            dispatch(successLatestCollectionsCarousel(collection, language));
            break;
          case 'carousel-cms':
            dispatch(successLatestCollectionsCMSCarousel(collections, language));
            break;
          case 'brand-page':
            return Promise.resolve(collections[0]?.noveltiesByBrand?.[0]);
            break;
          case 'latest-collections-page':
            let carousels: LatestCollectionCarouselsModel[] = collections;

            carousels = carousels.slice(0, 3);
            if (carousels.length > 0) {
              carousels[0].isNew = true;
            }

            dispatch(successLatestCollections(carousels, language));
            break;
        }
      })
      .catch(err => {
        dispatch(showError(err));

        switch (variant) {
          case 'carousel':
            dispatch(failureLatestCollectionsCarousel());
            break;
          case 'carousel-cms':
            dispatch(failureLatestCollectionsCMSCarousel());
            break;
          case 'brand-page':
            break;
          case 'latest-collections-page':
            dispatch(failureLatestCollections());
            break;
        }
      });
  };

  function requestLatestCollectionsCarousel() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CAROUSEL_REQUEST,
    };
  }
  function requestLatestCollectionsCMSCarousel() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CMS_CAROUSEL_REQUEST,
    };
  }
  function requestLatestCollections() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_REQUEST,
    };
  }

  function successLatestCollectionsCarousel(collection, languageParam) {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CAROUSEL_SUCCESS,
      collection,
      language: languageParam,
    };
  }
  function successLatestCollectionsCMSCarousel(collections, languageParam) {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CMS_CAROUSEL_SUCCESS,
      collections,
      language: languageParam,
    };
  }
  function successLatestCollections(latestCollections, language) {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_SUCCESS,
      latestCollections,
      language,
    };
  }

  function failureLatestCollectionsCarousel() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CAROUSEL_FAILURE,
    };
  }
  function failureLatestCollectionsCMSCarousel() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CMS_CAROUSEL_FAILURE,
    };
  }
  function failureLatestCollections() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_FAILURE,
    };
  }
};

const manipulateCollectionsFromRequest = (
  collectionsParam: LatestCollectionCarouselModel[],
  labelL1Map
): LatestCollectionCarouselModel[] => {
  let collections: LatestCollectionCarouselModel[] = collectionsParam || [];

  //sort collections
  collections = collections?.sort((a, b) => a.collectionNumber - b.collectionNumber);

  collections.forEach(collection => {
    //sort brands inside collection
    // collection.noveltiesByBrand?.sort((a, b) => a.order - b.order);
    //sort novelties inside every brand collection + merge collection info into brand collection object
    collection.noveltiesByBrand?.forEach(collectionBrand => {
      collectionBrand.release = collection.release;
      collectionBrand.releaseDate = collection.releaseDate;
      collectionBrand.isOlderThan2Months = collection.isOlderThan2Months;
      collectionBrand.longName = collection.longName;
      collectionBrand.shortName = collection.shortName;
      collectionBrand.description = collection.description;
      const brandId = getL1IdFromBrandId(collectionBrand.brand);
      const leveLabel = getLevelLabel(brandId, labelL1Map) || brandId;
      const brandName = getBrandIdFromL1Id(leveLabel);
      collectionBrand.brandName = brandName || '';
      collectionBrand.brandCollectionLongName =
        (brandName || '') + ' ' + (collection.longName || '');
      collectionBrand.novelties; //?.sort((a, b) => a.order - b.order);
    });
  });

  return collections;
};

export const getLatestCollectionsCatalogue = (
  filters: ResponseGetFilterItems,
  language: string
) => {
  return (dispatch, getState) => {
    const mock = false;

    if (!language) {
      console.error('getLatestCollectionsCatalogue - language not specified');
      return;
    }

    let url =
      urlList.GET_LATEST_COLLECTIONS_FILTERS_AND_CONTENT_URL +
      '?startNumber=0&recordForPage=0';
    let payload: { responseFilterItems: ResponseGetFilterItems; mapCollectionBrand } = {
      responseFilterItems: filters,
      mapCollectionBrand: null,
    };

    //JUST FOR MOCK
    let variant = 1;
    // if (filters.brands?.findIndex(a => a.checked) >= 0 || filters.collections?.findIndex(a => a.checked) >= 0) {
    //   variant = 2;
    // }

    dispatch(request());
    (mock
      ? getLatestCollectionsCatalogueMock(variant)
      : axios({
        url,
        method: 'POST',
        headers: getHeaders(),
        data: payload,
      })
    )
      .then(response => {
        const result = response.data;
        result.collectionNoveltiesList = manipulateCollectionsFromRequest(
          result.collectionNoveltiesList,
          getState().utils.labelL1Map
        );

        dispatch(success(result, language));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CATALOGUE_REQUEST,
    };
  }

  function success(latestCollectionsCatalogue, language) {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CATALOGUE_SUCCESS,
      latestCollectionsCatalogue,
      language,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_LATEST_COLLECTIONS_CATALOGUE_FAILURE,
    };
  }
};

export const getBrandCollectionCatalogue = (
  filters: ResponseGetFilterItems,
  language: string,
  params?
) => {
  return (dispatch, getState) => {
    const mock = false;

    if (!language || !params) {
      console.error('getBrandCollectionCatalogue - language or params not specified', {
        language,
        params,
      });
      return;
    }

    let url =
      urlList.GET_BRAND_COLLECTION_FILTERS_AND_CONTENT_URL +
      '?release=' +
      params.release +
      '&brand=' +
      params.brand;
    let payload: ResponseGetFilterItems = filters;

    //JUST FOR MOCK
    let variant = 1;
    // for (let filterKey of Object.keys(filters)) {
    //   if (filters[filterKey].findIndex(a => a.checked) >= 0) {
    //     variant = 2;
    //   }
    // }

    dispatch(request(params.release, params.brand));
    (mock
      ? getBrandCollectionCatalogueMock(variant)
      : axios({
        url,
        method: 'POST',
        headers: getHeaders(),
        data: payload,
      })
    )
      .then(response => {
        const result = response.data;
        result.collectionNoveltiesList = manipulateCollectionsFromRequest(
          result.collectionNoveltiesList,
          getState().utils.labelL1Map
        );

        dispatch(success(result, language, params.release, params.brand));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure(params.release, params.brand));
      });
  };

  function request(collectionId, brandId) {
    return {
      type: actionTypes.GET_BRAND_COLLECTION_CATALOGUE_REQUEST,
      collectionId: collectionId,
      brandId: brandId,
    };
  }

  function success(brandCollectionCatalogue, language, collectionId, brandId) {
    return {
      type: actionTypes.GET_BRAND_COLLECTION_CATALOGUE_SUCCESS,
      brandCollectionCatalogue,
      language,
      collectionId: collectionId,
      brandId: brandId,
    };
  }

  function failure(collectionId, brandId) {
    return {
      type: actionTypes.GET_BRAND_COLLECTION_CATALOGUE_FAILURE,
      collectionId: collectionId,
      brandId: brandId,
    };
  }
};

export const getNoveltiesByStory = (
  storyId: string,
  brandId: string,
  releaseLongName: string,
  pillModel: string,
  language: string
) => {
  return (dispatch, getState) => {
    if (!storyId || !pillModel || !releaseLongName || !language) {
      console.error('getNoveltiesByStory - storyId or pillModel or releaseLongName or language not specified', {
        storyId,
        pillModel,
        releaseLongName,
        language,
      });
      return;
    }

    let url =
      urlList.GET_NOVELTIES_SEARCH +
      '?startNumber=0&recordForPage=14';
    let payload = INITIAL_FILTER_NOVELTIES_STORY_PLP.items;
    payload.brands = [
      {
        id: brandId,
        order: 0,
        checked: true,
        disabled: false,
      },
    ];
    payload.collections = [
      {
        id: releaseLongName,
        order: 0,
        checked: true,
        disabled: false,
      }
    ]
    payload.marketingTheme2 = [
      {
        id: storyId,
        order: 0,
        checked: true,
        disabled: false,
      },
    ];

    dispatch(request(pillModel));
    axios({
      url,
      method: 'POST',
      headers: getHeaders(),
      data: payload,
    })
      .then(response => {

        // const mockTP = [
        //   {
        //     "urlImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/8ab459c6-d0b1-4d08-b77f-b09d016c16b8/3dc4a648-2e14-4d77-93ba-b09d016c1c5e/0RB0947S__901_31__P21__shad__fr.png?imwidth=300",
        //     "urlAnnotatedImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/8ab459c6-d0b1-4d08-b77f-b09d016c16b8/1b863b68-b055-4c23-ba89-b09d016c1715/0RB0947S__901_31.jpg",
        //     "code": "0RB0947S",
        //     "modelName": "CARLOS",
        //     "advertising": false,
        //     "brandName": "Ray-Ban",
        //     "brand": "RB",
        //     "marketingTheme": "CASUAL CLASSIC",
        //     "model": "",
        //     "brandCampaign": ["Brand campaign w/30 characters","Brand campaign w/30 characters", "Ray-Ban" ]
        //   },
        //   {
        //     "urlImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/a704d8dd-6121-43a2-b268-b0be012032dc/e92709bd-60bf-41b6-a9f5-b0be01203ac2/0RB0947SF__901_31__P21__shad__fr.png?imwidth=300",
        //     "urlAnnotatedImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/a704d8dd-6121-43a2-b268-b0be012032dc/f1d760ec-71ff-4ef1-8e81-b0be0120339e/0RB0947SF__901_31.jpg",
        //     "code": "0RB0947SF",
        //     "modelName": "CARLOS",
        //     "advertising": false,
        //     "brandName": "Ray-Ban",
        //     "brand": "RB",
        //     "marketingTheme": "CASUAL CLASSIC",
        //     "model": "",
        //     "brandCampaign": ["Brand campaign w/30 characters"]
        //   },
        //   {
        //     "urlImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1e7cdffc-a0ce-4dec-be4d-b09d016c7b6d/2922985f-344c-4528-8e42-b09e000650cb/0RB2210__902_31__P21__shad__fr.png?imwidth=300",
        //     "urlAnnotatedImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1e7cdffc-a0ce-4dec-be4d-b09d016c7b6d/986c477d-7561-4f52-b1ae-b09d016c7c53/0RB2210__902_31.jpg",
        //     "code": "0RB2210",
        //     "modelName": "",
        //     "advertising": false,
        //     "brandName": "Ray-Ban",
        //     "brand": "RB",
        //     "marketingTheme": "CASUAL CLASSIC",
        //     "model": "",
        //     "brandCampaign": ["Ray-Ban" ]
        //   },
        //   {
        //     "urlImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/2c01d168-669c-4e2e-ad29-b0b500af0750/4bc764a4-23b1-4631-94ff-b0b500af0d65/0RB2210F__6615B1__P21__shad__fr.png?imwidth=300",
        //     "urlAnnotatedImage": "https://leonardo-im2.luxottica.com/cdn-record-files-pi/2c01d168-669c-4e2e-ad29-b0b500af0750/3f20ff51-4196-46f4-83cb-b0b500af07b4/0RB2210F__6615B1.jpg",
        //     "code": "0RB2210F",
        //     "modelName": "",
        //     "advertising": false,
        //     "brandName": "Ray-Ban",
        //     "brand": "RB",
        //     "marketingTheme": "CASUAL CLASSIC",
        //     "model": "",
        //     "brandCampaign": ["Ray-Ban", "Brand campaign w/30 characters","Brand campaign w/30 characters",]
        //   },
        // ]
        const result = response.data?.searchFields;
        //const noveltiesWithBC = [...mockTP, ...result.slice(0, 5)];
        let novelties = transformNoveltiesIntoTrainingPills(result);
        //remove current pill
        novelties = novelties.filter(a => a.model !== pillModel);

        if (result) {
          let novelties = transformNoveltiesIntoTrainingPills(result);
          //remove current pill
          novelties = novelties.filter(a => a.model !== pillModel);
          dispatch(success(novelties, pillModel, language, storyId));
        } else {
          dispatch(failure(pillModel));
        }

      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure(pillModel));
      });
  };

  function request(pillModel) {
    return {
      type: actionTypes.GET_PILLS_FROM_SAME_STORY_REQUEST,
      pillModel,
    };
  }

  function success(pills, pillModel, language, storyId) {
    return {
      type: actionTypes.GET_PILLS_FROM_SAME_STORY_SUCCESS,
      pills,
      pillModel,
      language,
      storyId,
    };
  }

  function failure(pillModel) {
    return {
      type: actionTypes.GET_PILLS_FROM_SAME_STORY_FAILURE,
      pillModel,
    };
  }
};


export const getBrandCampaignByMoco = (moco: string) => {
  return (dispatch, getState) => {
    let url = urlList.GET_BRAND_CAMPAIGN_BY_MOCO;
    url = url.replace('{moco}', moco);

    dispatch(request());
    const mock: boolean = false;
    (mock ? getBrandCampaignMocoData() :
      axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      }))

      .then(response => {
        dispatch(success(response.data,moco))
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_BRAND_CAMPAIGN_BY_MOCO_REQUEST,
    };
  }

  function success(mocoInfo,moco) {
    mocoInfo.moco = moco;
    return {
      type: actionTypes.GET_BRAND_CAMPAIGN_BY_MOCO_SUCCESS,
      mocoInfo,
      moco
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_BRAND_CAMPAIGN_BY_MOCO_FAILURE,
    };
  }
}

