import axios from "axios";
import { SEND_VIDEO_PROGRESS, GET_VIDEO_TRACKING_INTERVAL } from "../../config";
import { getHeaders } from "@utility/Api";
import { getCookie, setCookie,removeCookie } from '@utility/cookie';
import { UpdateVideoTracking } from "@model/VideoTracking/VideoTrackingClasses";

// GET api call to send video progress to Moodle
export const getSecondsInterval = async (duration: number): Promise<number> => {

    return axios.get(GET_VIDEO_TRACKING_INTERVAL,
        {
            headers: getHeaders(),
            params: { duration }
        }
    )
        .then(response => {
            return response?.data;
        })
        .catch(err => {
            console.error('Error getting interval seconds from API call', err);
            return 0;
        });

};

// POST api call to send video progress to Moodle
export const sendVideoProgress = async (progressToSend: UpdateVideoTracking) => {

    axios.post(SEND_VIDEO_PROGRESS,
        { ...progressToSend },
        { headers: getHeaders() }
    )
        .then(response => {
            // IF successful, save temporary data
            if(response.status === 200) {
                const progressData = {
                    lessonId: progressToSend.lessonId,
                    moduleId: progressToSend.moduleId,
                    currentTime: progressToSend.currentTime,
                    progress: progressToSend.progress
                };

                // Store in a cookie to temporary memoize it
                setVideoProgressCookie(progressData)
            }
        })
        .catch(err => {
            console.error('Error sending video progress', err);
        });

};

// GET course_videoProgress cookie
export const getVideoProgressCookie = (lessonId: string) => {
    const cookieName = `${lessonId}_videoProgress`;
    const progressData = getCookie(cookieName);
    if(progressData && typeof progressData === "string") {
        return JSON.parse(progressData);
    }
    return null;
}

// SET course_videoProgress cookie
export const setVideoProgressCookie = (progressData: UpdateVideoTracking) => {
    const cookieName = `${progressData.lessonId}_videoProgress`;
    setCookie(cookieName, JSON.stringify(progressData));
}

// REMOVE course_videoProgress cookie
export const removeVideoProgressCookie = (lessonId: string) => {
    const cookieName = `${lessonId}_videoProgress`;
    removeCookie(cookieName);
}