import React, { useEffect } from 'react';
import { useSelector } from '@hooks/redux';
import Modal from './Modal';
import ButtonUpskillPrograms from '@components/BuildYourCareer/ButtonUpskillPrograms';
import ButtonV2 from './ButtonV2';
import ModalEvolution from './ModalEvolution';
import { printHTML } from '@utility/Api';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { removeCookie } from '@utility/cookie';
import { COOKIE } from '@utility/const';
import { useHistory } from 'react-router-dom';
import { PUBLIC_URLS } from '@components/link-utils';
type Props = {
  logout: () => void;
};

const ModalExpiredSession = (props: Props) => {
  const showModalExpiredSession = useSelector(state => state.utils.showModalSessionExpired);
  const {isDesktop} = useBreakpointBoolean()
  const history = useHistory();

  const lang = useSelector(state => state.utils.lang);

  function deleteCookieAndGoToLogin(): void {
    removeCookie(COOKIE.SESSION_FINISHED);
    props.logout();
    history.push(PUBLIC_URLS.LOGIN.URL);
  }

  if (showModalExpiredSession) { 

    return (
      <ModalEvolution 
      show={true}
      close={deleteCookieAndGoToLogin}
      hideX={true}
      isCloseIconSquare={true}
      modalClassName="variant-expired-session-alert"
      title={(lang?.SESSION_EXPIRED_TITLE).toUpperCase()}
      descriptionElement={<div dangerouslySetInnerHTML={printHTML(lang?.SESSION_EXPIRED_DESCRIPTION)}></div>}
      mainButtonLabel={lang.LOGIN}
     mainButtonAction={deleteCookieAndGoToLogin}
     variantSessionExpired={isDesktop}>
     </ModalEvolution>

    );
  }
  return null;
};

export default ModalExpiredSession;
