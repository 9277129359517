import { Course, LevelEnrichedMap } from '@model/CoursesClass';
import { AKAMAI_IMAGE_URL, L0_IDS } from './const';
import { allowedEducationalCatalogBycL1s } from '@hooks/buildYourCareer/useL0BycOffice';

const L1_BRAND_PREFIX = 'brand_';

export const getL0ImageCover = (l0Id: string): string => {
  return AKAMAI_IMAGE_URL + '/l0_cover/l0_cover_' + l0Id + '.jpg';
};
export const getL0ImageIcon = (l0Id: string): string => {
  return AKAMAI_IMAGE_URL + '/l0_icons/l0_icon_' + l0Id + '.svg';
};
export const getL0ImageEditorial = (l0Id: string): string => {
  return AKAMAI_IMAGE_URL + '/l0_editorial/l0_editorial_' + l0Id + '.jpg';
};

export const getL1ImageChipLarge = (l1Id: string): string => {
  //remove "brand_" from L1 brand
  l1Id = getBrandIdFromL1Id(l1Id);
  return AKAMAI_IMAGE_URL + '/l1_chip_large/l1_chip_large_' + l1Id + '.jpg';
};
export const getL1ImageBrandLogoWhite = (l1Id: string): string => {

  //remove "brand_" from L1 brand


  l1Id = getBrandIdFromL1Id(l1Id);
  return AKAMAI_IMAGE_URL + '/brand_logo/' + l1Id + '.svg';
};

export const getProductImageBrandLogo = (l1Id: string): string => {

  //remove "brand_" from L1 brand


  l1Id = getBrandIdFromL1Id(l1Id);
  return AKAMAI_IMAGE_URL + '/brand-product_logo/' + l1Id + '.svg';
  //return AKAMAI_IMAGE_URL + '/brand-product_logo/' + l1Id + '.svg';
};

export const getBrandPageLogo = (l1Id: string): string => {

  //remove "brand_" from L1 brand


  l1Id = getBrandIdFromL1Id(l1Id);
  return AKAMAI_IMAGE_URL + '/l1_logo/' + l1Id + '.svg';
  //return AKAMAI_IMAGE_URL + '/l1_logo/' + l1Id + '.svg';
};


export const getL1BrandByCourse = (course: Course): string[] => {
  if (!course) {
    return [];
  }

  const L1Brands = [];

  course.catalogTypes?.forEach(l1 => {
    if (isL1IdBrand(l1)) {
      L1Brands.push(l1);
    }
  });

  return L1Brands;
};

export const isL1IdBrand = (l1Id: string): boolean => {
  return l1Id?.startsWith(L1_BRAND_PREFIX);
};
export const getL1Type = (l1Id: string): 'brand' | 'channel' => {
  return isL1IdBrand(l1Id) ? 'brand' : 'channel';
};
export const isBycOfficeCompetency = (l1Id: string): boolean => {
  return  allowedEducationalCatalogBycL1s.includes(l1Id)
};
export const isL0IdBrand = (l0Id: string): boolean => {
  return (
    l0Id &&
    (l0Id.startsWith(L0_IDS.BRANDS) ||
      l0Id.startsWith(L0_IDS.BRAND) ||
      l0Id.startsWith(L0_IDS.BRANDS_EYEWEAR) ||
      l0Id.startsWith(L0_IDS.BRAND_LENSES))
  );
};
export const getL0Type = (l0Id: string): 'brand' | 'channel' => {
  return isL0IdBrand(l0Id) ? 'brand' : 'channel';
};

export const getLevelLabel = (levelId: string, labelMap: LevelEnrichedMap): string => {
  return labelMap?.[levelId]?.label || '';
};

export const getBrandIdFromL1Id = (l1Id: string): string => {
  return l1Id?.replace(L1_BRAND_PREFIX, '');
};
export const getL1IdFromBrandId = (brandId: string): string => {
  if (isL1IdBrand(brandId)) {
    return brandId;
  }
  return L1_BRAND_PREFIX + brandId;
};

export const hasCourseComplexity = (course: Course, complexity: string) => {
  return course?.complexityId === complexity;
};

export const fixL1LetterCase = (l1Id: string): string => {
  if (!l1Id) {
    return l1Id;
  }

  if (isL1IdBrand(l1Id)) {
    return getL1IdFromBrandId(getBrandIdFromL1Id(l1Id).toUpperCase());
  } else {
    return l1Id;
  }
};
