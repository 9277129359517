import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utility/Api";
import { OrderDetails, OrderDetailsWithCreditCard, OrderHistoryDetailsReducer, OrderItemsHistory, OrderToken, OrdersTokens, PromoList } from "@model/EcommerceClass";
import { CartModel, OrderHistory, ProductCart } from "@model/CartModel";
import { GetSubscriptionResponse, PlanModel } from "@model/PlanModel";
import { ShippingInfo } from "@model/ShippingInfo";
import { ActivateLicenseModalParams } from "@model/ActivateLicenseModalParams";
import { PaymentInstructionsResponse } from '@model/CartModel';
import { WalletRequestResponse } from "@model/WalletModel";

class InitialStateProps {
  orderHistory: OrderHistory = null;
  isLoadingOrderHistory: boolean = false;
  isOrderHistoryCompleted: boolean = false;
  orderHistoryDetails: OrderHistoryDetailsReducer = {};
  orderDetail: OrderDetailsWithCreditCard = null;
  isLoadingGetOrderById: boolean = false;
  isGetOrderByIdCompleted: boolean = false;
  ordersTokens: OrdersTokens = {availableTokens: 0,pendingTokens:0, activatedTokens: 0,tokenList:[], expiredTokens:0};
  isLoadingOrdersTokens: boolean = false;
  isOrdersTokensCompleted: boolean = false;
  //CART
  cart: CartModel = null;
  isLoadingCart: boolean = false;
  isLoadedCart: boolean = false;
  //PLAN MODAL
  showModalPlan: boolean = false;
  quantityModalPlan: number = 1;
  //ALREADY IN PLAN MODAL
  showModalCourseAlreadyPlan: boolean = false;
  productModalCourseAlreadyPlan: ProductCart = null;
  //COURSE LESS 1 MONTH MODAL
  showModalCourseOnly1Month: boolean = false;
  productModalCourseOnly1Month: ProductCart = null;
  variantModalCourseOnly1Month: "1m" | "7d" = "1m";
  //LESSON PART OF A COURSE MODAL
  showModalLessonPartCourse: boolean = false;
  productModalLessonPartCourse: ProductCart = null;
  //CART MODAL
  showModalCart: boolean = false;
  //CONTENT EXPIRATION MODAL
  showModalContentExpiration: boolean = false;
  //STOP AUTORENEWAL CONFIRMATION
  modalStopRenewalConfirmationInfo: { show: boolean, expirationDate: string, orderId: string, subscriptionId: string } = null;
  //START AUTORENEWAL CONFIRMATION
  modalStartRenewalConfirmationInfo: { show: boolean, plan: PlanModel, orderId: string, subscriptionId: string } = null;
  //ACTIVATE PLAN CONFIRMATION
  modalActivatePlanConfirmation: {show: boolean, availableLicenses: OrderToken[]} = null;
  //RECOVERING LICENSE MODAL
  modalRecoveringLicense: {show: boolean, email: string , token: string[]} = null;
  isRecoveringLicenseConfirmed: boolean = false;
  // ALERT MODAL IF CAN'T ADD ITEM TO CART (COURSE OR PLAN ALREADY IN CART) 
  modalAlertAddCartPlan: boolean = false;

  modalAlertExpiredInPlan: boolean = false;

  //ACTIVATE LICENSE SUCCESS/ERROR MODAL
  showModalLicenseActivate: ActivateLicenseModalParams = { open: false, token: "", loading: false, success: false };
  //plan info (for now just 1 plan)
  plan: PlanModel = null;
  isLoadingPlan: boolean = false;
  isPlanCompleted: boolean = false;
  //shipping info
  shippingInfo: ShippingInfo = null;
  isLoadingShippingInfo: boolean = false;
  isShippingInfoFailed: boolean = false;
  isLoadingRemoveItemCart: boolean = false;

  paymentInfo: WalletRequestResponse["wallet"] = [];
  wcsDetail: GetSubscriptionResponse["wcsdetail"] = null;
  userPlans: GetSubscriptionResponse["userPlans"] = { bought: {} as any, activated: {} as any, invited: {} as any};
  planTokens: GetSubscriptionResponse["tokenDetailDto"] = [];
  isSubscriptionListLoading: boolean = false;
  isLoadingPaymentInfo = false;
  isPaymentInfoFailed: false;
  renewalPlanActive: boolean = false;
  showCartRightModal: boolean = false;
}

const initialState: InitialStateProps = new InitialStateProps();

const getOrderHistoryRequest = (state) => {
  return updateObject(state, {
    isLoadingOrderHistory: true,
    isOrderHistoryCompleted: false,
  } as InitialStateProps);
};

const getOrderHistorySuccess = (state, action) => {
  return updateObject(state, {
    orderHistory: action.orderHistory,
    isLoadingOrderHistory: false,
    isOrderHistoryCompleted: true,
  } as InitialStateProps);
};

const getOrderHistoryFailure = (state) => {
  let orderHistory = state.orderHistory;
  if (!orderHistory) {
    orderHistory = new OrderHistory();
  }
  return updateObject(state, {
    isLoadingOrderHistory: false,
    orderHistory,
  } as InitialStateProps);
};

const getGetOrderByIdRequest = (state) => {
  return updateObject(state, {
    isLoadingGetOrderById: true,
    isGetOrderByIdCompleted: false,
  } as InitialStateProps);
};

const getGetOrderByIdSuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryDetails: {
      ...state.orderHistoryDetails,
      [action.id]: action.data,
    },
    isLoadingGetOrderById: false,
    isGetOrderByIdCompleted: true,
  } as InitialStateProps);
};

const getGetOrderByIdFailure = (state, action) => {
  return updateObject(state, {
    isLoadingGetOrderById: false,
    orderHistoryDetails: {
      ...state.orderHistoryDetails,
      [action.id]: { orderId: action.id },
    },
  } as InitialStateProps);
};

const getOrderEnrichmentByIdRequest = (state) => {
  return updateObject(state, {
    isLoadingGetOrderById: true,
    isGetOrderByIdCompleted: false,
  } as InitialStateProps);
};

const getOrderEnrichmentByIdSuccess = (state, action) => {
  return updateObject(state, {
    orderHistoryDetails: {
      ...state.orderHistoryDetails,
      [action.id]: action.data,
    },
    isLoadingGetOrderById: false,
    isGetOrderByIdCompleted: true,
  } as InitialStateProps);
};

const getOrderEnrichmentByIdFailure = (state, action) => {
  return updateObject(state, {
    isLoadingGetOrderById: false,
    orderHistoryDetails: {
      ...state.orderHistoryDetails,
      [action.id]: { orderId: action.id },
    },
  } as InitialStateProps);
};

const getOrderEnrichmentConfirmationByIdRequest = (state) => {
  return updateObject(state, {
    isLoadingGetOrderById: true,
    isGetOrderByIdCompleted: false,
  } as InitialStateProps);
};

const getOrderEnrichmentConfirmationByIdSuccess = (state, action) => {
  return updateObject(state, {
    orderDetail: action.data,
    isLoadingGetOrderById: false,
    isGetOrderByIdCompleted: true,
  } as InitialStateProps);
};

const getOrderEnrichmentConfirmationByIdFailure = (state, action) => {
  return updateObject(state, {
    isLoadingGetOrderById: false,
    orderDetail: action,
  } as InitialStateProps);
};



const getCartRequest = (state) => {
  return updateObject(state, {
    isLoadingCart: true,
  } as InitialStateProps);
};

const getCartSuccess = (state, action) => {
  return updateObject(state, {
    cart: action.cart,
    isLoadingCart: false,
    isLoadedCart: true,
  } as InitialStateProps);
};

const getCartFailure = (state) => {
  return updateObject(state, {
    isLoadingCart: false,
  } as InitialStateProps);
};

const setShowModalPlan = (state, action) => {
  let quantity = action.quantity;
  if (!quantity || quantity < 0 || !action.show) {
    quantity = 1;
  }

  return updateObject(state, {
    showModalPlan: action.show,
    quantityModalPlan: quantity,
  } as InitialStateProps);
};

const setShowModalCourseAlreadyPlan = (state, action) => {
  return updateObject(state, {
    showModalCourseAlreadyPlan: action.show,
    productModalCourseAlreadyPlan: action.product,
  } as InitialStateProps);
};

const setShowModalCourseOnly1Month = (state, action) => {
  return updateObject(state, {
    showModalCourseOnly1Month: action.show,
    productModalCourseOnly1Month: action.product,
    variantModalCourseOnly1Month: action.variant,
  } as InitialStateProps);
};

const setShowModalLessonPartCourse = (state, action) => {
  return updateObject(state, {
    showModalLessonPartCourse: action.show,
    productModalLessonPartCourse: action.product,
  } as InitialStateProps);
};

const setShowModalCart = (state, action) => {
  return updateObject(state, {
    showModalCart: action.show,
  } as InitialStateProps);
};

const setShowModalContentExpiration = (state, action) => {
  return updateObject(state, {
    showModalContentExpiration: action.show,
    productModalCourseOnly1Month: action.product,
  } as InitialStateProps);
};

const setShowModalStopRenewalConfirmationInfo = (state, action) => {
  return updateObject(state, {
    modalStopRenewalConfirmationInfo: action.show ? { show: true, ...action?.data } : null,
  } as InitialStateProps);
};

const setShowModalStartRenewalConfirmationInfo = (state, action) => {
  return updateObject(state, {
    modalStartRenewalConfirmationInfo: action.show ? { show: true, plan: action.plan, ...action?.data } : null,
  } as InitialStateProps);
};

const setShowModalActivatePlanConfirmation = (state, action) => {
  return updateObject(state, {
    modalActivatePlanConfirmation: action.show? { show: true, availableLicenses: action.availableLicenses} : null,
  } as InitialStateProps)
}

const setRecoveringLicenseModal = (state, action) => {
  return updateObject(state, {
    modalRecoveringLicense: action.show? { show: true, email: action.email , token: action.token} : null,
  } as InitialStateProps)
}

const setRecoveringLicenseConfirmationState = (state) => {
  return updateObject(state, {
    isRecoveringLicenseConfirmed: true,
  }) as InitialStateProps;
}

const setShowModalAlertAddCartPlan = (state, action) => {
  return updateObject(state, {
    modalAlertAddCartPlan: action.show,
  } as InitialStateProps);
};

const setModalAlertExpiredInPlan = (state, action) => {
  return updateObject(state, {
    modalAlertExpiredInPlan: action.show,
  } as InitialStateProps);
};

const setActivateLicenseModal = (state, action: ActivateLicenseModalParams) => {
  return updateObject(state, {
    showModalLicenseActivate: action
  }) as InitialStateProps;
};

const getPlanRequest = (state) => {
  return updateObject(state, {
    isLoadingPlan: true,
  }) as InitialStateProps;
};

const getPlanSuccess = (state, action) => {
  return updateObject(state, {
    plan: action.plan,
    isLoadingPlan: false,
    isPlanCompleted: true,
  }) as InitialStateProps;
};

const getPlanFailure = (state) => {
  return updateObject(state, {
    isLoadingPlan: false,
    isPlanCompleted: true,
  }) as InitialStateProps;
};

const getContactRequest = (state) => {
  return updateObject(state, {
    isLoadingShippingInfo: true,
    isShippingInfoFailed: false,
  }) as InitialStateProps;
};

const getContactSuccess = (state, action) => {
  return updateObject(state, {
    shippingInfo: action.shippingInfo,
    isLoadingShippingInfo: false,
    isShippingInfoFailed: false,
  }) as InitialStateProps;
};

const getContactFailure = (state, action) => {
  let shippingInfo = action.shippingInfo;
  if (!shippingInfo) {
    shippingInfo = {};
  }

  return updateObject(state, {
    isLoadingShippingInfo: false,
    isShippingInfoFailed: true,
    shippingInfo,
  }) as InitialStateProps;
};

const setContact = (state, action) => {
  return updateObject(state, {
    shippingInfo: action.shippingInfo,
  }) as InitialStateProps;
};

const getAllTokensRequest = (state) => {
  return updateObject(state, {
    isLoadingOrdersTokens: true,
  }) as InitialStateProps;
};

const getAllTokensSuccess = (state, action) => {
  return updateObject(state, {
    ordersTokens: {
      ...state.ordersTokens,
      ...action.data,
    },
    isLoadingOrdersTokens: false,
    isOrdersTokensCompleted: true,
  } as InitialStateProps);
};

const getAllTokensFailure = (state) => {
  return updateObject(state, {
    isLoadingOrdersTokens: false,
  } as InitialStateProps);
};

const getOrderTokensRequest = (state) => {
  return updateObject(state, {
    isLoadingOrdersTokens: true,
  }) as InitialStateProps;
};

const getOrderTokensSuccess = (state, action) => {
  return updateObject(state, {
    ordersTokens: {
      ...state.ordersTokens,
      [action.id]: action.data,
    },
    isLoadingOrdersTokens: false,
    isOrdersTokensCompleted: true,
  } as InitialStateProps);
};

const getOrderTokensFailure = (state) => {
  return updateObject(state, {
    isLoadingOrdersTokens: false,
  } as InitialStateProps);
};


const removeItemCartRequest = (state, _) => {
  return updateObject(state, {
    isLoadingRemoveItemCart: true,
  } as InitialStateProps);
};

const removeItemCartResponse = (state, _) => {
  return updateObject(state, {
    isLoadingRemoveItemCart: false,
  } as InitialStateProps);
};


const getPaymentInfoRequest = (state) => {
  return updateObject(state, {
    isLoadingPaymentInfo: true,
    isShippingInfoFailed: false,
  }) as InitialStateProps;
};

const getPaymentInfoSuccess = (state, action) => {
  return updateObject(state, {
    paymentInfo: action.data,
    isLoadingPaymentInfo: false,
    isPaymentInfoFailed: false,
  }) as InitialStateProps;
};

const getPaymentInfoFailure = (state, action) => {
  return updateObject(state, {
    isLoadingPaymentInfo: false,
    isPaymentInfoFailed: true,
  }) as InitialStateProps;
};

const getSubscriptionListRequest = (state) => {
  return updateObject(state, {
    isSubscriptionListLoading: true,
  }) as InitialStateProps;
};

const getSubscriptionListSuccess = (state, action) => {
  return updateObject(state, {
    wcsDetail: action.wcsdetail,
    userPlans: action.userPlans,
    planTokens: action.planTokens,
    isSubscriptionListLoading: false,
  }) as InitialStateProps;
};

const getSubscriptionListFailure = (state, action) => {
  return updateObject(state, {
    isSubscriptionListLoading: false,
  }) as InitialStateProps;
};

const setRenewalPlan = (state, action) => {
  return updateObject(state, {
    renewalPlanActive: action.active,
  }) as InitialStateProps;
};

const setShowCartRightModal = (state, action) => {
  return updateObject(state, {
      showCartRightModal: action.value
  });
}

const logout = () => {
  return initialState;
};

const reducer = (state = initialState, action): InitialStateProps => {
  switch (action.type) {
    case actionTypes.GET_ORDER_HISTORY_REQUEST:
      return getOrderHistoryRequest(state);
    case actionTypes.GET_ORDER_HISTORY_SUCCESS:
      return getOrderHistorySuccess(state, action);
    case actionTypes.GET_ORDER_HISTORY_FAILURE:
      return getOrderHistoryFailure(state);
    case actionTypes.GET_ORDER_BY_ID_REQUEST:
      return getGetOrderByIdRequest(state);
    case actionTypes.GET_ORDER_BY_ID_SUCCESS:
      return getGetOrderByIdSuccess(state, action);
    case actionTypes.GET_ORDER_BY_ID_FAILURE:
      return getGetOrderByIdFailure(state, action);
        case actionTypes.GET_ORDER_ENRICHMENT_BY_ID_REQUEST:
      return getOrderEnrichmentByIdRequest(state);
    case actionTypes.GET_ORDER_ENRICHMENT_BY_ID_SUCCESS:
      return getOrderEnrichmentByIdSuccess(state, action);
    case actionTypes.GET_ORDER_ENRICHMENT_BY_ID_FAILURE:
      return getOrderEnrichmentByIdFailure(state, action);
    case actionTypes.GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_REQUEST:
      return getOrderEnrichmentConfirmationByIdRequest(state);
    case actionTypes.GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_SUCCESS:
      return getOrderEnrichmentConfirmationByIdSuccess(state, action);
    case actionTypes.GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_FAILURE:
      return getOrderEnrichmentConfirmationByIdFailure(state, action);
    case actionTypes.GET_CART_REQUEST:
      return getCartRequest(state);
    case actionTypes.GET_CART_SUCCESS:
      return getCartSuccess(state, action);
    case actionTypes.GET_CART_FAILURE:
      return getCartFailure(state);
    case actionTypes.SET_SHOW_MODAL_PLAN:
      return setShowModalPlan(state, action);
    case actionTypes.SET_SHOW_MODAL_COURSE_ALREADY_PLAN:
      return setShowModalCourseAlreadyPlan(state, action);
    case actionTypes.SET_SHOW_MODAL_COURSE_ONLY_1_MONTH:
      return setShowModalCourseOnly1Month(state, action);
    case actionTypes.SET_SHOW_MODAL_LESSON_PART_COURSE:
      return setShowModalLessonPartCourse(state, action);
    case actionTypes.SET_SHOW_MODAL_CART:
      return setShowModalCart(state, action);
    case actionTypes.SET_SHOW_MODAL_CONTENT_EXPIRATION:
      return setShowModalContentExpiration(state, action);
    case actionTypes.SET_SHOW_MODAL_STOP_RENEWAL_CONFIRMATION:
      return setShowModalStopRenewalConfirmationInfo(state, action);
    case actionTypes.SET_SHOW_MODAL_START_RENEWAL_CONFIRMATION:
      return setShowModalStartRenewalConfirmationInfo(state, action);
    case actionTypes.SET_SHOW_MODAL_ACTIVATE_PLAN:
      return setShowModalActivatePlanConfirmation(state, action);
      case actionTypes.SET_RECOVERING_LICENSE_MODAL:
        return setRecoveringLicenseModal(state, action);
    case actionTypes.SET_RECOVERING_LICENSE_CONFIRMATION:
      return setRecoveringLicenseConfirmationState(state);
    case actionTypes.SET_SHOW_MODAL_ALERT_ADD_CART_PLAN:
      return setShowModalAlertAddCartPlan(state, action);  
    case actionTypes.SET_SHOW_MODAL_EXPIRED_IN_PLAN:
      return setModalAlertExpiredInPlan(state, action);  
    case actionTypes.SET_ACTIVATE_LICENSE_MODAL:
      return setActivateLicenseModal(state, action);
    case actionTypes.GET_PLAN_REQUEST:
      return getPlanRequest(state);
    case actionTypes.GET_PLAN_SUCCESS:
      return getPlanSuccess(state, action);
    case actionTypes.GET_PLAN_FAILURE:
      return getPlanFailure(state);
    case actionTypes.GET_SHIPPING_INFO_REQUEST:
      return getContactRequest(state);
    case actionTypes.GET_SHIPPING_INFO_SUCCESS:
      return getContactSuccess(state, action);
    case actionTypes.GET_SHIPPING_INFO_FAILURE:
      return getContactFailure(state, action);
    case actionTypes.SET_SHIPPING_INFO:
      return setContact(state, action);
    case actionTypes.GET_ALL_TOKENS_REQUEST:
      return getAllTokensRequest(state);
    case actionTypes.GET_ALL_TOKENS_SUCCESS:
      return getAllTokensSuccess(state, action);
    case actionTypes.GET_ALL_TOKENS_FAILURE:
      return getAllTokensFailure(state);
    case actionTypes.GET_ORDER_TOKENS_REQUEST:
      return getOrderTokensRequest(state);
    case actionTypes.GET_ORDER_TOKENS_SUCCESS:
      return getOrderTokensSuccess(state, action);
    case actionTypes.GET_ORDER_TOKENS_FAILURE:
      return getOrderTokensFailure(state);
    case actionTypes.REMOVE_ITEM_CART_REQUEST:
      return removeItemCartRequest(state, action);
    case actionTypes.REMOVE_ITEM_CART_RESPONSE:
      return removeItemCartResponse(state, action);
    case actionTypes.GET_PAYMENT_INFO_REQUEST:
      return getPaymentInfoRequest(state);
    case actionTypes.GET_PAYMENT_INFO_SUCCESS:
      return getPaymentInfoSuccess(state, action);
    case actionTypes.GET_PAYMENT_INFO_FAILURE:
      return getPaymentInfoFailure(state, action);
    case actionTypes.GET_SUBSCRIPTION_LIST_REQUEST:
      return getSubscriptionListRequest(state);
    case actionTypes.GET_SUBSCRIPTION_LIST_SUCCESS:
      return getSubscriptionListSuccess(state, action);
    case actionTypes.GET_SUBSCRIPTION_LIST_FAILURE:
      return getSubscriptionListFailure(state, action);
    case actionTypes.SET_PLAN_RENEWAL:
      return setRenewalPlan(state, action);
    case actionTypes.SET_SHOW_MODAL_CART_RIGHT_MODAL: 
      return setShowCartRightModal(state, action);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
