import "@css/partials_v3/_collections-brand-modal.scss";
import { LevelEnrichedMap } from "@model/CoursesClass";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "@hooks/redux";
import ModalEvolution from "../ModalEvolution";
import { setCollectionsBrandModalInfo } from "@redux-actions/";
import { getScrollerElementInModal } from "@utility/Api";
import { getL1IdFromBrandId, getL1ImageBrandLogoWhite  } from "@utility/levelUtility";
import LazySVG from "@components/LazySvg";
import { BRANDS_RESIZE_LIST } from "@utility/const";
import ButtonV2 from "../ButtonV2";
import { goToCollectionPage } from "@utility/CollectionsUtility";
import clsx from "@utility/clsx";

type CollectionsBrandModalProps = {

};

const phonebookChars = { 'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'F': 6, 'G': 7, 'H': 8, 'I': 9, 'J': 10, 'K': 11, 'L': 12, 'M': 13, 'N': 14, 'O': 15, 'P': 16, 'Q': 17, 'R': 18, 'S': 19, 'T': 20, 'U': 21, 'V': 22, 'W': 23, 'X': 24, 'Y': 25, 'Z': 26, '#': 27 };
const headerHeight = 166;

const CollectionsBrandModal = ({ }: CollectionsBrandModalProps) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.utils.lang);

  const info = useSelector((state) => state.trainingPills.collectionsBrandModalInfo);
  const labelL1Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL1Map);

  const [brandsCharGroup, setBrandsCharGroup] = useState({});
  const [currentChar, setCurrentChar] = useState("A");


  const refsChar = useRef<HTMLElement[]>([]);
  const refsBrands = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const charsMap = {};

    if (info.collectionBrands) {
      const brandsTemp = info.collectionBrands
        .map((a) => {
          const L1Id = getL1IdFromBrandId(a.brand);

          return {
            ...a,
            image: getL1ImageBrandLogoWhite (L1Id),
            resize: BRANDS_RESIZE_LIST.includes(L1Id),
          }
        })
        .filter((a) => a.brandName)
        .sort((a, b) => a.brandName?.toLowerCase().localeCompare(b.brandName?.toLowerCase()));

      brandsTemp
        .forEach(a => {
          let firstCharBrand = a.brandName[0].toUpperCase();

          if (firstCharBrand.localeCompare('A') < 0 || firstCharBrand.localeCompare('Z') > 0) {
            firstCharBrand = '#';
          }

          if (!charsMap[firstCharBrand]) {
            charsMap[firstCharBrand] = [];
          }
          charsMap[firstCharBrand].push(a);
        });
    }


    // console.log('charsMap', charsMap);
    setBrandsCharGroup(charsMap)
  }, [info.collectionBrands, labelL1Map]);


  useEffect(() => {
    //useEffect to highlight the first char in mobile according to scroll
    var timerCollectionsBrandModal = null;

    const handleScroll = () => {
      if (timerCollectionsBrandModal !== null) {
        clearTimeout(timerCollectionsBrandModal);
      }
      let closest = refsChar.current.find(ref => ref)?.getBoundingClientRect().top - headerHeight,
        key = refsChar.current.find(ref => ref)?.getAttribute("id");

      timerCollectionsBrandModal = setTimeout(() => {
        refsChar.current.forEach(ref => {
          if (ref) {
            const currentKey = ref.getAttribute("id");
            const keyParent = `brands_${currentKey.replace("phonebook_", "")}`;
            const currentParent = refsBrands.current?.find(ref => ref?.getAttribute("id")?.includes(keyParent));

            const current = ref.getBoundingClientRect().top - headerHeight;
            // if (currentKey.includes(l0list?.[1]?.code)) {
            //   firstParentHeight = currentParent?.getBoundingClientRect()?.height;
            // }

            if (current >= 0 && current <= Math.abs(closest) && current < (currentParent?.getBoundingClientRect()?.height - 30)) {
              closest = current;
              key = currentKey;
            } else if (current <= 0 && - current < Math.abs(closest) && current > -(currentParent?.getBoundingClientRect()?.height + 30)) {
              closest = current;
              key = currentKey;
            }
          }
        });

        // console.log('closest', { closest, key })
        setCurrentChar(key?.replace('phonebook_', ''));
      }, 300);
    };

    let wrapper = getScrollerElementInModal("collections-brand-modal");
    wrapper.addEventListener('scroll', handleScroll, {
      capture: true,
      passive: true
    });

    () => wrapper.removeEventListener('scroll', handleScroll);

  }, [brandsCharGroup])

  const close = () => {
    dispatch(setCollectionsBrandModalInfo(false));
  }

  const handleClickBrand = (brandId: string) => {
    goToCollectionPage(info.collectionId, brandId);
    close();
  }

  const handleClickChar = (e, char) => {
    e.stopPropagation();

    let scrollToChar = '';

    if (brandsCharGroup[char]) {
      scrollToChar = char;
    } else if (char === '#') {
      scrollToChar = Object.keys(brandsCharGroup).length > 0 ? Object.keys(brandsCharGroup).sort((a, b) => b.localeCompare(a))[0] : '';
    } else {
      //get the nearest char
      let minDistance = 100;
      Object.keys(brandsCharGroup).forEach(searchChar => {
        const distance = Math.abs(phonebookChars[searchChar] - phonebookChars[char]);

        if (distance < minDistance) {
          minDistance = distance;
          scrollToChar = searchChar;
        }
      })
    }


    const element = document.getElementById('phonebook_' + scrollToChar);
    let wrapper = getScrollerElementInModal("collections-brand-modal");

    if (wrapper && element) {
      const scrollParams = {
        top: element?.offsetTop - headerHeight,
        behavior: "smooth",
      };

      wrapper.scrollTo({ ...scrollParams });
    }

  }


  return (
    <ModalEvolution
      blur={null}
      blurIntensity={null}
      close={close}
      show={info.show}
      modalClassName="collections-brand-modal"
      headerElement={
        <section>
          <h3 className="collections-brand-modal__title">
            {info.collectionName}
          </h3>
          <span className="collections-brand-modal__descr">
            {lang.COLLECTION_BRAND_MODAL_DESCR}
          </span>
        </section>
      }
      descriptionElement={
        <section>
          {Object.keys(brandsCharGroup)?.map(char => (
            <React.Fragment key={char}>
              <div
                className="collections-brand-modal__char"
                id={"phonebook_" + char}
                ref={(ref) => ref && !refsChar.current.includes(ref) && refsChar.current.push(ref)}
              >
                <span>
                  {char}
                </span>
              </div>
              <div
                id={"brands_" + char}
                ref={(ref) => ref && !refsBrands.current.includes(ref) && refsBrands.current.push(ref)}
                className="collections-brand-modal__brands"
              >
                {brandsCharGroup[char].map(brand => (
                  <ButtonV2
                    key={brand.brand}
                    className={clsx("collections-brand-modal__brand", {
                      "brand-resize": brand.resize,
                      "pradalinearossa": brand.brand === "PS"
                    })}
                    handleClick={() => handleClickBrand(brand.brand)}
                  >
                    <LazySVG
                      className="collections-brand-modal__brand-img"
                      src={brand.image}
                      alt={brand.brandName}
                    />
                  </ButtonV2>
                ))}
              </div>
            </React.Fragment>
          ))}

          <div className="phonebook__chars-list" >
            {Object.keys(phonebookChars)
              .map((char) => (
                <div key={char} className={"char " + (char === currentChar ? 'selected' : '')} onClick={(e) => handleClickChar(e, char)}>
                  {char}
                </div>
              ))
            }
          </div>
        </section>
      }
    />
  );
};

export default CollectionsBrandModal;
