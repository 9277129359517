import React from 'react';
import Modal from '@components/UI/Modal';
import ButtonV2 from '@components/UI/ButtonV2';
import LazySVG from '@components/LazySvg';
import { classNames } from 'react-select/src/utils';

type ModalEvolutionProps = {
  modalClassName?: string;
  modalContentClassName?: string;
  close: () => void;
  show: boolean;

  variant?: 'informative' | 'confirmation' | 'alert' | 'action';
  hideX?: boolean;

  icon?: string;
  title?: string;
  headerElement?: React.ReactElement;
  description?: string;
  descriptionElement?: React.ReactElement;
  formElement?: React.ReactElement
  footerElement?: React.ReactElement;
  finalElement?: React.ReactElement;

  mainButtonLabel?: string;
  mainButtonAction?: () => void;
  mainButtonIsLoading?: boolean;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  secondaryButtonIsLoading?: boolean;
  clickToClose?: boolean;
  isCloseIcon2?: boolean;
  isCloseIconSquare?: boolean;
  mainButtonDataElementId?: string;
  mainButtonDataDescription?: string;
  secondaryButtonDataElementId?: string;
  secondaryButtonDataDescription?: string;
  outlineButtonLabel ?: string;
  outlineButtonAction ?: () => void;
  blur?: 'always' | 'desktop' | 'mobile' | null;
  blurIntensity?: '07' | null;
  outlineButtonDataElementId ?: string,
  outlineButtonDataDescription ?: string,
  outlineButtonIsLoading?: boolean,
  variantSessionExpired ?: boolean,
};

export const ModalEvolution = ({
  modalClassName = '',
  modalContentClassName = '',
  close,
  show,
  variant,
  hideX = false,
  icon,
  title = '',
  headerElement,
  description,
  descriptionElement,
  formElement,
  footerElement,
  finalElement,
  mainButtonLabel = '',
  mainButtonAction,
  mainButtonIsLoading = false,
  secondaryButtonLabel = '',
  secondaryButtonAction,
  secondaryButtonIsLoading = false,
  clickToClose = false,
  blur = 'always',
  blurIntensity = '07',
  isCloseIcon2 = false,
  isCloseIconSquare = false,
  mainButtonDataElementId = '',
  mainButtonDataDescription = '',
  secondaryButtonDataElementId = '',
  secondaryButtonDataDescription = '',
  outlineButtonLabel = '',
  outlineButtonAction,
  outlineButtonDataElementId = '',
  outlineButtonDataDescription = '',
  outlineButtonIsLoading= false,
  variantSessionExpired= false,
}: ModalEvolutionProps): React.ReactElement => {
  return (
    <Modal
      blur={blur}
      blurIntensity={blurIntensity}
      close={close}
      contentClassName={'modal__content--centered max-width-620 ' + modalContentClassName}
      className={'ecommerce-alert-modal ' + modalClassName}
      show={show}
      hideX={hideX}
      isCloseIcon2={isCloseIcon2}
      isCloseIconSquare={isCloseIconSquare}
      focusTrapOptions={{
        initialFocus: false,
      }}
      eventCloseMenuOnClick={clickToClose ? close : undefined}
      variantSessionExpired={variantSessionExpired}
    >
      <div className="ecommerce-alert-modal__header">
        {icon && <LazySVG src={icon} alt="" />}
        {!!headerElement && headerElement}
        {title && <h3 className="ecommerce-alert-modal__title">{title}</h3>}
      </div>
      <div className="ecommerce-alert-modal__body">
        {!!descriptionElement && descriptionElement}
        {description && <p>{description}</p>}
        {!!formElement && formElement}
      </div>
      <div className="ecommerce-alert-modal__footer">
        {mainButtonLabel && mainButtonAction && (
          <ButtonV2
            variant="primary"
            small
            handleClick={mainButtonAction}
            loading={mainButtonIsLoading}
            data-element-id={mainButtonDataElementId}
            data-description={mainButtonDataDescription}
          >
            {mainButtonLabel}
          </ButtonV2>
        )}
        {secondaryButtonLabel && secondaryButtonAction && (
          <ButtonV2
            variant="secondary"
            small
            handleClick={secondaryButtonAction}
            loading={secondaryButtonIsLoading}
            data-element-id={secondaryButtonDataElementId}
            data-description={secondaryButtonDataDescription}
          >
            {secondaryButtonLabel}
          </ButtonV2>
        )}
        {outlineButtonLabel && outlineButtonAction && (
          <ButtonV2
            variant="outline-btn"
            handleClick={outlineButtonAction}
            loading={outlineButtonIsLoading}
            data-element-id={outlineButtonDataElementId}
            data-description={outlineButtonDataDescription}
            small={true}
           className={variantSessionExpired ? "button-session-expired-modal" : ""}
          >
            {outlineButtonLabel}
          </ButtonV2>
        )}
        {!!footerElement && footerElement}
      </div>

      {!!finalElement && finalElement}
    </Modal>
  );
};

export default ModalEvolution;
