import React, { useEffect, useState } from 'react';
import { useSelector } from '@hooks/redux';
import useL1List from '@hooks/useL1List';
import ButtonV2 from './UI/ButtonV2';
import LazySVG from './LazySvg';
import { getBrandPageUrl, isCourseVisible, isHighlighted } from '@utility/Api';
import { multipleSortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import CardController from './CardV3/CardController';
import useModalTypes from '@hooks/useModalByType';
import TextSkeleton from './skeletons/TextSkeleton';
import HeaderMenu from './UI/HeaderMenu';
import { Course, LangMap } from '@model/CoursesClass';
import { purifyItemDataElementId, purifyItemDataElementIdCamelCase } from '@utility/analytics-utils';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { HighlightsThirdColumn } from './collections/CollectionsMenu';
import camelCase from 'lodash/camelCase';
import lang from '@utility/lang';
import { USER_URLS } from './link-utils';
import { buildClickDataTrackingObject } from '@model/TrackingClass';


export const URL_IMAGE_PODCAST_THIRD_COLUMN= "https://media.leonardo.essilorluxottica.com/images/header-menu/podcast.jpg"
export const URL_CTA_PODCAST_SECOND_COLUMN= USER_URLS.CMS_NEW_PAGE_PODCAST.URL 

const AreaOfExpertiseModal = ({ show }: { show: boolean }) => {
  const lang = useSelector(state => state.utils.lang);
  const coursesMap = useSelector(state => state.course.coursesMap);
  const isGetCourseCompleted = useSelector(state => state.course.isGetCourseCompleted);
  const breakpoint = useBreakpointBoolean();
  const userProfile = useSelector(state => state.user.userProfile);

  const modalTypes = useModalTypes();

  const [l1List, l0list, selectedL0, setSelectedL0, loadingL1List] = useL1List(null, true);
  const [selectedL0Full, setSelectedL0Full] = useState(null);
  const [coursesToShow, setCoursesToShow] = useState([]);

  useEffect(() => {
    let selectedL0FullTemp = {
      code: '',
    };
    if (selectedL0 && selectedL0 !== lang.PODCAST_HEADER) {
      selectedL0FullTemp = l0list.find(a => a.code === selectedL0);
    }
    else if(selectedL0 && selectedL0 === lang.PODCAST_HEADER){
      selectedL0FullTemp = {code: lang.PODCAST_HEADER};
    }
    setSelectedL0Full(selectedL0FullTemp);
  }, [selectedL0]);

  useEffect(() => {
    let coursesToShowTemp = [];

    if(selectedL0 === lang.PODCAST_HEADER){
      setCoursesToShow([]);
      return;
    }

    if (coursesMap) {
      coursesToShowTemp = Object.values(coursesMap).filter(
        a => isCourseVisible(a) && isHighlighted(a)
      );

      if (selectedL0 && selectedL0 !== lang.PODCAST_HEADER) {
        //filter by L0 if selected
        coursesToShowTemp = coursesToShowTemp.filter(a => a.level0 === selectedL0);
      } else {
        //exclude l0 with order > 100
        let listOfCode = l0list?.map(a => a?.code);
        coursesToShowTemp = coursesToShowTemp.filter(a => listOfCode?.includes(a?.level0));
      }

      //sort by start date, if same start date --> sort by title
      coursesToShowTemp = multipleSortCourses(coursesToShowTemp, [
        SORTBY.TITLE_AZ,
        SORTBY.START_DATE,
      ]).slice(0, 3);
    }
    setCoursesToShow(coursesToShowTemp);
  }, [selectedL0, coursesMap]);

  const closeModal = () => {
    modalTypes?.close();
  };

  return (
    <HeaderMenu
      show={show}
      closeModal={closeModal}
      closeDataId={`mainnav_educationalCatalog${
        selectedL0 ? '_' + purifyItemDataElementId(selectedL0) : ''
      }_close`}
      firstColumn={
        <FirstColumn
          isGetCourseCompleted={isGetCourseCompleted}
          selectedL0Full={selectedL0Full}
          setSelectedL0={setSelectedL0}
          l0list={l0list}
          lang={lang}
          userProfile={userProfile}
        />
      }
      secondColumn={
        <SecondColumn
          isGetCourseCompleted={isGetCourseCompleted}
          selectedL0Full={selectedL0Full}
          l1List={l1List}
          lang={lang}
          closeModal={closeModal}
        />
      }
      thirdColumn={
        <ThirdColumn
          courses={coursesToShow}
          selectedL0Full={selectedL0Full}
          isDesktop={breakpoint.isDesktop}
          closeModal={closeModal}
          l1={selectedL0Full}
        />
      }
      firstColumnProps={null}
      secondColumnProps={{ key: 'l1_' + selectedL0Full?.code }}
      thirdColumnProps={{ key: 'highlights_' + selectedL0Full?.code }}
    />
  );
};

export default AreaOfExpertiseModal;

const SkeletonL0 = ({ width = 200 }) => {
  return (
    <TextSkeleton
      className="area-of-expertise__l0-skeleton"
      borderRadius={8}
      width={width}
      height={28}
      lightTheme={true}
    />
  );
};

const SkeletonL0List = () => {
  return (
    <>
      <SkeletonL0 width={275} />
      <SkeletonL0 width={218} />
      <SkeletonL0 width={230} />
      <SkeletonL0 width={293} />
      <SkeletonL0 width={250} />
      <SkeletonL0 width={286} />
      <SkeletonL0 width={230} />
      <SkeletonL0 width={262} />
      <SkeletonL0 width={287} />
      <SkeletonL0 width={269} />
    </>
  );
};

type FirstColumnProps = {
  isGetCourseCompleted: boolean;
  selectedL0Full: any;
  setSelectedL0: (s: string) => void;
  l0list: any;
  lang: LangMap;
  userProfile: any;
};

const FirstColumn = ({
  isGetCourseCompleted,
  selectedL0Full,
  setSelectedL0,
  l0list,
  lang,
  userProfile
}: FirstColumnProps) =>
  isGetCourseCompleted ? (
    <div className="area-of-expertise__l0-list">
      <ButtonV2
        variant="text-btn"
        className={
          'area-of-expertise__l0-button ' +
          (selectedL0Full?.code === '' ? 'l0-button--selected' : '')
        }
        handleClick={() => setSelectedL0('')}
        variantLongArrow
      >
        {lang.AREAS_OF_EXPERTISE}
      </ButtonV2>
      <div className="area-of-expertise__separator" />
      {l0list?.filter(item => item?.code !== "vision_care").map(l0 => (
        <ButtonV2
          variant="text-btn-dotted"
          className={
            'area-of-expertise__l0-button ' +
            (selectedL0Full?.code === l0.code ? 'l0-button--selected' : '')
          }
          handleClick={() => { buildClickDataTrackingObject("mainNav_"+ camelCase(lang.AREAS_OF_EXPERTISE)+ "_"+camelCase(l0.label));setSelectedL0(l0.code)}}
          variantLongArrow
          key={'l0_button_' + l0?.code}
        >
          {l0.label}
        </ButtonV2>
      ))}
            {userProfile.menuEntryPoint?.podcast &&
              <ButtonV2
              variant="text-btn-dotted"
              className={
                'area-of-expertise__l0-button '+ (selectedL0Full?.code === lang.PODCAST_HEADER ? 'l0-button--selected' : '')
              }
              handleClick={() => { buildClickDataTrackingObject("mainNav_"+ camelCase(lang.AREAS_OF_EXPERTISE)+ "_"+camelCase(lang.PODCAST_HEADER));setSelectedL0(lang.PODCAST_HEADER)}}
              variantLongArrow
              key={'l0_button_' + lang.PODCAST_HEADER}
            >
            {lang.PODCAST_HEADER}
        </ButtonV2>
      }
    </div>
  ) : (
    <SkeletonL0List />
  );

const SkeletonL1 = ({ width = 200 }) => {
  return (
    <TextSkeleton
      className="area-of-expertise__l1-skeleton"
      borderRadius={5}
      width={width}
      height={24}
      lightTheme={true}
    />
  );
};

const SkeletonL1List = () => {
  return (
    <>
      <SkeletonL1 width={275} />
      <SkeletonL1 width={323} />
      <SkeletonL1 width={300} />
      <SkeletonL1 width={243} />
    </>
  );
};

type SecondColumnProps = {
  isGetCourseCompleted: boolean;
  selectedL0Full: any;
  lang: LangMap;
  l1List: any;
  closeModal: () => void;
};

const SecondColumn = ({
  isGetCourseCompleted,
  selectedL0Full,
  lang,
  l1List,
  closeModal,
}: SecondColumnProps) =>
  isGetCourseCompleted ? (
    selectedL0Full &&
    (selectedL0Full.code === '' ? (
      <span className="area-of-expertise__l1-description">{lang?.AREAS_OF_EXPERTISE_DESCR}</span>
    ) : (
      (selectedL0Full.code !== lang.PODCAST_HEADER ? 
      <>
        <span className="area-of-expertise__l1-title">{selectedL0Full.label}</span>
        <div className="area-of-expertise__l1-list">
          <span className="area-of-expertise__l1-description">
            {selectedL0Full.longDescription}
          </span>

          {l1List[selectedL0Full.code]?.map(channel => (
            <ButtonV2
              key={channel.value}
              className={'area-of-expertise__l1-button area-of-expertise__l1-channel'}
              to={getBrandPageUrl(channel.type, channel.value)}
              handleClick={() => { buildClickDataTrackingObject("mainNav_"+ camelCase(lang.AREAS_OF_EXPERTISE)+ "_"+camelCase(selectedL0Full.label)+"_"+camelCase(channel.label));closeModal()}}
            >
              {channel.label}
              <LazySVG
                className="area-of-expertise__l1-channel-arrow"
                src={'/images/svg-icons/arrow-right5.svg'}
                alt={channel.label}
              />
            </ButtonV2>
          ))}
        </div>
      </> : 
      <>
       <span className="area-of-expertise__l1-title">{lang.PODCAST_HEADER}</span>
       <div className="area-of-expertise__l1-list">
          <span className="area-of-expertise__l1-description">
            {lang.PODCAST_DESCRIPTION}
          </span>
          <ButtonV2
            className={'area-of-expertise__l1-button area-of-expertise__l1-channel'}
            to={URL_CTA_PODCAST_SECOND_COLUMN} 
            handleClick={() => { buildClickDataTrackingObject("mainNav_"+ camelCase(lang.AREAS_OF_EXPERTISE)+ "_"+camelCase(lang.PODCAST_HEADER)+"_"+camelCase(lang.PODCAST_CTA))}}
          >
            {lang.PODCAST_CTA}
            <LazySVG
              className="area-of-expertise__l1-channel-arrow"
              src={'/images/svg-icons/arrow-right5.svg'}
              alt={selectedL0Full.label}
            />
          </ButtonV2> 
          </div> 
      </>
    )))
  ) : (
    <SkeletonL1List />
  );

  const ThirdColumn = ({
    courses,
    isDesktop,
    closeModal,
    selectedL0Full,
    l1
  }: {
    courses: Course[];
    isDesktop: boolean;
    selectedL0Full: any;
    closeModal: () => void;
    l1: any;
  }) => {
    const lang = useSelector(state => state.utils.lang);
    const isPodcastHeader = selectedL0Full?.code === lang.PODCAST_HEADER;
  
    if (isPodcastHeader) {
      return (
        <div className="collections-menu__section-3 collections-menu__a podcast-image ">
          <img src={URL_IMAGE_PODCAST_THIRD_COLUMN} />
        </div>
      );
    } else {
      return (
        <>
          {isDesktop ? (
            <>
              {courses.length > 0 && courses.map((course) => (
                <CardController
                  course={course}
                  variant="card-areaofexpertise"
                  key={course.courseIdMaster}
                  clickObjectTracking ={(camelCase(lang.AREAS_OF_EXPERTISE)+"_"+camelCase(l1?.label))}
                />
              ))}
            </>
          ) : (
            <HighlightsThirdColumn courses={courses} closeModal={closeModal} />
          )}
        </>
      );
    }
  };
  
