import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";
const axios = require("axios");
import { getHeaders, getParentCourse, getParentLPPurchasable, isInThePast, willCourseBeRemovedSoon } from "@utility/Api";
import { setShowModalByType, showError } from "./utilsActions";
import {
  isPurchasedInPlan,
  mockAddItemToCart,
  mockAssignPromoCode,
  mockGetCart,
  mockGetOrderById,
  mockGetOrderByIdWithToken,
  mockGetShippingInfo,
  mockRemovePromoCode,
  mockRemoveItemToCart,
  orderHistoryMockRequest,
  promiseMock,
  toFixedNumString,
  checkoutErrorUtag,
  getNickNameDefault,
  getDefaultContact,
  isDefaultContactPresent,
  mockActivateLicense,
  mockGetPaymentDetails,
  mockAddPaymentDetail,
  mockRemovePaymentDetail,
  mockUpdatePaymentDetail,
  promiseMockForTokendSend,
  promiseMockResetTokendSend,
  mockGetTokensByOrderId,
} from "@utility/ecommerceUtility";
import { CartModel, OrderHistory, PaymentInstructionItem, PaymentInstructionRequest, PaymentInstructionsResponse, ProductCart, ResponseSetPaymentAndConfirm } from '@model/CartModel';
import { ADD_TO_CART_STEPS, CARD_ZERO, CONTACT_DEFAULT_PREFIX, COOKIE, MODAL_TYPES, PAYMENT_ERROR_RECAPTCHA, PROMO_STATUS_CODE, queryParams } from '@utility/const';
import { UserProfile } from "@model/User";
import history from "../../history";
import { USER_URLS, styledLogUtagView } from "@components/link-utils";
import { GetSubscriptionResponse, PlanModel, UOM } from "@model/PlanModel";
import { ShippingAddress, ShippingInfo } from "@model/ShippingInfo";
import { RootState, store } from "../store";
import { Dispatch } from "react";
import { DataLayerTracking, ErrorTracking, ErrorTypes, TealiumEvents, tealiumEventsOnAction } from "@model/TrackingClass";
import { getCourses, setCatalogueCourses, toggleModal, toggleModalCourseById, updateCourse } from ".";
import { ActivateTokenResponse, ACTIVATE_TOKEN_ERROR_CODE, OrdersTokens, OrderToken, OrderTokenResponse, PromoList } from "@model/EcommerceClass";
import { AxiosError } from "axios";
import { getCookie, removeCookie, setCookie } from "@utility/cookie";
import { updateUserSuccessHandler } from "./userActions";
import { filterPlanCarouselCourses } from "@hooks/usePlanCarousel";
import { isPlanExpired, mockGetPlan, mockGetSubscriptionList, selectSubscription, selectUserPlan } from "@utility/ecommercePlanUtility";
import { WalletItem, WalletRequestBody, WalletRequestResponse } from "@model/WalletModel";
import { isCourseMaster } from "@utility/onBoardingCourseUtility";
import { triggerErrorEvent, triggerTrackEvent } from "@utility/analytics-utils";
import { LevelEnrichedMap } from "@model/CoursesClass";

const mockRequests = false;
const mockRequestsForToken = false;
const mockRequestSendedToken = false;

export const getOrderHistory = () => {
  return (dispatch) => {
    let url = urlList.GET_ORDER_HISTORY;
    dispatch(request());

    (mockRequests
      ? orderHistoryMockRequest()
      : axios({
        url,
        method: "GET",
        headers: getHeaders(),
      })
    )
      .then((response: { data: OrderHistory }) => {

        const parentOrders = response.data?.Order.filter(order => !!order?.parentOrderId);
        const childrenOrders = response.data?.Order.filter(order => !order?.parentOrderId);
        if (parentOrders?.length > 0 && childrenOrders?.length > 0) {
          // merge parent and children order
          const Order: OrderHistory["Order"] = response.data?.Order?.map((order) => {

            if (order?.parentOrderId) {
              return {
                ...order,
                // invert selectOrderDetailsById 
                isChild: true,
              }
            }
            const parentOrder = parentOrders?.find(parentOrder => parentOrder?.parentOrderId === order?.orderId);
            if (parentOrder) {
              return {
                ...order,
                isParent: true,
              }
            }
            return order;
          });

          response.data.Order = Order;
        }

        dispatch(getAllTokens(response.data?.Order?.map(a => a.orderId)));

        dispatch(success(response.data));
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_ORDER_HISTORY_REQUEST,
    };
  }

  function success(orderHistory) {
    return {
      type: actionTypes.GET_ORDER_HISTORY_SUCCESS,
      orderHistory,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_ORDER_HISTORY_FAILURE,
    };
  }
};

export const activateLicense = (token: string, redirectToHP: boolean = false, redirectPDPPlan: boolean = false) => {
  return (dispatch, getState) => {

    dispatch(setActivateLicenseModal({
      token,
      open: true,
      loading: true,
      success: false,
    }));

    const url = urlList.ACTIVATE_LICENSE;
    return (mockRequests
      ? mockActivateLicense()
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: token,
      })

    )
      .then((response: { data: ActivateTokenResponse }) => {
        //check response result
        if (response.data?.active) {
          //token activated

          let utagEventData: DataLayerTracking & tealiumEventsOnAction = {};

          const coursesMap = getState().course.coursesMap;
          const labelL1Map: LevelEnrichedMap = getState().utils.labelL1Map;

          if (response.data.type === 'plan') {
            //if a plan is activated --> refresh the entire catalog
            dispatch(getCourses());
            //update the field subscription in the user profile
            let userProfile: UserProfile = getState().user.userProfile;
            userProfile.subscription = true;
            dispatch(updateUserSuccessHandler({ data: userProfile }, 'subscription'));

            dispatch(setCatalogueCourses(filterPlanCarouselCourses(coursesMap), 'PREMIUM_ACCESS_PLAN'));

              const plan : PlanModel = getState().ecommerce.plan;
              utagEventData = {
                Events_LicenceActivation: '1',
                Products_Id_Array: [plan.id],
                Products_Category_Array: ['plan'],
                Products_ModelName_Array: [plan.name?.toLowerCase()],
              }

            if (redirectPDPPlan) {
              history.push(USER_URLS.PLAN.URL);
            }

          } else {

            const courseActivated = coursesMap?.[response.data.object];
            utagEventData = {
              Events_LicenceActivation: '1',
              Products_Id_Array: [courseActivated?.courseIdMaster],
              Products_Category_Array: courseActivated?.catalogTypes.map(catalogType => {
                let categoryName = labelL1Map?.[catalogType]?.label;
                return categoryName?.toLowerCase();
              }),
              Products_ModelName_Array: [courseActivated?.courseFullName?.toLowerCase()]
            }
            //update impacted course/lesson
            //if course, its contained lessons will be updated too
            dispatch(updateCourse(response.data.object, {}, false, true));

            if (redirectToHP) {
              //go to HP and wait that the catalogue is downloaded
              history.push(USER_URLS.HOMEPAGE.URL + '?' + queryParams.COURSE_DETAIL + '=' + response.data.object);
            } else {
              dispatch(toggleModalCourseById(response.data.object));
            }
          }

          triggerTrackEvent(utagEventData)
          styledLogUtagView("Activate license", utagEventData);

          //update order tokens
          if (response.data.orderId) {
            const ordersTokens = getState().ecommerce.ordersTokens;
            if (ordersTokens) {
              dispatch(getAllTokens([response.data.orderId]));
            }
          }

          dispatch(setActivateLicenseModal({
            open: false,
            loading: false,
            success: false,
            token,
          }));
          dispatch(getSubscriptionList());

        } else {
          //no valid token
          // window?.utag?.link({
          //   id: TealiumEvents.error,
          //   Page_DeviceType: "web",
          //   error_type: ErrorTypes.user,
          //   error_message: "Invalid licence key",
          //   licence_destination: ["me"],
          // });

          console.error('No valid token - ', response.data.errorMessage);

          dispatch(setActivateLicenseModal({
            open: true,
            loading: false,
            success: false,
            token,
            errorCode: response.data.errorCode,
          }));
        }

        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch(showError(err));
        return Promise.reject(err);
      });
  };
};

export const setActivateLicenseModal = (action: {
  open: boolean;
  loading: boolean;
  token: string;
  success?: boolean;
  errorCode?: string;
}) => {
  return {
    type: actionTypes.SET_ACTIVATE_LICENSE_MODAL,
    ...action,
  };
}



export const sendTokenToFriend = (licenses: { sendedTo: string; token: string }[], orderId: string[], errorEmails?: string[]) => {

  const url = urlList.SEND_TOKEN_FRIEND;
  return (
    mockRequestsForToken
      ? promiseMockForTokendSend("license.email", "license.token", errorEmails)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: licenses,
      })
  )
    .then((response: { data: OrderToken[] }) => {
      const orderTokenResponse: OrderTokenResponse[] = response.data.map((order) => {
        if (order?.sendedTo && order?.sendFailure !== undefined) { //LEON-4167
          return {
            ...order,
            sendedTo: order?.sendedTo,
            success: !order?.sendFailure
          }
        }
      })
      return orderTokenResponse;
    })
    .catch((err, dispatch) => {
      dispatch(showError(err));
      return Promise.reject(err);
    })

};


export const resetSendedToken = (token: string[]) => {

  return (dispatch) => {
    const url = urlList.RESET_SENDED_TOKENS;
    return (
      mockRequestSendedToken
        ? promiseMockResetTokendSend(token)
        : axios({
          url,
          method: "POST",
          headers: getHeaders(),
          data: token,

        })

    )
      .then(response => {
        dispatch(getAllTokens([response?.data?.[0].orderid]))
        // CALL subscription V2 to update user subscription data
        dispatch(getSubscriptionList())
      })
      .catch((err) => {
        dispatch(showError(err));
        return Promise.reject(err);
      });
  }
}


const getCartRequest = () => {
  return {
    type: actionTypes.GET_CART_REQUEST,
  };
}

const getCartSuccess = (cart) => {
  return {
    type: actionTypes.GET_CART_SUCCESS,
    cart,
  };
}

const getCartFailure = () => {
  return {
    type: actionTypes.GET_CART_FAILURE,
  };
}

export const getCart = (ecommerceEnable: boolean = false) => {
  return (dispatch, getState) => {
    ecommerceEnable = ecommerceEnable || getState().user.ecommerceEnable;
    if (!ecommerceEnable) {
      console.log('getCart - user not enabled to ecommerce')
      return;
    }

    let url = urlList.GET_CART;
    dispatch(getCartRequest());

    (mockRequests
      ? mockGetCart()
      : axios({
        url,
        method: "GET",
        headers: getHeaders(),
      })
    )
      .then((response: { data: CartModel }) => {
        dispatch(getCartSuccess(response.data));
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(getCartFailure());
      });
  };
};

export const insertProductInCart = (product: ProductCart, step: number, redirectToCart: boolean = true) => {
  return (dispatch, getState) => {
    if (!product.partNumber || !product.quantity || (+product.quantity) <= 0) {
      return;
    }
    const actualCart: CartModel = getState().ecommerce.cart;
    const userProfile: UserProfile = getState().user.userProfile;
    const labelL1Map: LevelEnrichedMap = getState().utils.labelL1Map;
    const plan = getState()?.ecommerce?.plan;
    const lang = getState()?.utils?.lang;
    if (!actualCart) {
      console.error('cart not found');
      return;
    }

    //if adding to cart a lesson
    if (step !== ADD_TO_CART_STEPS.PLAN) {
      const coursesMap = getState().course.coursesMap;
      let course = getParentCourse(product.partNumber, coursesMap);
      if (!course) {
        console.error('course ' + product.partNumber + ' not found in coursesMap');
        return;
      }

      const plans = actualCart.orderItem?.filter(cartItem => cartItem?.partNumber === plan.id);
      const showErrorBuyPlan: boolean = plans?.length > 0;
      if (showErrorBuyPlan) {
        dispatch(setShowModalAlertAddCartPlan(showErrorBuyPlan));
        let utagErrorData: ErrorTracking = {
          Error_Source: ErrorTypes.user,
          Error_Code: 'checkout',
          Error_Message: lang?.ADD_ITEMS_PLAN_ERROR_TITLE
        }

        triggerErrorEvent(utagErrorData);
        styledLogUtagView(utagErrorData?.Error_Message, utagErrorData)
        return;
      }

      //if course is already included in plan purchased by the user --> show modal course already in plan
      if (isPurchasedInPlan(course, userProfile) && step < ADD_TO_CART_STEPS.LESSON_IN_PLAN) {
        dispatch(setShowModalCourseAlreadyPlan(true, product));
        return;
      }

      //if lesson belongs to at least one purchasable LP
      if (getParentLPPurchasable(product.partNumber, coursesMap).length > 0 && step < ADD_TO_CART_STEPS.LESSON_PART_COURSE) {
        dispatch(setShowModalLessonPartCourse(true, product));
        return;
      }

      //if course is already included in plan purchased by the user --> show modal course already in plan
      if (step < ADD_TO_CART_STEPS.EXPIRATION_LESS_1_MONTH) {
        if (willCourseBeRemovedSoon(course, userProfile)) {
          let variant = "1m";
          if (willCourseBeRemovedSoon(course, userProfile, '7d')) {
            variant = "7d";
          }
          if (willCourseBeRemovedSoon(course, userProfile, '24h')) {
            variant = "24h";
          }
          dispatch(setShowModalCourseOnly1Month(true, product, variant));
          return;
        }
      }

    } else {
      const notPlans = actualCart.orderItem?.filter(cartItem => cartItem?.partNumber !== plan.id);
      const showErrorBuyPlan: boolean = notPlans?.length > 0;
      if (showErrorBuyPlan) {
        dispatch(setShowModalAlertAddCartPlan(showErrorBuyPlan));
        let utagErrorData: ErrorTracking = {
          Error_Source: ErrorTypes.user,
          Error_Code: 'checkout',
          Error_Message: lang?.ADD_ITEMS_PLAN_ERROR_TITLE
        }

        triggerErrorEvent(utagErrorData);
        styledLogUtagView(utagErrorData?.Error_Message, utagErrorData)
        return;
      }

    }

    //check if the product is already in cart
    if (actualCart.orderItem?.length > 0) {
      for (let productInCart of actualCart.orderItem) {
        if (productInCart.partNumber === product.partNumber) {
          console.log('Product already in cart --> update instead of insert', product);
          dispatch(updateProductInCart(product, true));
          return;
        }
      }
    }


    let url = urlList.ADD_ITEM_CART;
    dispatch(getCartRequest());

    //create the body to send
    let body: any = {};
    body.orderId = actualCart.orderId;
    body.orderItem = [];
    body.orderItem.push({
      partNumber: product.partNumber,
      quantity: product.quantity,
    });

    (mockRequests
      ? mockAddItemToCart(actualCart, product)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: body
      })
    )
      .then((response: { data: CartModel }) => {
        if (response?.data?.code === "ERROR_ADD_CART_PLAN_ITEMS") {
          dispatch(setShowModalAlertAddCartPlan(true));
          let utagErrorData: ErrorTracking = {
            Error_Source: ErrorTypes.user,
            Error_Code: 'checkout',
            Error_Message: lang?.ADD_ITEMS_PLAN_ERROR_TITLE
          }
  
          triggerErrorEvent(utagErrorData);
          styledLogUtagView(utagErrorData?.Error_Message, utagErrorData)
          return;
        }
        dispatch(getCartSuccess(response.data));

        if (step === ADD_TO_CART_STEPS.PLAN) {
          dispatch(setShowModalPlan(false));
        }

        if (redirectToCart) {
          dispatch(setShowModalByType(MODAL_TYPES.cartRightModal))
          // history.push(USER_URLS.CART.URL);
        }

        const orderInfos = response?.data?.orderItem.find(order => order?.partNumber === product?.partNumber);

        let utagEventData: DataLayerTracking & tealiumEventsOnAction = {
          Events_CartAdd: '1',
          Products_Id_Array: [product.partNumber],
          Products_RevenuePerUnit_Array: [toFixedNumString(orderInfos?.orderItemPrice)],
          Products_Units_Array: [toFixedNumString(orderInfos?.quantity, 0)],
        }

        if(product?.partNumber === 'pl1') {
          utagEventData.Products_Category_Array = ["plan"];
          utagEventData.Products_ModelName_Array = [lang?.UNLIMITED_ACCESS_PLAN?.toLowerCase()];
          utagEventData.Products_PriceFull_Array = [toFixedNumString(plan?.price || 0)];
          utagEventData.Products_Language_Array = [undefined]
          if(response?.data?.adjustment?.[0]?.code.includes("FREE")) {
            utagEventData.Products_RevenuePerUnit_Array = ['0.00']
          } else {
            utagEventData.Products_RevenuePerUnit_Array = [toFixedNumString(plan?.price)]
          }
        } else {

          let courseContextIdFromSessionStorage = window?.sessionStorage.getItem("Products_ContextId_Array") ?? "";
          if(courseContextIdFromSessionStorage === "undefined") courseContextIdFromSessionStorage = ""
          const course = getState().course.coursesMap?.[product?.partNumber]
          utagEventData.Products_Category_Array = course?.catalogTypes?.map( catalogType =>  labelL1Map?.[catalogType]?.label?.toLowerCase())
          utagEventData.Products_ModelName_Array = [course?.courseFullName?.toLowerCase()];
          utagEventData.Products_RevenuePerUnit_Array = [toFixedNumString(course?.price)];
          utagEventData.Products_PriceFull_Array = [toFixedNumString(orderInfos?.orderItemPrice)];
          utagEventData.Products_Language_Array = [course?.language]
          utagEventData.Products_ContextId_Array = [courseContextIdFromSessionStorage];
        }

        triggerTrackEvent(utagEventData);
        styledLogUtagView("Add to cart", utagEventData)
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(getCartFailure());
      });
  };
};

export const updateProductInCart = (product: ProductCart, redirectToCart: boolean = false) => {
  return (dispatch, getState) => {
    if (!product.partNumber || !product.quantity || (+product.quantity) <= 0) {
      return;
    }
    const actualCart: CartModel = getState().ecommerce.cart;
    if (!actualCart) {
      console.error('cart not found');
      return;
    }

    let itemToUpdate = null;
    for (let item of actualCart.orderItem) {
      if (item.partNumber === product.partNumber) {
        itemToUpdate = item;
        itemToUpdate.quantity = product.quantity;
      }
    }

    if (!itemToUpdate) {
      console.error('product ' + product.partNumber + ' not found');
      return;
    }

    let url = urlList.UPDATE_ITEM_CART;
    dispatch(getCartRequest());

    //create the body to send
    let body: any = {};
    body.orderId = actualCart.orderId;
    body.orderItem = [itemToUpdate];
    const labelL1Map: LevelEnrichedMap = getState().utils.labelL1Map;
    const plan = getState()?.ecommerce?.plan;
    const lang = getState()?.utils?.lang;

    (mockRequests
      ? mockAddItemToCart(actualCart, product)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: body
      })
    )
      .then((response: { data: CartModel }) => {
        dispatch(getCartSuccess(response.data));

        const orderInfos = response?.data?.orderItem.find(order => order?.partNumber === product?.partNumber);

        let utagEventData: DataLayerTracking & tealiumEventsOnAction = {
          Events_CartAdd: '1',
          Products_Id_Array: [product.partNumber],
          Products_RevenuePerUnit_Array: [toFixedNumString(orderInfos?.orderItemPrice)],
          Products_Units_Array: [toFixedNumString(orderInfos?.quantity, 0)],
          Products_Language_Array: [undefined]
        }

        if(product?.partNumber === 'pl1') {
          utagEventData.Products_Category_Array = ["plan"];
          utagEventData.Products_ModelName_Array = [lang?.UNLIMITED_ACCESS_PLAN?.toLowerCase()];
          utagEventData.Products_PriceFull_Array = [toFixedNumString(plan?.price || 0)];
          if(response?.data?.adjustment?.[0]?.code.includes("FREE")) {
            utagEventData.Products_RevenuePerUnit_Array = ['0.00']
          } else {
            utagEventData.Products_RevenuePerUnit_Array = [toFixedNumString(plan?.price)]
          }
        } else {
          let courseContextIdFromSessionStorage = window?.sessionStorage.getItem("Products_ContextId_Array") ?? "";
          const course = getState().course.coursesMap?.[product?.partNumber]
          utagEventData.Products_Category_Array = course?.catalogTypes?.map( catalogType =>  labelL1Map?.[catalogType]?.label?.toLowerCase())
          utagEventData.Products_ModelName_Array = [course?.courseFullName?.toLowerCase()];
          utagEventData.Products_RevenuePerUnit_Array = [toFixedNumString(course?.price)]
          utagEventData.Products_PriceFull_Array = [toFixedNumString(orderInfos?.orderItemPrice)]
          utagEventData.Products_Language_Array = [course?.language];
          utagEventData.Products_ContextId_Array = [courseContextIdFromSessionStorage];
        }

        triggerTrackEvent(utagEventData);
        styledLogUtagView("Add to cart", utagEventData)

        if (redirectToCart) {
          dispatch(setShowModalByType(MODAL_TYPES.cartRightModal))
          // history.push(USER_URLS.CART.URL);

          //close course detail if we are in cart page
          if (window.location.pathname === USER_URLS.CART.URL) {
            dispatch(toggleModal(false, null, true));
          }
        }
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(getCartFailure());
      });
  };
};

export const removeProductInCart = (id: string) => {
  return (dispatch, getState) => {
    if (!id) {
      return;
    }
    const actualCart: CartModel = getState().ecommerce.cart;
    if (!actualCart) {
      console.error('cart not found');
      return;
    }

    //get orderItemId from course/lesson/plan id 
    let orderItemId: string = '';
    actualCart.orderItem.forEach(a => {
      if (a.partNumber === id) {
        orderItemId = a.orderItemId;
      }
    });
    if (!orderItemId) {
      console.error('product ' + id + ' not found in cart');
      return;
    }

    let url = urlList.REMOVE_ITEM_CART;
    dispatch(request());
    dispatch(getCartRequest());

    (mockRequests
      ? mockRemoveItemToCart(actualCart, id)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: {
          orderItemId: orderItemId,
        }
      })
    )
      .then((res: { data: CartModel }) => {
        dispatch(response());
        dispatch(getCartSuccess(res.data));
      })
      .catch((err) => {
        dispatch(response());
        dispatch(showError(err));
        dispatch(getCartFailure());
      });
  }

  function request() {
    return {
      type: actionTypes.REMOVE_ITEM_CART_REQUEST,
    };
  }

  function response() {
    return {
      type: actionTypes.REMOVE_ITEM_CART_RESPONSE,
    };
  }

};

export const getPromoList = () => {
  return (dispatch) => {

    dispatch(request());
    const url = urlList.GET_PROMOCODE;

    return axios({
      url,
      method: "GET",
      headers: getHeaders(),
    })
      .then((response: { data: PromoList }) => {
        dispatch(sucess(response.data));
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
        return Promise.reject(err);
      });
  };

  function request() {
    return {
      type: actionTypes.GET_PROMO_LIST_REQUEST,
    };
  }

  function sucess(promo) {
    return {
      type: actionTypes.GET_PROMO_LIST_SUCCESS,
      promo,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_PROMO_LIST_FAILURE,
    };
  }
};

export const assignPromoCode = (token: string) => {
  return (dispatch, getState: () => RootState) => {
    if (!token) {
      console.error('assignPromoCode - no token');
      return;
    }

    const cart = getState().ecommerce.cart;
    dispatch(getCartRequest());
    const url = urlList.APPLY_PROMOCODE.replace('{promoCode}', encodeURI(token ?? ""));

    return (mockRequests
      ? mockAssignPromoCode(cart, token)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
      })
    )
      .then((response) => {
        if (response.data.statusCode && response.data.statusCode === PROMO_STATUS_CODE.GENERIC_ERROR) {
          dispatch(getCartFailure());
          return Promise.reject('');
        } else {
          dispatch(getCartSuccess(response.data));
          return Promise.resolve(response);
        }

      })
      .catch((err) => {
        // it shouldn't be necessary to show error here
        // dispatch(showError(err));
        dispatch(getCartFailure());
        return Promise.reject(err);
      });
  };
};

export const removePromoCode = (token: string) => {
  return (dispatch) => {
    if (!token) {
      console.error('removePromoCode - no token');
      return Promise.reject();
    }

    return dispatch(removePromoCodes([token]));
  }
}

export const removePromoCodes = (tokens: string[]) => {
  return (dispatch, getState: () => RootState) => {
    if (!tokens || tokens.length <= 0) {
      console.error('removePromoCodes - no token');
      return Promise.reject();
    }

    const cart = getState().ecommerce.cart;
    dispatch(getCartRequest());

    let promises = [];
    for (let token of tokens) {
      const url = urlList.REMOVE_PROMOCODE.replace('{promoCode}', encodeURI(token ?? ""));
      let promise = mockRequests
        ? mockRemovePromoCode(cart)
        : axios({
          url,
          method: "POST",
          headers: getHeaders(),
        });

      promises.push(promise);
    }

    return Promise.all(promises)
      .then((responses: { data: CartModel }[]) => {
        //get the cart with less promos (should be the most updated)
        let minResponse = responses[0];
        let minNumPromo = minResponse?.data?.adjustment?.length;
        for (let response of responses) {
          if (response.data?.adjustment?.length < minNumPromo) {
            minResponse = response;
            minNumPromo = minResponse?.data?.adjustment?.length;
          }
        }

        dispatch(getCartSuccess(minResponse.data));
        return Promise.resolve(minResponse);
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(getCartFailure());
        return Promise.reject(err);
      });
  };
};

export const setShowModalPlan = (show: boolean, quantity: number = 1) => {
  return {
    type: actionTypes.SET_SHOW_MODAL_PLAN,
    show,
    quantity,
  };
}

export const setShowModalCourseAlreadyPlan = (show: boolean, product: ProductCart = null) => {
  return (dispatch) => {
    if (show && !product) {
      return;
    }

    dispatch({
      type: actionTypes.SET_SHOW_MODAL_COURSE_ALREADY_PLAN,
      show,
      product,
    });
  }
}

export const setShowModalAlertAddCartPlan = (show: boolean) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_ALERT_ADD_CART_PLAN,
      show,
    });
  }
}

export const setShowModalExpiredInPlan = (show: boolean) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_EXPIRED_IN_PLAN,
      show,
    });
  }
}

export const setShowModalCourseOnly1Month = (show: boolean, product: ProductCart = null, variant: string = "1m") => {
  return (dispatch) => {
    if (show && !product) {
      return;
    }

    dispatch({
      type: actionTypes.SET_SHOW_MODAL_COURSE_ONLY_1_MONTH,
      show,
      product,
      variant,
    });
  }
}

export const setShowModalLessonPartCourse = (show: boolean, product: ProductCart = null) => {
  return (dispatch) => {
    if (show && !product) {
      return;
    }

    dispatch({
      type: actionTypes.SET_SHOW_MODAL_LESSON_PART_COURSE,
      show,
      product,
    });
  }
}

export const setShowModalCart = (show: boolean) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_CART,
      show,
    });
  }
}

export const setShowModalContentExpiration = (show: boolean, product: ProductCart = null) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_CONTENT_EXPIRATION,
      show,
      product,
    });
  }
}

export const setShowModalStopRenewalConfirmationInfo = (show: boolean, data?: { expirationDate: string, orderId: string, subscriptionId: string }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_STOP_RENEWAL_CONFIRMATION,
      show,
      data,
    });
  }
}

export const setModalStartAutorenewal = (show: boolean, plan: PlanModel = null, data?: { orderId: string, subscriptionId: string }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_START_RENEWAL_CONFIRMATION,
      show,
      plan,
      data,
    });
  }
}

export const setModalActivatePlan = (show: boolean, availableLicenses?: OrderToken[]) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_ACTIVATE_PLAN,
      show,
      availableLicenses,
    })
  }
}

export const setModalRecoveringLicense = (show: boolean, email?: string, token?: string[], orderId?:string[]) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_RECOVERING_LICENSE_MODAL,
      show,
      email,
      token,
      orderId
    })
  }
}

export const setRecoveringLicenseConfirmationState = (isRecoveringLicenseConfirmed: boolean = false) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_RECOVERING_LICENSE_CONFIRMATION,
      isRecoveringLicenseConfirmed,
    })
  }
}

export const getPlan = (ecommerceEnable: boolean = false) => {
  return (dispatch, getState) => {
    //check if the user is enabled to ecommerce
    ecommerceEnable = ecommerceEnable || getState().user.ecommerceEnable;
    if (!ecommerceEnable) {
      console.log('getPlan - user not enabled to ecommerce')
      return;
    }

    let url = urlList.GET_PLAN;
    dispatch(request());

    (mockRequests
      ? mockGetPlan()
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
      })
    )
      .then((response: { data: PlanModel[] }) => {
        if (response.data?.length > 0) {
          dispatch(success(response.data[0]));
        } else {
          console.error('getPlan returned no one plan');
          dispatch(showError(''));
          dispatch(failure());
        }
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
      });
  }

  function request() {
    return {
      type: actionTypes.GET_PLAN_REQUEST,
    };
  }

  function success(plan) {
    return {
      type: actionTypes.GET_PLAN_SUCCESS,
      plan,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_PLAN_FAILURE,
    };
  }
};

export const getContact = (setShippingInfoAutomatically: boolean = false) => {
  return (dispatch) => {
    let url = urlList.GET_CONTACT;
    dispatch(requestAddContact());

    (mockRequests
      ? mockGetShippingInfo(true)
      : axios({
        url,
        method: "GET",
        headers: getHeaders(),
      })
    )
      .then((response: { data: ShippingInfo }) => {

        if (isDefaultContactPresent(response.data?.contact) && setShippingInfoAutomatically) {
          dispatch(setShippingInfo(response.data));
        } else {
          dispatch(successAddContact(response.data));
        }
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          console.log('empty billing address');
          dispatch(successAddContact(new ShippingInfo()));
        }

        dispatch(showError(err));
        dispatch(failureAddContact());
      });
  }
};

export const addContact = (address: ShippingAddress, isUpdate: boolean) => {
  return (dispatch, getState) => {
    let url = urlList.ADD_CONTACT;
    dispatch(requestAddContact());

    address.nickName = getNickNameDefault();
    // address.isNew = !isUpdate;
    address.isNew = true;

    (mockRequests
      ? mockGetShippingInfo(false, address)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: address,
      })
    )
      .then((response: { data: { addressId: string, userId: string } }) => {
        //create new contact to avoid another getContact()
        let shippingInfo: ShippingInfo = getState().ecommerce.shippingInfo;
        //transform addressLine into an array
        if (typeof address.addressLine === 'string') {
          address.addressLine = [address.addressLine];
        }
        //update address
        address.addressId = response.data.addressId;
        //store the address to the shipping info
        //because it's new default address --> overwrite all others
        shippingInfo.contact = [address];
        shippingInfo.userId = response.data.userId;

        console.log('new shipping info', shippingInfo);
        dispatch(successAddContact(shippingInfo));
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failureAddContact());
      });
  }
};

export const addContactCheckout = (address: ShippingAddress, saveAddress: boolean, successCallback = () => { }) => {
  return (dispatch, getState) => {
    const cart: CartModel = getState().ecommerce.cart;
    if (!cart || !(cart.orderItem?.length > 0)) {
      dispatch(showError(''));
      console.error('cart not found or empty orderItem', cart);
      return;
    }

    let url = urlList.ADD_CONTACT_CHECKOUT;
    dispatch(requestAddContact());

    if (saveAddress) {
      address.nickName = getNickNameDefault();
    }

    address.isNew = true;

    (mockRequests
      ? mockGetShippingInfo(false, address)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: {
          ...address,
          orderId: cart.orderId,
          shipModeId: cart.orderItem[0].shipModeId
        },
      })
    )
      .then((response: { data: CartModel }) => {
        //create new contact to avoid another getContact()
        let shippingInfo: ShippingInfo = getState().ecommerce.shippingInfo;
        if (shippingInfo) {
          //transform addressLine into an array
          if (typeof address.addressLine === 'string') {
            address.addressLine = [address.addressLine];
          }
          address.addressId = response.data.orderItem?.[0]?.addressId;

          if (!shippingInfo.contact) {
            shippingInfo.contact = [];
          }

          //set that the address is temporary
          if (!saveAddress) {
            address.isTemporary = true;
            shippingInfo.contact.push(address);
          } else {
            //if new default address --> overwrite all others
            shippingInfo.contact = [address];
          }

          //update address
        }
        console.log('new shipping info', shippingInfo);
        dispatch(successAddContact(shippingInfo));

        dispatch(getCartSuccess(response.data));

        if (successCallback) {
          successCallback();
        }
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failureAddContact());
      });
  }
};

export const setShippingInfo = (shippingInfo: ShippingInfo) => {
  return (dispatch, getState) => {
    if (!shippingInfo) {
      dispatch(showError(''));
      console.error('shippingInfo not found', { shippingInfo });
      return;
    }
    const addressId = getDefaultContact(shippingInfo?.contact)?.addressId;
    if (!addressId) {
      //is possible that addressId is not found because all addresses are temporary
      // dispatch(showError(''));
      dispatch(successAddContact(shippingInfo));
      console.error('setShippingInfo() - addressId not found', { addressId });
      return;
    }

    const cart: CartModel = getState().ecommerce.cart;
    if (!cart || !(cart.orderItem?.length > 0)) {
      dispatch(showError(''));
      dispatch(successAddContact(shippingInfo));
      console.error('cart not found or empty orderItem', cart);
      return;
    }


    let url = urlList.SET_SHIPPING_INFO;
    dispatch(requestAddContact());

    axios({
      url,
      method: "POST",
      headers: getHeaders(),
      data: {
        addressId: addressId,
        orderId: cart.orderId,
        shipModeId: cart.orderItem[0].shipModeId
      },
    })
      .then((response: { data: ShippingInfo }) => {
        dispatch(successAddContact(shippingInfo));
        dispatch(getCartSuccess(response.data));
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failureAddContact());
      });
  }
};

export const cleanTemporaryContacts = () => {
  return (dispatch, getState) => {
    let shippingInfo: ShippingInfo = cloneDeep(getState().ecommerce.shippingInfo);

    if (shippingInfo) {
      shippingInfo.contact = shippingInfo.contact?.filter(a => a.nickName?.startsWith(CONTACT_DEFAULT_PREFIX));

      dispatch({
        type: actionTypes.SET_SHIPPING_INFO,
        shippingInfo
      })
    }
  }
}


function requestAddContact() {
  return {
    type: actionTypes.GET_SHIPPING_INFO_REQUEST,
  };
}

function successAddContact(shippingInfo) {
  return {
    type: actionTypes.GET_SHIPPING_INFO_SUCCESS,
    shippingInfo,
  };
}

function failureAddContact() {
  return {
    type: actionTypes.GET_SHIPPING_INFO_FAILURE,
  };
}

export const setPaymentAndConfirm = (paymentInstruction: PaymentInstructionRequest, isCartZeroPayment: boolean = false, token?: string) => {
  return (dispatch, getState) => {
    let payload = paymentInstruction;
    const cart: CartModel = getState().ecommerce.cart;
    const coursesMap = getState().course.coursesMap;
    // const isPlanAutorenewalActive = cart.orderItem?.some(cartItem => cartItem?.autoRenewalActive)
    const isPlanAutorenewalActive = getState().ecommerce.renewalPlanActive;

    if (isCartZeroPayment) {
      if (token) {
        const shippingInfo = getState().ecommerce.shippingInfo;
        const addressId = getDefaultContact(shippingInfo?.contact, true)?.addressId;

        payload = new PaymentInstructionRequest();

        payload.piAmount = cart.grandTotal.toString();
        payload.orderid = cart.orderId.toString();
        payload.cc_brand = CARD_ZERO;
        payload.payMethodId = CARD_ZERO;
        payload.billing_address_id = addressId;
        payload.token = token;
        payload.currency = cart?.totalProductPriceCurrency;
      } else {
        console.error('setPaymentAndConfirm - token not found');
        return;
      }
    }

    //check if the user is buying a master
    payload.isMasterProgram = false;
    payload.courseAndQuantityMap = {};
    cart.orderItem.forEach((item) => {
      const lesson = coursesMap[item.partNumber];
      if (isCourseMaster(lesson)) {
        //at least one master is in cart
        payload.isMasterProgram = true;

        //if the user is buying a master --> add courseIdMaster and quantity into the payload
        payload.courseAndQuantityMap[item.partNumber] = item.quantity;
      }
    })

    const forterToken = getCookie(COOKIE.FORTER_TOKEN);
    payload.forterToken = forterToken;

    let url = urlList.SET_PAYMENT_AND_CONFIRM;
    // let url = urlList.SET_PAYMENT_AND_CONFIRM2;
    return (mockRequests
      ? promiseMock({
        data: {
          orderId: 2,
          resourceName: 'order',
        }
      })
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: payload,
        params: {
          autoRenewalActive: !!isPlanAutorenewalActive
        }
      })
    ).then((response) => {
      //update cart
      dispatch(getCart());

      const orderId = response.data.orderId;
      removeCookie(COOKIE.AUTORENEWAL_ACTIVE);
      history.push(USER_URLS.ORDER_CONFIRMATION.URL.replace(":orderId", orderId));

      return Promise.resolve(response);
    }).catch((error: AxiosError) => {
      console.error('setPaymentAndConfirm error', error);

      if (error.response?.data?.message === "ERROR_MASTER_NO_AVAILABLE_SUBSCRIPTIONS") {
        console.error('Master - not enough available seats for the master');
        checkoutErrorUtag("Error placing order - not enough available seats for the master", ErrorTypes.user);

        //get updated seats available for masters
        if (payload.courseAndQuantityMap) {
          Object.keys(payload.courseAndQuantityMap).forEach(courseIdMaster => {
            dispatch(updateCourse(courseIdMaster, {}));
          })
        }

        history.push(USER_URLS.CART.URL);
      }


      if ([400, 403].includes(error.response.status)) {
        checkoutErrorUtag("Error placing order", ErrorTypes.user);
      }
      if (error.response.status === 500) {
        dispatch(showError());

        if (error.response?.data?.message?.includes(PAYMENT_ERROR_RECAPTCHA)) {
          console.error('ReCaptcha not validated by BE');
          checkoutErrorUtag("Error placing order - ReCaptcha not validated by BE", ErrorTypes.user);
        } else {
          checkoutErrorUtag("Error placing order", ErrorTypes.server);
        }
      }

      return Promise.reject(error);
    })
  }
};

export const getUsablePaymentInfo = () => {
  let url = urlList.GET_USABLE_PAYMENT_INFO_URL;
  axios({
    url,
    method: "POST",
    headers: getHeaders(),
  }).then(response => {
    console.log('getUsablePaymentInfo', response.data);
  })
};

export const getOrderById = (id: string) => {
  const mock = false;
  return (dispatch) => {
    dispatch(request());
    return (mock
      ? mockGetOrderById(id)
      : axios({
        url: urlList.GET_ORDER_BY_ID.replace("{orderId}", encodeURI(id ?? "")),
        method: "GET",
        headers: getHeaders(),
      })
    )
      .then((response: { data }) => {
        dispatch(success(response.data, id));
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        dispatch(showError());
        dispatch(failure());
        return Promise.reject(err);
      });
  };

  function request() {
    return {
      type: actionTypes.GET_ORDER_BY_ID_REQUEST,
    };
  }

  function success(data, id) {
    return {
      type: actionTypes.GET_ORDER_BY_ID_SUCCESS,
      data,
      id,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_ORDER_BY_ID_FAILURE,
    };
  }
};
export const getOrderByIdWithToken = (id: string) => {
  return (dispatch) => {
    dispatch(request());
    return (mockRequests
      ? mockGetOrderByIdWithToken(id)
      : axios({
        url: urlList.GET_ORDER_WITH_TOKEN.replace("{orderId}", encodeURI(id ?? "")),
        method: "GET",
        headers: getHeaders(),
      })
    )
      .then((response: { data }) => {
        dispatch(success(response.data, id));
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        dispatch(showError());
        dispatch(failure(id));
        return Promise.reject(err);
      });
  };

  function request() {
    return {
      type: actionTypes.GET_ORDER_BY_ID_REQUEST,
    };
  }

  function success(data, id) {
    return {
      type: actionTypes.GET_ORDER_BY_ID_SUCCESS,
      data,
      id,
    };
  }

  function failure(id) {
    return {
      type: actionTypes.GET_ORDER_BY_ID_FAILURE,
      id,
    };
  }
}

export const mockGetOrderEnrichmentById = async (id: string) => {
  const data = await fetch('/data/ecommerce/getOrderEnrichmentById.json');
  const dataJson = await data.json();
  return promiseMock({ data: { orderId: id, ...dataJson } }, false, 1000);
};

//use in thank you page only
export const getOrderEnrichmentConfirmationById = (id: string) => {

  return (dispatch) => {
    dispatch(request());

    axios({
      url: urlList.GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID.replace("{orderId}", encodeURI(id ?? "")),
      method: "GET",
      headers: getHeaders(),
    })
    .then((response: { data }) => {
      dispatch(success(response.data, id));
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      dispatch(showError());
      dispatch(failure());
      return Promise.reject(err);
    });

    function request() {
      return {
        type: actionTypes.GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_REQUEST
      }
    }
  
    function success(data, id) {
      return {
        type: actionTypes.GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_SUCCESS,
        data,
        id,
      };
    }
  
    function failure() {
      return {
        type: actionTypes.GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_FAILURE,
      };
    }

  }
}

export const getOrderEnrichmentById = (id: string) => {
  const mock = false;

  return (dispatch) => {
    dispatch(request());

    (mock ?
      mockGetOrderEnrichmentById(id)
      : axios({
        url: urlList.GET_ORDER_ENRICHMENT_BY_ID.replace("{orderId}", encodeURI(id ?? "")),
        method: "GET",
        headers: getHeaders(),
      })
    )
      .then((response: { data }) => {
        dispatch(success(response.data, id));
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        dispatch(showError());
        dispatch(failure());
        return Promise.reject(err);
      });
  };

  function request() {
    return {
      type: actionTypes.GET_ORDER_ENRICHMENT_BY_ID_REQUEST
    }
  }

  function success(data, id) {
    return {
      type: actionTypes.GET_ORDER_ENRICHMENT_BY_ID_SUCCESS,
      data,
      id,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_ORDER_ENRICHMENT_BY_ID_FAILURE,
    };
  }

};

const mockGetTokenInfo = async () => {
  const licensesInfo = await fetch(`/data/ecommerce/getModdleTokens.json`);
  const licenseInfoJson: OrderToken[] = await licensesInfo.json();
  return { data: licenseInfoJson };
};

export const getAllTokens = (orderIdList: string[]) => {
  const mock = false;
  return (dispatch) => {
    if (!orderIdList || orderIdList.length === 0) {
      console.error('orderIds not found or empty', orderIdList);
    }

    let url = urlList.GET_TOKENS_BY_ORDER_ID;
    dispatch(request());

    (mock
      ? mockGetTokenInfo()
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: {
          orderIdList: orderIdList,
        }
      })
    )

      .then((response: { data: OrdersTokens }) => {
        //split tokens by order id
        let ordersTokens: OrdersTokens = {
          tokenList: response.data.tokenList,
          availableTokens: response.data.availableTokens,
          activatedTokens: response.data.activatedTokens,
          expiredTokens: response.data.expiredTokens,
          pendingTokens: response.data.pendingTokens
        };
        for (let token of response.data.tokenList) {
          if (!ordersTokens?.[token.orderid]) {
            ordersTokens[token.orderid] = [];
          }
          ordersTokens?.[token.orderid].push(token);
        }

        dispatch(success(ordersTokens));
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
      });

    function request() {
      return {
        type: actionTypes.GET_ALL_TOKENS_REQUEST,
      };
    }

    function success(data) {
      return {
        type: actionTypes.GET_ALL_TOKENS_SUCCESS,
        data,
      };
    }

    function failure() {
      return {
        type: actionTypes.GET_ALL_TOKENS_FAILURE,
      };
    }
  }

};

export const getTokensByOrderId = (orderId: string) => {
  return (dispatch) => {
    let url = urlList.GET_TOKENS_BY_ORDER_ID;
    dispatch(request());

    const body = {
      username: "",
      orderIdList: [orderId],
    }

    return (mockRequests
      ? mockGetTokensByOrderId(orderId)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: body,
      })
    )
      .then((response: { data }) => {
        dispatch(success(response.data?.items, orderId));
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
      });

    function request() {
      return {
        type: actionTypes.GET_ORDER_TOKENS_REQUEST,
      };
    }

    function success(data, id) {
      return {
        type: actionTypes.GET_ORDER_TOKENS_SUCCESS,
        data,
        id,
      };
    }

    function failure() {
      return {
        type: actionTypes.GET_ORDER_TOKENS_FAILURE,
      };
    }
  }

};

export const openCartCourseDetail = (cartItem) => {
  return (dispatch) => {
    if (cartItem.isPlan) {
      history.push(USER_URLS.PLAN.URL);
    } else {
      dispatch(toggleModalCourseById(cartItem.id.toString()));
    }
  }
}

export const getPaymentDetails = (onSuccess: () => void, onFailure: (err: AxiosError) => void) => {
  return (dispatch) => {
    let url = urlList.GET_PAYMENT_INFO_LIST_URL;
    dispatch(request());

    const mock = mockRequests;

    (mock
      ? mockGetPaymentDetails()
      : axios({
        url,
        method: "GET",
        headers: getHeaders(),
      })
    )
      .then((response: { data: WalletRequestResponse }) => {
        if (Array.isArray(response.data?.wallet)) {
          dispatch(success(response.data?.wallet));
        } else {
          dispatch(showError());
          dispatch(failure());
        }
        onSuccess?.();
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          console.log('empty payment details');
          dispatch(success([]));
        }

        dispatch(showError(err));
        dispatch(failure());
        onFailure?.(err);
      });

    function request() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_REQUEST,
      };
    }

    function success(data) {
      return {
        type: actionTypes.GET_PAYMENT_INFO_SUCCESS,
        data,
      };
    }

    function failure() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_FAILURE,
      };
    }
  }
};

export const addPaymentDetail = (paymentInstruction: WalletRequestBody, isDefault, onSuccess: (wallet: any[]) => void = () => { }) => {
  return (dispatch, getState) => {

    const paymentInfo: WalletRequestResponse["wallet"] = getState().ecommerce.paymentInfo;

    dispatch(request());
    let url = isDefault ? urlList.ADD_PAYMENT_INFO_DEFAULT : urlList.ADD_PAYMENT_INFO;


    const mock = mockRequests;

    (mock
      ? mockAddPaymentDetail(paymentInstruction, paymentInfo, isDefault)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: paymentInstruction
      })
    )
      .then((response: { data: WalletRequestResponse }) => {
        if (response.data?.wallet) {
          onSuccess?.(response.data?.wallet);
          dispatch(success(response.data.wallet));
        } else {
          dispatch(showError());
          dispatch(failure());
        }
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
      });

    function request() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_REQUEST,
      };
    }

    function success(data) {
      return {
        type: actionTypes.GET_PAYMENT_INFO_SUCCESS,
        data,
      };
    }

    function failure() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_FAILURE,
      };
    }
  };
};
export const updatePaymentDetail = (paymentInstruction: WalletRequestBody, walletId: string, isDefault: boolean) => {

  return (dispatch, getState) => {

    const paymentInfo: WalletRequestResponse["wallet"] = getState().ecommerce.paymentInfo;

    let url = urlList.UPDATE_PAYMENT_INFO.replace('{walletId}', walletId);

    dispatch(request());

    const mock = mockRequests;

    

    (mock
      ? mockUpdatePaymentDetail(paymentInstruction, paymentInfo, isDefault)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
        data: paymentInstruction,
        params: {
          makeDefault: (isDefault? isDefault : false)
        }
      })
    )
      .then((response: { data: WalletRequestResponse }) => {
        console.log('data', response.data);
        if (response.data?.wallet) {
          dispatch(success(response.data.wallet));
        } else {
          dispatch(showError());
          dispatch(failure());
        }
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
      });

    function request() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_REQUEST,
      };
    }

    function success(data) {
      return {
        type: actionTypes.GET_PAYMENT_INFO_SUCCESS,
        data,
      };
    }

    function failure() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_FAILURE,
      };
    }
  };
};

export const removePaymentDetail = (id: string | number, onSuccess: () => void, onFailure: () => void) => {
  return (dispatch, getState) => {

    const paymentInfo: WalletRequestResponse["wallet"] = getState().ecommerce.paymentInfo;

    let url = urlList.REMOVE_PAYMENT_INFO.replace('{walletId}', id + '');
    dispatch(request());

    const mock = mockRequests;

    (mock
      ? mockRemovePaymentDetail(id, paymentInfo)
      : axios({
        url,
        method: "POST",
        headers: getHeaders(),
      })
    )
      .then((response: { data: WalletRequestResponse }) => {
        if (response.data?.wallet) {
          dispatch(success(response.data.wallet));
          onSuccess();
        } else {
          dispatch(showError());
          dispatch(failure());
          onFailure();
        }
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
        onFailure();
      });

    function request() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_REQUEST,
      };
    }

    function success(data) {
      return {
        type: actionTypes.GET_PAYMENT_INFO_SUCCESS,
        data,
      };
    }

    function failure() {
      return {
        type: actionTypes.GET_PAYMENT_INFO_FAILURE,
      };
    }
  };
};

export const setAutorenewalActive = (active: boolean, plan: PlanModel = null) => {
  return (dispatch) => {
    setCookie(COOKIE.AUTORENEWAL_ACTIVE, JSON.stringify({ active, plan }));
    dispatch({
      type: actionTypes.SET_PLAN_RENEWAL,
      active,
      plan,
    });
  }
}

export const setActiveRenewalPlan = (
  orderId: string,
  subscriptionId: string,
  active: boolean,
  walletId: string = null,
): Promise<{ orderId: string[] }> => {
  let url = (active
    ? urlList.ENABLE_PLAN_RENEWAL
    : urlList.DISABLE_PLAN_RENEWAL
  ).replace("{orderId}", orderId);


  // const activateParams = {
  //   startDate: new Date().toISOString(),
  //   fulfillmentInterval: "1",
  //   fulfillmentIntervalUOM: UOM.ANN,
  //   // requesttype: "json",
  // };

  const mock = mockRequests;

  return mock
    ? promiseMock({
      orderId: [orderId],
    })
    : axios({
      url,
      method: "POST",
      headers: getHeaders(),
      data: active ? {
        walletId,
        subscriptionId,
      } : undefined,
      params: active ? {
        orderId,
      } : {
        orderId,
        subscriptionId,
      },
    });
};

export const getSubscriptionList = () => {
  return (dispatch, getState) => {


    let url = urlList.GET_SUBSCRIPTION_LIST_V2;
    dispatch(request());

    const mock = false;

    (mock
      ? mockGetSubscriptionList()
      : axios({
        url,
        method: "GET",
        headers: getHeaders(),
        // params: {
        //   q: "byBuyerIdAndSubscriptionType",
        //   subscriptionTypeCode: "RecurringOrder",
        //   // should this be used? 
        //   profileName: "IBM_Store_Summary",
        // },
      })
    )
      .then((response: { data: GetSubscriptionResponse }) => {
        if (response.data?.userPlans) {
          const userPlan = selectUserPlan({ ecommerce: { userPlans: response.data.userPlans } } as any);
          const subscription = selectSubscription({ ecommerce: { wcsdetail: response.data?.wcsdetail || {} } } as any);
          const showCta = userPlan?.isFree || isInThePast(userPlan?.timeEnded) || userPlan?.isInactive || isPlanExpired(subscription, userPlan);
          if (!showCta) {
            // remove temporary cookie after order placed if plan purchased
            removeCookie(COOKIE.PLAN_PURCHASED);
          }
        }
        if (response.data?.wcsdetail || response.data?.userPlans) {
          dispatch(success(response.data?.wcsdetail || {}, response.data?.userPlans || { bought: {}, activated: {} }, response.data?.tokenDetailDto || []));
          // onSuccess();
        } else {
          dispatch(showError());
          dispatch(failure());
          // onFailure();
        }
      })
      .catch((err) => {
        dispatch(showError(err));
        dispatch(failure());
        // onFailure();
      });

    function request() {
      return {
        type: actionTypes.GET_SUBSCRIPTION_LIST_REQUEST,
      };
    }

    function success(wcsdetail, userPlans, planTokens) {
      return {
        type: actionTypes.GET_SUBSCRIPTION_LIST_SUCCESS,
        wcsdetail,
        userPlans,
        planTokens
      };
    }

    function failure() {
      return {
        type: actionTypes.GET_SUBSCRIPTION_LIST_FAILURE,
      };
    }
  };
};
export const setShowCartRightModal = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_CART_RIGHT_MODAL,
      value
    });
  }
};
