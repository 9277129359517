import {useSelector} from "@hooks/redux";
import {getHeaders} from '@utility/Api';
import {UserGroup} from "@model/User"
import {useDispatch} from 'react-redux';
import {
	//setAlreadyInSimulation,
	setShowDialogUserGroupSimulationPage,
	setShowLabelPersonificationBanner,
	setUserSimulated
} from '@redux-actions/';
import {ECellType, TCellDataHeader} from "@components/UI/TableV2/TableV2Logic";
import {PERSONIFICATION_URLS} from "../../config";
import {isPersonificationOn} from "./UserGroupSimulation_PersonificateLogic";
import {useState} from "react";
import UserGroupSimulation from "./UserGroupSimulation";
import { promiseMock } from "@utility/ecommerceUtility";
import { ResponseGetProductsFilter } from "@model/ProductClass";
import UserGroupSimulationFilters, { ResponseGetProductsUserGroup } from "./UserGroupSimulationFilters";

const axios = require('axios');

enum EUserGroup {
	favorite    = 'favorite',
	macroUser   = 'simulableMacrouser',
	brand       = 'retailer',
	area        = 'brandRegion',
	country     = 'country',
	role        = 'userType',
	inUse       = 'simulableInUse',
	isManager   ='isManager',
	sortBy      ='sortBy',
	macroUserZA = 'macroUserZA',
	action = 'action',  // not fetched
}

export enum EUserGroupPayloadReqCases {
	firstFetch = "firstFetch",
	reset = "reset",
	resetModal = "resetModal",
	newPage = "newPage",
	applyNewFilters = "applyNewFilters",
	checkbox = "checkbox",
	removalFromChip = "removalFromChip",
	sortingTable = "sortingTable",
	sortingModal = "sortingModal",
}

const HEADER_TABLE_USER: Array<TCellDataHeader> = [
	{ key:EUserGroup.favorite, label: "", type: ECellType.ICON, sortable: false , filterable: false, fixedWidth: 72},
	{ key:EUserGroup.macroUser, labelKey: "PROFILE_PERSONIFICATION_TABLE_MACROUSER", type: ECellType.TAGGED , sortable: true, filterable: true, fixedWidth: 300},
	{ key:EUserGroup.brand, labelKey: "PROFILE_PERSONIFICATION_TABLE_BRAND", type: ECellType.DEFAULT, sortable: true, filterable: true, fixedWidth: 200},
	{ key:EUserGroup.area, labelKey: "PROFILE_PERSONIFICATION_TABLE_AREA",  type: ECellType.DEFAULT, sortable: true, filterable: true, fixedWidth: 180},
	{ key:EUserGroup.country, labelKey: "PROFILE_PERSONIFICATION_TABLE_COUNTRY", type: ECellType.DEFAULT, sortable: true, filterable: true, fixedWidth: 180},
	{ key:EUserGroup.role, labelKey: "PROFILE_PERSONIFICATION_TABLE_ROLE", type: ECellType.DEFAULT, sortable: true , filterable: true, fixedWidth: 238},
	{ key:EUserGroup.isManager, labelKey: "PROFILE_PERSONIFICATION_LABEL_CHECKBOX_MY_TEAM_INLINE", type: ECellType.DATA, sortable: false , filterable: true},
	{ key:EUserGroup.action, label: "", type: ECellType.LINK, sortable: false , filterable: false, fixedWidth: 150},
]

export type ResponseUsergroupInfos = {
	simulationFilters: {[key: string]: UsergroupSimulationFilters},
	simulableUsers: Array<Array<UserGroup>>,
	alreadyInSimulation : boolean,
	totalElements: number,
	totalPages: number,
	page: number
}

export type UsergroupSimulationFilters = {
	orderBy: number,
	filters: ResponseGetProductsFilter[]
}

export type FetchParams = {
	param?: number; //page to fetch
	filtersToApply?: ResponseGetProductsFilter[]; //truthy filters
	filterToRemove?: ResponseGetProductsFilter; // filter to remove - chip case
	firstFiltersFetched?: {[key: string]: UsergroupSimulationFilters}; //first filters retrieved
	currentFilters?: {[key: string]: UsergroupSimulationFilters}; //filters applied currently
	columnKey?: string //key to use in order to change payload the right way
	type?: string
	numElements?: number //number of users to retrieve for each page
}

const mockUserGroupSimulationFilters = async () => {
	const usergroupSimulationFilters = await fetch("../../data/usergroupsimulation/getUsergroupSimulationFiltersMock.json");
	const usergroupSimulationFiltersJson = await usergroupSimulationFilters.json();

	return promiseMock({data: usergroupSimulationFiltersJson}, false, 1000)
}

const mockUserGroupSimulation = async (param: number) => {
	let urls = [
		"../../data/usergroupsimulation/getUsergroupSimulationMock.json",
		"../../data/usergroupsimulation/getUsergroupSimulationMock2.json",
		"../../data/usergroupsimulation/getUsergroupSimulationMock3.json",
	]

	const usergroupSimulationUsers = await fetch(urls[param]);
	const usergroupSimulationUsersJson = await usergroupSimulationUsers.json();
	return promiseMock({data: usergroupSimulationUsersJson}, false, 6000);
}

/**
 * API of [simulation/getUsersForSimulation](https://qu.leonardo.essilorluxottica.com/newuni/bff/webjars/swagger-ui/index.html?configUrl=/newuni/bff/v3/api-docs/swagger-config#/simulation/getUsersForSimulation)
 * */
const useGetUsersForSimulation = () => {
	const getUsers = (
		requestCallback?: () => void,
		successCallback?: (res) => void, //TODO FIX
		errorCallback?: (res: ResponseUsergroupInfos | null) => void,
		onRequestDone?: () => void,
		param?: number,
		filtersToApply?: ResponseGetProductsFilter[],
		setCurrPage?: (param: number) => void,
		setIsLoading?: (isLoading: boolean) => void,
		setAllowHightlight?: (val: boolean) => void,
		setAllowIsChecked?: (val: boolean) => void,
		initialFilters?:  {[key: string]: UsergroupSimulationFilters}, 
		currentFilters?: {[key: string]: UsergroupSimulationFilters},
		columnKey?: string,
		filterToRemove?: ResponseGetProductsFilter,
		type?: string,
		numElements?: number,
		setResetDDMValueShown?: (val: boolean) => void,
	) => {
		const mock = false;
		//console.log("🚀", filtersToApply, initialFilters, currentFilters, columnKey, filterToRemove, type)
		
		if(type == EUserGroupPayloadReqCases.sortingModal || type == EUserGroupPayloadReqCases.sortingTable) {
			if(setAllowHightlight) setAllowHightlight(true)
			if(setAllowIsChecked) setAllowIsChecked(true)
		}
		
		if(type == EUserGroupPayloadReqCases.resetModal) {
			if(setAllowIsChecked) setAllowIsChecked(false);
		}

		if(type == EUserGroupPayloadReqCases.reset) {
			if(setResetDDMValueShown) setResetDDMValueShown(false)
		}

		let payload = newPayload(filtersToApply, initialFilters, currentFilters, columnKey, filterToRemove, type)
		//console.log(payload)
		if(setIsLoading) setIsLoading(true)
		if (requestCallback) requestCallback();
		(mock ? 
			(mockUserGroupSimulation(param))
			:
			axios.post(
				`${PERSONIFICATION_URLS.URL_GET_LIST_USERS_W2}?page=${param}&numElements=${numElements}`,
				payload,
				{ headers: getHeaders() }
			)
		)
			.then(response => {
				const data: ResponseUsergroupInfos  = response.data;
				if (successCallback)
					if(setCurrPage) setCurrPage(param)
					successCallback(prev => (
					{...data,
						simulableUsers: 
							prev?.simulableUsers && param != 0 ? //se la pagina è la 0 allora non devi mantenere i precedenti!!!
								[...prev?.simulableUsers, [...data?.simulableUsers]]
								: [data?.simulableUsers]
					})
					
				);
			})
			.catch((error) => {
			console.error('error initial filters', error);
			if (errorCallback) {
				errorCallback(null);
			}
		}).finally(() => {
			if (onRequestDone) onRequestDone()
		});
	}

	return getUsers
};

/**
 * Use useGetUsersForSimilation for setting into redux the user simulated.
 * It's used in LabelPersonificationBanner that persiste in every page.
 * @see useGetUsersForSimilation
 * @see LabelPersonificationBanner
 * */
const useGetUserSimulated = (): null | UserGroup =>  {
	const dispatch = useDispatch()
	const userSimulatedFromRedux = useSelector(state=>state.utils.userSimulated)
	const [userSimulatedFounded, setUserSimulatedFounded] = useState<UserGroup>(userSimulatedFromRedux)
	const [bIsLoading, setIsLoading] = useState<boolean>(false)
	if(userSimulatedFromRedux == null){
		const getUsersSimulable = useGetUsersForSimulation()
		if(!bIsLoading){
			getUsersSimulable(
				()=>{setIsLoading(true)},
				(users)=>{
					if(users.alreadyInSimulation){
						const userSimulatedFound = users.simulableUsers.find(user=>user[EUserGroup.inUse]) as UserGroup
						setUserSimulatedFounded(userSimulatedFound)
						dispatch(setShowLabelPersonificationBanner(true))
						dispatch(setUserSimulated(userSimulatedFound))
						//dispatch(setAlreadyInSimulation(true))
					}
					else{
						//dispatch(setAlreadyInSimulation(false))
						//setUserSimulatedFounded(null)
					}


				},
				null,
			)
		}
	}

	return userSimulatedFounded

};

const requestOnlyFilters = (filtersToApply, initialFilters, currentFilters, columnKey, filterToRemove, type, callback) => {

	// console.log("🚀 filtersReq:\n",
	// "filtersToApply: ",filtersToApply, "\n",
	// "initialFilters: ", initialFilters, "\n",
	// "currentFilters: ", currentFilters, "\n", 
	// "columnKey: ", columnKey, "\n",
	// "filterToRemove: ", filterToRemove, "\n",
	// "type: ", type
	// );
	const mock = false;
	const payload = newPayload(filtersToApply, initialFilters, currentFilters, columnKey, filterToRemove, type);
	//console.log("🚀 filtersReq", payload);

	(mock ? 
		(mockUserGroupSimulationFilters())
		:
		axios.post(
			`${PERSONIFICATION_URLS.URL_GET_USERS_SIMULATION_FILTERS}`,
			payload,
			{ headers: getHeaders() }
		)
	)
		.then(response => {
			const data: Partial<ResponseUsergroupInfos>  = response.data;
			if(callback) callback(data);
		})
		.catch((error) => {
			console.error('error filters', error);
		}).finally(() => {
			//setFiltersLoading(false)
		});
}


/**
 * @see DialogUserGroupSimulationPage
 * */
const useGetStringToWriteInDialog = (): string | null =>{
	const lang = useSelector(state=>state.utils.lang)
	const userSimulated = useSelector(state=> state.utils.userSimulated)
	const bIsPersonificationOn = isPersonificationOn()
	const dispatch = useDispatch()

	if(lang  == undefined || userSimulated == undefined) return null

	// decide which string show
	let stringToWrite = lang?.PROFILE_PERSONIFICATION_BANNER_SIMULATION_ON // case 1
	if(!bIsPersonificationOn && userSimulated == null){                              // case 2
		stringToWrite = lang?.PROFILE_PERSONIFICATION_BANNER_SIMULATION_OFF
		dispatch(setShowDialogUserGroupSimulationPage(true))
	}

	/*else                                                                          // case 3
		stringToWrite = lang?.PROFILE_PERSONIFICATION_LABEL_WARNING_ON_START_SIMULATION_ALREADY_ON*/

	return stringToWrite
}

const newPayload = (
	filtersToApply: ResponseGetProductsFilter[],
	initialFilters: {[key: string]: UsergroupSimulationFilters},
	currentFilters:  {[key: string]: UsergroupSimulationFilters},
	columnKey: string,
	filterToRemove: ResponseGetProductsFilter,
	type: string) => {

	let updatedFilters: {[key: string]: UsergroupSimulationFilters} = cloneDeep(currentFilters)

	switch(type) {
		case EUserGroupPayloadReqCases.firstFetch: 
			updatedFilters = initialFilters;
			break;
		case EUserGroupPayloadReqCases.newPage:
			break;
		case EUserGroupPayloadReqCases.reset:
			for(let filter in updatedFilters) {
				updatedFilters[filter].filters.forEach(item => item.checked = false)
			}
			break;
		case EUserGroupPayloadReqCases.resetModal:
			for(let filter in updatedFilters) {
				updatedFilters[filter].filters.forEach(item => item.checked = false);
				updatedFilters[filter].orderBy = 0;
			}
			break;
		case EUserGroupPayloadReqCases.removalFromChip:
			const filtersToApplyIdsRemovalFromChip = filtersToApply.map(filter => filter.id);
			for(let filter of updatedFilters[columnKey].filters) {
				filter.checked = filtersToApplyIdsRemovalFromChip.includes(filter.id)
			}
			updatedFilters[columnKey].filters = updatedFilters[columnKey]?.filters.map(filter => 
				filter.id === filterToRemove?.id ? {...filter, checked: false} : filter
			)
			break;
		case EUserGroupPayloadReqCases.applyNewFilters:
			const filtersToApplyIds = filtersToApply.map(filter => filter.id);
			for(let filter of updatedFilters[columnKey].filters) {
				filter.checked = filtersToApplyIds.includes(filter.id)
			}
			break;
		case EUserGroupPayloadReqCases.checkbox:
			updatedFilters[columnKey].filters = updatedFilters[columnKey].filters.map(filter => 
				(filter.id === "TRUE" || filter.id === "true") ? {...filter, checked: !filtersToApply[0].checked} : filter
			)
			break;
		case EUserGroupPayloadReqCases.sortingTable:
			for(let filter in updatedFilters) {
				filter === columnKey ? 
					updatedFilters[filter].orderBy = updatedFilters[filter].orderBy !== 1 ? 1 : 2
				:
				updatedFilters[filter].orderBy = 0
			}
			break;
		case EUserGroupPayloadReqCases.sortingModal:
			for(let filter in updatedFilters) {
				if(columnKey === EUserGroup.macroUserZA){
					updatedFilters[filter].orderBy = filter === EUserGroup.macroUser ? 2 : 0
				} else{
					updatedFilters[filter].orderBy = filter === columnKey ? 1 : 0
				}
			}
			break;
	}

	return updatedFilters

}

export {
	HEADER_TABLE_USER,
	EUserGroup,
	useGetUsersForSimulation,
	useGetUserSimulated,
	useGetStringToWriteInDialog,
	requestOnlyFilters
}