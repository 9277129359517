import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LangMap } from '@model/CoursesClass';
import { printHeaderLink, printLink, PUBLIC_URLS, USER_URLS } from '../link-utils';
import { isMobile, isQualityEnv } from '@utility/Api';
import { useSelector } from '@hooks/redux';
import { UserProfile } from '@model/User';
import ChevronRight from '@svg-icons/chevron-right.svg';
import UserIcon from '@svg-icons/user2.svg';
import useModalTypes from '@hooks/useModalByType';
import { useIsEnabledForCollections } from '@hooks/collections/useIsEnabledForCollections';
import useShowEducationalPathsMenuHeader from '@hooks/educationalPaths/useShowEducationalPathsMenuHeader';
import { selectHighlightedCourses, selectPrograms } from '../../store/selectors/coursesSelectors';
import useL1List from '@hooks/useL1List';
import ButtonV2 from '@components/UI/ButtonV2';
import { isUserEl360, hasUserActivePlan, isBycEnabled } from '@utility/userUtility';
import AccountIconGold from '@svg-icons/header/gold-account.svg';
import StarGold from '@svg-icons/header/star-gold.svg';
import { HEADER } from '@utility/const';
import useShowBuildYourCareerMenuHeader from '@hooks/buildYourCareer/useShowBuildYourCareerMenuHeader';
import { getHeaderMenuViaCMSRow } from '@redux-actions/';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from '@utility/clsx';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';

type Props = {
  closeMenu: () => void;
  isAuthenticated: boolean;
  show: boolean;
  logout: () => void;
  isManager: boolean;
  isTeacher: boolean;
  fixed: boolean;
  userProfile: UserProfile;
  isLight?: boolean;
};

const MenuMobile = ({
  closeMenu,
  show,
  logout,
  isTeacher = false,
  fixed,
  isLight,
  userProfile,
}: Props) => {
  const lang: LangMap = useSelector(state => state.utils.lang);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const highlightedCourses = useSelector(selectHighlightedCourses);
  const location = useLocation();
  const isUserEnabledForCollections = useIsEnabledForCollections();
  const showCollectionsDropdown = useSelector(
    state => !!state.trainingPills?.latestCollectionsCarouselInfo?.collection?.noveltiesByBrand
  );
  const showCollectionsLink = useSelector(
    state =>
      isUserEnabledForCollections &&
      (!state.trainingPills?.latestCollectionsCarouselInfo?.isCompleted || showCollectionsDropdown)
  ); //UU-2149
  const [showEducationalPathsMenu] = useShowEducationalPathsMenuHeader();
  const [l1List, l0list, y, x, loading] = useL1List(null, false);
  const userEl360 = isUserEl360(userProfile);
  const coursesMap = useSelector(state => state.course.coursesMap);
  const isUserPlanActive = hasUserActivePlan(userProfile);
  const programs = useSelector(selectPrograms);
  const isBycUser = useSelector(state => state.user.isBycUser);
  const isBycOffice = useSelector(state => state.user.isBycOffice);
  const [showBuildYourCareerMenu] = useShowBuildYourCareerMenuHeader();

  const showCommercialOfferPage = userEl360 ? (programs.length > 0 ? true : false) : true;

  //cms new pages header
  const [isCMSPersonalized, setIsCMSPersonalized] = useState<boolean>(false);
  const stateOfLinks = useSelector(state => state.utils.linksHeaderFromCMS);
  const hideLogin = useSelector(state => state.utils.hideLogin)
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
	const [ShowVisionCareModal, setShowVisionCareModal] = useState<boolean>(false);

  const [showLogin, setShowLogin] = useState<boolean>(false);

  useEffect(() => {
    if (stateOfLinks.length > 0 && !isAuthenticated) {
      setIsCMSPersonalized(true);
    }
  }, [stateOfLinks]);

  useEffect(() => {
    if (coursesMap) {
      let showModal = Object.values(coursesMap).some(course => course.level0 === "vision_care");
      setShowVisionCareModal(showModal);
  }
  }, [coursesMap]);

  useEffect(() => {
    setShowLogin(!hideLogin);
  }, [hideLogin])

  const handleShowCurrentPage = (event, page: keyof typeof USER_URLS) => {
    if (location.pathname === USER_URLS[page].URL && show) {
      closeMenu();
    }
  };

  const handleShowCurrentPageInDynamicHeader = (event) => {
    if (show) {
      closeMenu();
    }
  };

  const handleClickBycMenuMobile = () => {
		if (location.pathname === USER_URLS.BUILD_YOUR_CAREER.URL) {
			window.location.reload();
		}
	}

  const handleClickUpcomingMenuMobile = () => {
		if (location.pathname === USER_URLS.PROFILE_UPCOMING_EVENTS.URL) {
			window.location.reload();
		}
	}

  const handleClickHomepageMenuMobile = () => {
		if (location.pathname === USER_URLS.HOMEPAGE.URL) {
			window.location.reload();
		}
	}

  const whiteMenu = HEADER.ISWHITE;

  const viewportHeight = window.outerHeight;
  let classBasedOnTheViewport = 'menu-mobile__navigation';
  let menuProfileBasedOnViewport = 'menu-mobile__profile-section';
  let hrBasedOnViewport = 'menu-mobile__hr sticky';
  /* if (viewportHeight >= 865) {
    classBasedOnTheViewport = 'menu-mobile__navigation sticky';
  }*/
  if (true) {
    hrBasedOnViewport = 'menu-mobile__hr notSticky';
    menuProfileBasedOnViewport = 'menu-mobile__profile-section notSticky';
  }

  const modalTypes = useModalTypes();

  const handleClickMenuMButtonCMS = (e: any, href: string) => {
    e.preventDefault()
    document.getElementById(href)?.scrollIntoView({ behavior: "smooth" })
  }

  const setVhModalRight = () => {

    if (isMobile.any()) {
      let modalRight = document.querySelector('.modal-right') as HTMLElement;
      let modalCMSLogin = modalRight?.querySelector('.menu-mobile.cms-personalized') as HTMLElement;
      let modalCMSLoginMenuNav = modalCMSLogin?.querySelector('.menu-mobile__navigation') as HTMLElement;
      if (modalCMSLoginMenuNav) {
        //89 is header's height
        modalCMSLoginMenuNav.style.minHeight = (window.innerHeight - 89) + 'px';
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setVhModalRight);
    return () => window.removeEventListener("resize", setVhModalRight);
  }, [])

  useEffect(() => {
    setVhModalRight()
  }, [!!isCMSPersonalized])

  return (
    <>
      {isCMSPersonalized && stateOfLinks.length > 0 && (
        <nav
          className={clsx('menu-mobile', {
            'dark-variant-mobile': !whiteMenu,
            'cms-personalized': isCMSPersonalized
          })}
          aria-label={lang?.MENU}
        >
          <ul className={classBasedOnTheViewport + (fixed ? ' fixed' : '')}>
            <>
              <div className={"menu-mobile__links"}>
                {stateOfLinks.map(link => (
                  <li key={link}
                    className="menu-mobile__item-head"
                    onClick={event => handleShowCurrentPageInDynamicHeader(event)}
                  >
                    <a href={link.linkString} className="menu-mobile__item-head"
                      onClick={(e) => handleClickMenuMButtonCMS(e, link.linkString)}>
                      {link.linkLabel}
                    </a>
                  </li>
                ))}
              </div>
              {showLogin &&
                <div className='menu-mobile__cta'>
                  <ButtonV2
                    className="header-v3__button-login"
                    variant="primary"
                    small
                    to={PUBLIC_URLS.LOGIN.URL}
                    handleClick={()=>{buildClickDataTrackingObject("login")}}
                  >
                    {t('LOGIN_CTA')}
                  </ButtonV2>
                </div>}
            </>
          </ul>
        </nav>
      )}
      {!isCMSPersonalized && (
        <nav
          className={'menu-mobile' + (whiteMenu ? '' : ' dark-variant-mobile')}
          aria-label={lang?.MENU}
        >
          <ul className={classBasedOnTheViewport + (fixed ? ' fixed' : '')}>
            <div className="menu-mobile__links">
              <hr className="menu-mobile__hr" />
              <li
                className="menu-mobile__item-head"
                onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.NAV_HOME));handleShowCurrentPage(event, 'HOMEPAGE'); handleClickHomepageMenuMobile()}}
              >
                {printLink('HOMEPAGE', lang)}
              </li>
              {showBuildYourCareerMenu && (
                <li
                  className="menu-mobile__item-head"
                  onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.BUILD_CAREER_TITLE));handleShowCurrentPage(event, 'BUILD_YOUR_CAREER'); handleClickBycMenuMobile()}}
                >
                  {printHeaderLink('BUILD_YOUR_CAREER', lang, true)}
                </li>
              )}
              {showEducationalPathsMenu && (isBycUser === false && isBycOffice === false) && (
                // LLEO-925
                <li
                  className="menu-mobile__item-head"
                  onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.EDUCATIONAL_PATHS_TITLE));handleShowCurrentPage(event, 'EDUCATIONAL_PATHS')}}
                >
                  {printLink('EDUCATIONAL_PATHS', lang)}
                </li>
              )}
              {ShowVisionCareModal &&
              <li className="menu-mobile__item-head">
                <button
                  onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.VISION_CARE_TITLE));modalTypes.visionCareMenu.open()}}
                >
                  {lang?.VISION_CARE_TITLE}
                  <span className="button-no-back menu-mobile__arrow">
                    <ChevronRight />
                  </span>
                </button>
              </li>
              }
              {(l0list.length > 0 ||
                (showCollectionsLink &&
                  l0list.length === 0 &&
                  highlightedCourses.length !== 0)) && ( //collections
                  <li className="menu-mobile__item-head">
                    <button
                      onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.COLLECTIONS_LABEL));modalTypes.collectionsMenu.open()}}
                    >
                      {lang?.COLLECTIONS_LABEL}
                      <span className="button-no-back menu-mobile__arrow">
                        <ChevronRight />
                      </span>
                    </button>
                  </li>
                )}

              {showCollectionsLink &&
                l0list.length === 0 &&
                highlightedCourses.length === 0 && ( //latestcollections
                  <li
                    className="menu-mobile__item-head"
                    onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.LATEST_COLLECTIONS_MENU_TITLE));handleShowCurrentPage(event, 'LATEST_COLLECTIONS')}}
                  >
                    {printLink('COLLECTIONS_TAB', lang)}
                  </li>
                )}

              <li
                className="menu-mobile__item-head"
                onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.PROFILE_UPCOMING_EVENTS));handleShowCurrentPage(event, 'PROFILE_UPCOMING_EVENTS'); handleClickUpcomingMenuMobile()}}
              >
                {printLink('PROFILE_UPCOMING_EVENTS', lang)}
              </li>

              {/*showCommercialOfferPage && (
                <li
                  className="menu-mobile__item-head"
                  onClick={event => handleShowCurrentPage(event, 'COMMERCIAL_OFFER')}
                >
                  {printLink('COMMERCIAL_OFFER', lang)}
                </li>
              )*/}
              <li className="menu-mobile__item-head">
                <button
                  onClick={modalTypes.areaOfExpertiseMenu.open}
                  data-element-id="mainNav_educationalCatalog"
                >
                  {lang?.AREAS_OF_EXPERTISE}
                  <span className="button-no-back menu-mobile__arrow">
                    <ChevronRight />
                  </span>
                </button>
              </li>

              {isTeacher && (
                <li
                  className="menu-mobile__item-head"
                  onClick={event => handleShowCurrentPage(event, 'CATALOGUE')}
                >
                  <a
                    target="_blank"
                    href={
                      isQualityEnv()
                        ? 'https://qu-backend.leonardo.essilorluxottica.com'
                        : 'https://backend.leonardo.essilorluxottica.com'
                    }
                  >
                    {lang.TEACHER_BO}
                  </a>
                </li>
              )}
            </div>
            <div className={menuProfileBasedOnViewport}>
              <li className="menu-mobile__item profile" 
              onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.MY_PROFILE));modalTypes.profileMenu.open()}}>
                <div className="menu-mobile__item-content-profile">
                  <>
                    {userEl360 || isUserPlanActive ? (
                      <div className="container-gold">
                        <AccountIconGold className="gold-circle" />
                        <StarGold className="gold-star" />
                        {lang.PROFILE_PAGE}
                      </div>
                    ) : (
                      <span className="flex align-center">
                        <span className="circle">
                          <UserIcon />
                        </span>
                        {lang.PROFILE_PAGE}
                      </span>
                    )}
                  </>
                  <button
                    aria-label={lang.PROFILE_PAGE}
                    className="button-no-back arrow"
                    tabIndex={0}
                  >
                    <ChevronRight />
                  </button>
                </div>
              </li>

              <li
                className="menu-mobile__item"
                onClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.PROFILE_SETTINGS));handleShowCurrentPage(event, 'PROFILE_SETTINGS')}}
              >
                {printLink('PROFILE_SETTINGS', lang)}
              </li>

              <li
                className="menu-mobile__item"
                onClick={event => handleShowCurrentPage(event, 'CONTACT_US')}
              >
                {printLink('CONTACT_US', lang)}
              </li>

              {/* <li
            className="menu-mobile__item"
            onClick={event => handleShowCurrentPage(event, 'HOWTO')}
          >
            {printLink('HOWTO', lang)}
          </li> */}

              <li className="menu-mobile__item logout">
                <ButtonV2
                  variant="text-btn2"
                  small
                  className="plus-button"
                  handleClick={event => {buildClickDataTrackingObject("mainNav_"+camelCase(lang.LOG_OUT));logout()}}
                  aria-label={lang.LOG_OUT}
                >
                  {lang?.LOG_OUT}
                </ButtonV2>
              </li>
            </div>
          </ul>
        </nav>
      )}
    </>
  );
};

export default MenuMobile;
