import React, { CSSProperties } from 'react';
import ArrowLeftIcon from '@svg-icons/arrow-left6.svg';
import ArrowLongLeftIcon from '@svg-icons/arrow-left6.svg';
import ArrowRightIcon from '@svg-icons/arrow-right6.svg';
import ArrowLongRightIcon from '@svg-icons/arrow-right6.svg';
import PlayIcon from '@svg-icons/play-2.svg';
import clsx from '@utility/clsx';
import { useSelector } from '@hooks/redux';
import useLangAuth from '@hooks/useLangAuth';
import { Link } from 'react-router-dom';
import LazySVG from '@components/LazySvg';

export type Props = {
  loading?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  variant?:
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-vtransparent'
  | 'text-btn'
  | 'text-btn2'
  | 'text-btn-no-arrow'
  | 'text-btn-dotted'
  | 'play-btn'
  | 'icon-btn'
  | 'outline-btn'
  | 'counter-btn'
  | 'overlay-btn';
  small?: boolean;
  title?: string;
  ariaLabel?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  to?: string;
  target?: string;
  rel?: string;
  tabIndex?: number;
  forceFocus?: boolean;
  variantLongArrow?: boolean;
  leadingIcon?: boolean;
  forwardRef?;
  ['data-element-id']?: string;
  ['data-description']?: string;
  hideLoadingLabel?: boolean;
  numberCounter?: number;
  addStopPropagation?: boolean;
  showArrowLeft?: boolean;
  showArrowRight?: boolean;
};

const ButtonV2 = ({
  loading,
  handleClick = () => { },
  disabled,
  children,
  className,
  style,
  variant,
  title,
  ariaLabel,
  type,
  small,
  to,
  target,
  rel,
  tabIndex = 0,
  forceFocus,
  variantLongArrow,
  leadingIcon,
  numberCounter,
  'data-element-id': dataElementId,
  'data-description': dataDescription,
  forwardRef,
  hideLoadingLabel,
  addStopPropagation,
  showArrowLeft= false,  //show Left arrow in CTA Block in CMS New Pages
  showArrowRight = false //show Right arrow in CTA Block in CMS New Pages
}: Props) => {
  
  const showArrowCTABlock = showArrowLeft || showArrowRight;

  const Wrapper =
    variant === 'text-btn' || variant === 'text-btn2'
      ? { component: TextBtnWrapper, props: { variantLongArrow, leadingIcon, showArrowLeft, showArrowRight } }
      : variant === 'play-btn'
        ? { component: PlayBtnWrapper, props: {} }
        : variant === 'counter-btn'
          ? { component: CounterWrapper, props: { numberCounter } }
          : { component: showArrowCTABlock ? ButtonPrimaryWrapper : React.Fragment, props: showArrowCTABlock ? {showArrowLeft, showArrowRight} : {} };


  const classes = clsx('btn-v4', {
    loading: loading,
    [className]: !!className,
    [variant]: !!variant,
    disabled: !!disabled,
    small: small,
    ['force-focus']: forceFocus,
    ['leading-icon']: leadingIcon && !showArrowLeft,
    "arrowed": showArrowCTABlock
  });

  // const [div, size] = useElementSize();

  const Cta = to ? Link : 'button';

  return (
    <Cta
      className={classes}
      onClick={addStopPropagation
        ? (e: any) => { e?.stopPropagation(); handleClick(e)}
        : handleClick}
      disabled={disabled || loading}
      title={title}
      aria-label={ariaLabel}
      style={style}
      type={type}
      to={to ? to : undefined}
      target={target ? target : undefined}
      rel={rel ? rel : undefined}
      tabIndex={tabIndex}
      data-element-id={dataElementId}
      data-description={dataDescription}
      ref={forwardRef}
      aria-hidden={tabIndex < 0 || tabIndex === undefined ? 'true' : undefined}
    >
      {loading ? (
        <LoadingSpinner hideLoadingLabel={hideLoadingLabel} />
      ) : (
        <Wrapper.component {...Wrapper.props}>{children}</Wrapper.component>
      )}
    </Cta>
  );
};

ButtonV2.displayName = 'ButtonV2';

export default ButtonV2;

const LoadingSpinner = ({ hideLoadingLabel }) => {
  const labels = useLangAuth(['LOADING']);

  return (
    <span className="loading-content">
      <LazySVG
        className="loading-spinner"
        src="/images/spinner/button-v2-spinner.png"
        alt={labels?.LOADING}
      />
      {!hideLoadingLabel && <span>{labels?.LOADING?.toUpperCase()}</span>}
    </span>
  );
};

const TextBtnWrapper = ({ children, variantLongArrow = false, leadingIcon = false,  showArrowLeft = false , showArrowRight = false }) => {
  return (
    <>
      {leadingIcon && (
        showArrowLeft
        ? (variantLongArrow ? <ArrowLongLeftIcon /> : <ArrowLeftIcon />)
        : (variantLongArrow ? <ArrowLongLeftIcon /> : <ArrowLeftIcon />)
      )}
      <span>{children}</span>
      {!leadingIcon && (
        showArrowRight
        ? (variantLongArrow ? <ArrowLongRightIcon /> : <ArrowRightIcon />)
        : (variantLongArrow ? <ArrowLongRightIcon /> : <ArrowRightIcon />)
      )}
    </>
  );
};

const PlayBtnWrapper = ({ children }) => {
  const lang = useSelector(state => state.utils.lang);
  return (
    <>
      <span className="circle">
        <PlayIcon />
      </span>
      <span className="label">
        {children || lang.WATCH_VIDEO}
        {/* <span className="value">{children}</span> */}
      </span>
    </>
  );
};

const CounterWrapper = ({ children, numberCounter = 0 }) => {
  const lang = useSelector(state => state.utils.lang);
  return (
    <>
      <div className='counter-label'>
        {children}
      </div>
      <div className="number-counter">
        <span>{numberCounter}</span>
      </div>
    </>
  );
};

const ButtonPrimaryWrapper = ({ children, showArrowLeft = false, showArrowRight = false }) => {
  return (
    <>
      {showArrowLeft && <ArrowLeftIcon/> }
      <span>{children}</span>
      {showArrowRight && <ArrowRightIcon/> }
    </>
  );
};

