import {Nullable} from "../../types/utility";
import { EMargin, TCMSLink, TCMSSubjectTaxonomy } from "./CMSTypes";

export type TCMSRawLayoutVariant = {
	name: Nullable< string >,
}

export type TCMSRawLayoutVariants = Array<TCMSRawLayoutVariant>

export type TCMSRawValidDates = {
	validTo: Nullable< string >,
	validFrom: Nullable< string >,
}

export type TCMSRawSubjectTaxonomy = {
	subjectTaxonomy: Nullable< Array<TCMSSubjectTaxonomy> >,
}

export type TCMSRawGeneralInfo = {
	id: Nullable< string >,
	name: Nullable< string >,
	type: Nullable< string >,
	title: Nullable< string >,
	htmlTitle: Nullable< string >,
	htmlDescription: Nullable< string >,
}

export enum ECMSMultimediaContentType {
	JPEG = 'image/jpeg',
	JPG = 'image/jpg',
	PNG = 'image/png',
	VIDEO = 'video/mp4'
}

export type TCMSRawMultimediaTypeData = {
	uri: Nullable< string >,
	contentType: Nullable< ECMSMultimediaContentType >,
}

export type TCMSRawMultimediaTypeCropsDimensions = {
	width: Nullable< number >,
	height: Nullable< number >,
}

export type TCMSRawMultimediaTypeCrops = {
	name: Nullable< string >,
	aspectRatio: Nullable< TCMSRawMultimediaTypeCropsDimensions >,
	size: Nullable< Array<TCMSRawMultimediaTypeCropsDimensions> >,
}

export type TCMSRawMultimediaTypeItem = {
		// extends TCSMSLayoutInfo
		// extends TCMSLayoutSubjectTaxonomy
		// extends TCMSLayoutValidDates
		data: Nullable< TCMSRawMultimediaTypeData >,
		cropUrlTemplate: Nullable< string >,
		crops: Nullable< TCMSRawMultimediaTypeCrops >,
	}
	& TCMSRawGeneralInfo
	& TCMSRawSubjectTaxonomy
	& TCMSRawValidDates;

export type TCMSRawMultimediaType = Array<TCMSRawMultimediaTypeItem>

export type TCMSRawFontBackground = {
	font: Nullable< string >,
	backgroundColour: Nullable< string >,
	backgroundImage: Nullable< string >,
}

export type TCMSRawItemText = {
	description: Nullable< string >,
	description2: Nullable< string >,
	linkLabel: Nullable< string >,
	linkString: Nullable< string >,
	titleColour: Nullable< string >,
	description1Colour: Nullable< string >,
	description2Colour: Nullable< string >,
	linkLabelColour: Nullable< string >,
	componentIcon: Nullable< string >
}

export type TCMSTeaserLink = {
	linkUrl: Nullable< string >,
	title: Nullable< string >
	targetWindow: "_self" | "_blank" | null,
}

export type TCMSRawItem = {
	// extends TCSMSLayoutInfo
	// extends TCSMSLayoutVariant
	// extends TCMSLayoutValidDates
	// extends TCMSLayoutSubjectTaxonomy
	// extends TCMSLayoutFontBackground
	// extends TCMSLayoutItemText

	collectionTitle: Nullable<string>,
	collectionSubTitle: Nullable<string>,
	teaserTargetList:Nullable< Array<TCMSTeaserLink> >,
	badge: Nullable<unknown>,
	duration: Nullable<unknown>,
	complexity: Nullable<unknown>,
	chipLabels: Nullable< Array<unknown> >,
	modificationDate: Nullable< string >,
	creationDate: Nullable< string >,
	sequence: Nullable< unknown >,
	imageContent: Nullable< Array<unknown> >,
	multiMediaType: Nullable< TCMSRawMultimediaType >,
	multiMediaType2: Nullable< TCMSRawMultimediaType >,
	linkTarget: Nullable< Array<unknown> >,
	brand: Nullable< Array<unknown> >,
	offerId: Nullable< Array<unknown> >,
	analyticsTag: Nullable< Array<unknown> >,
	model: Nullable< Array<unknown> >,
	modelColor: Nullable< Array<unknown> >,
	category: Nullable< unknown  >,
	layoutVariant: Nullable< TCMSRawLayoutVariants >,
	items: Nullable< Array<TCMSRawItem> >,
	cmsResponse?: any,
	catalog?: any
}
	& TCMSRawGeneralInfo
	& TCMSRawLayoutVariant
	& TCMSRawValidDates
	& TCMSRawSubjectTaxonomy
	& TCMSRawFontBackground
	& TCMSRawItemText;


// ------------------------------------------------ ROW
//<editor-fold desc="ROW">

export type TCMSRawPlacement = {
		viewtype: 'default' | '50+50' | '33+33+33' | '25+25+25+25',
		name: Nullable<string>,
		items: Nullable<Array<Partial<TCMSRawItem>>>
		margin: EMargin | null
}

export type TCMSRawPlacements = Array<{placements: Nullable<TCMSRawPlacement>}>

export type TCMSRawStructurePageByPath = {
	// extends TCMSLayoutInfo
	// extends TCMSLayoutSubjectTaxonomy
	grid:{
		pageLayout: Nullable<string>,
		rows: TCMSRawPlacements
	}
} & TCMSRawGeneralInfo
	& TCMSRawSubjectTaxonomy

export type TCMSRawStructure = {
	data: {
		content: {
			pageByPath: Nullable<TCMSRawStructurePageByPath>
		}
	}
}

//</editor-fold>