import {
  isBrandCarouselPage,
  isBrandL2Page,
  isBrandPage,
  isBrandSubcataloguePage,
  isBrandTPSubcataloguePage,
  PUBLIC_URLS,
  USER_URLS,
} from '@components/link-utils';
import { SENDER_SYSTEM } from './const';
import { getNotReadNumber } from './firebaseUtility';
import { titles } from './pageTitles';

//NOW THE APP IS CALLED ALWAYS LEONARDO
export const getAppTitle = userProfile => {
  const titleLeonardo = 'Leonardo'; // Learning platform | EssilorLuxottica
  return titleLeonardo;

  const titleEssilorLux = 'Essilor Luxottica | University';
  const titleLux = 'Luxottica | University';
  const titleEssilor = 'Essilor | University';

  if (!userProfile || !userProfile.senderSystem) {
    return titleEssilorLux;
  }

  switch (userProfile.senderSystem.toUpperCase()) {
    case SENDER_SYSTEM.LUXOTTICA:
      return titleLux;
    case SENDER_SYSTEM.ESSILOR:
      return titleEssilor;
    default:
      return titleLux;
  }
};

//NOW THE FAVICON IS ALWAYS LEONARDO
export const getFavicon = (showNotif = false) => {
  const faviconLeonardo = '/images/favicons/leonardo/';
  const faviconLeonardoNotified = '/images/favicons/leonardo-notified/';
  return showNotif ? faviconLeonardoNotified : faviconLeonardo;

  const faviconEssilorLux = '/images/favicons/essilor-luxottica/';
  const faviconLux = '/images/favicons/luxottica/';
  const faviconLuxNotified = '/images/favicons/luxottica-notified/';
  const faviconEssilor = '/images/favicons/essilor/';
  const faviconEssilorNotified = '/images/favicons/essilor-notified/';

  if (!userProfile || !userProfile.senderSystem) {
    return faviconEssilorLux;
  }

  switch (userProfile.senderSystem.toUpperCase()) {
    case SENDER_SYSTEM.LUXOTTICA:
      return showNotif ? faviconLuxNotified : faviconLux;
    case SENDER_SYSTEM.ESSILOR:
      return showNotif ? faviconEssilorNotified : faviconEssilor;
    default:
      return showNotif ? faviconLuxNotified : faviconLux;
  }
};

export const setFavicon = (notifications, coursesMap, userProfile) => {
  const num = getNotReadNumber(notifications, coursesMap, userProfile);
  const showNotif = num > 0;

  let favicon32 = document.getElementById('app-icon-32');
  let favicon16 = document.getElementById('app-icon-16');
  let faviconTouch = document.getElementById('app-touch-icon');
  // let faviconManifest = document.getElementById('app-manifest');
  let faviconMask = document.getElementById('app-mask-icon');
  let faviconShortcut = document.getElementById('app-icon-shortcut');
  let faviconConfig = document.getElementById('msapplication-config');

  const favicon = getFavicon(showNotif);
  if (favicon32) favicon32.href = favicon + 'favicon-32x32.png';
  if (favicon16) favicon16.href = favicon + 'favicon-16x16.png';
  if (faviconTouch) faviconTouch.href = favicon + 'apple-touch-icon.png';
  if (faviconMask) faviconMask.href = favicon + 'safari-pinned-tab.svg';
  if (faviconShortcut) faviconShortcut.href = favicon + 'favicon.ico';
  if (faviconConfig) faviconConfig.content = favicon + 'browserconfig.xml';
};

const getPageName = (lang, labelKey, fallbackLabel) => {
  return lang && lang[labelKey] ? lang[labelKey] : fallbackLabel;
};

export const getMyTeamMemberPageName = lang => {
  const memberUsername = location.pathname.replace(/.*\//, '');
  return (
    getPageName(lang, USER_URLS.PROFILE_MY_TEAM_MEMBER.NAME, titles.MY_TEAM_MEMBER_TITLE) +
    ' - ' +
    memberUsername
  );
};

export const setDocumentTitle = (
  location,
  notifications,
  coursesMap,
  userProfile,
  lang,
  searchQuery,
  catalogueDocTitle,
  translate,
  currentCourse,
  showCourseModal
) => {
  const num = getNotReadNumber(notifications, coursesMap, userProfile);
  let pageName = '';
  let showAppName = true;

  let courseName = currentCourse?.courseFullName;

  if (courseName && showCourseModal) {
    pageName = courseName;
  }

  const appName = 'Leonardo'; // getAppTitle(userProfile);

  if (location && location.pathname && !showCourseModal) {
    switch (location.pathname) {
      case PUBLIC_URLS.HOMEPAGE.URL:
        pageName = translate(PUBLIC_URLS.HOMEPAGE.DOC_TITLE);
        showAppName = false;
        break;
      case PUBLIC_URLS.CONTACT_US.URL:
        pageName = translate(PUBLIC_URLS.CONTACT_US.DOC_TITLE);
        showAppName = false;
        break;
      case PUBLIC_URLS.MANAGE_COOKIES.URL:
        pageName = translate(PUBLIC_URLS.MANAGE_COOKIES.DOC_TITLE);
        showAppName = false;
      case USER_URLS.HOMEPAGE.URL:
        pageName = getPageName(lang, USER_URLS.HOMEPAGE.NAME, titles.HOMEPAGE_TITLE);
        break;
      case PUBLIC_URLS.LOGIN.URL:
        //pageName = getPageName(lang, PUBLIC_URLS.LOGIN.NAME);
        pageName = translate(PUBLIC_URLS.LOGIN.DOC_TITLE);
        showAppName = false;
        break;
      case PUBLIC_URLS.REGISTER.URL:
      case PUBLIC_URLS.REGISTER_SUCCESS.URL:
        pageName = getPageName(lang, PUBLIC_URLS.REGISTER.NAME, titles.REGISTER_TITLE);
        showAppName = false;
        break;
      case PUBLIC_URLS.EXPIRED_PASSWORD.URL:
        pageName = getPageName(
          lang,
          PUBLIC_URLS.EXPIRED_PASSWORD.NAME,
          titles.PASSWORD_RESET_TITLE
        );
        showAppName = false;
        break;
      case PUBLIC_URLS.RESET_PASSWORD.URL:
      case PUBLIC_URLS.RESET_PASSWORD_SUCCESS.URL:
        pageName = getPageName(lang, PUBLIC_URLS.RESET_PASSWORD.NAME, titles.PASSWORD_RESET_TITLE);
        showAppName = false;
        break;
      case PUBLIC_URLS.FORGOT_PASSWORD.URL:
      case PUBLIC_URLS.FORGOT_PASSWORD_SUCCESS.URL:
        pageName = getPageName(
          lang,
          PUBLIC_URLS.FORGOT_PASSWORD.NAME,
          titles.FORGOT_PASSWORD_TITLE
        );
        showAppName = false;
        break;
      case PUBLIC_URLS.SERVICE_PAGE.URL:
        pageName = getPageName(lang, PUBLIC_URLS.SERVICE_PAGE.NAME, titles.SERVICE_PAGE_TITLE);
        showAppName = false;
        break;
      case PUBLIC_URLS.LEGAL.URL:
        pageName = getPageName(lang, PUBLIC_URLS.LEGAL.NAME, titles.LEGAL_TITLE);
        showAppName = false;
        break;
      case USER_URLS.SUBCATALOGUE.URL:
        pageName = getPageName(lang, USER_URLS.SUBCATALOGUE.NAME, titles.CATALOGUE_TITLE);
        if (catalogueDocTitle) {
          pageName += ' - ' + (lang?.[catalogueDocTitle] || catalogueDocTitle);
        }
        break;
      case USER_URLS.SUBCATALOGUE_SEARCH.URL:
        pageName = getPageName(lang, USER_URLS.SUBCATALOGUE_SEARCH.NAME, titles.SEARCH_RESULTS);
        pageName = pageName.replace('{SEARCH}', searchQuery ? searchQuery : '');
        break;
      case USER_URLS.PROFILE.URL:
        pageName = getPageName(lang, USER_URLS.PROFILE.NAME, titles.PROFILE_TITLE);
        break;
      case USER_URLS.PROFILE_TO_DO.URL:
        pageName = getPageName(lang, USER_URLS.PROFILE_TO_DO.NAME, titles.TO_DO_TITLE);
        break;
      case USER_URLS.PROFILE_UPCOMING_EVENTS.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_UPCOMING_EVENTS.NAME,
          titles.UPCOMING_EVENTS_TITLE
        );
        break;
      case USER_URLS.PROFILE_MY_PROGRESS.URL:
        pageName = getPageName(lang, USER_URLS.PROFILE_MY_PROGRESS.NAME, titles.MY_PROGRESS_TITLE);
        break;
      case USER_URLS.PROFILE_MY_CERTIFICATES.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_MY_CERTIFICATES.NAME,
          titles.MY_CERTIFICATES_TITLE
        );
        break;
      case USER_URLS.PROFILE_MY_TEAM.URL:
        pageName = getPageName(lang, USER_URLS.PROFILE_MY_TEAM.NAME, titles.MY_TEAM_TITLE);
        break;
      case USER_URLS.PROFILE_SETTINGS.URL:
        pageName = getPageName(lang, USER_URLS.PROFILE_SETTINGS.NAME, titles.SETTINGS_TITLE);
        break;
      case USER_URLS.PROFILE_SETTINGS_PLATFORM.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_SETTINGS_PLATFORM.NAME,
          titles.SETTINGS_TITLE
        );
        break;
      case USER_URLS.PROFILE_SETTINGS_CONTENT.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_SETTINGS_CONTENT.NAME,
          titles.SETTINGS_TITLE
        );
        break;
      case USER_URLS.PROFILE_SETTINGS_BILLING.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_SETTINGS_BILLING.NAME,
          titles.SETTINGS_TITLE
        );
        break;
      case USER_URLS.PROFILE_SETTINGS_PAYMENT.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_SETTINGS_PAYMENT.NAME,
          titles.SETTINGS_TITLE
        );
        break;
      case USER_URLS.PROFILE_PERSONAL_SETTINGS.URL:
      case USER_URLS.PROFILE_PERSONAL_SETTINGS_SUCCESS.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_PERSONAL_SETTINGS.NAME,
          titles.PERSONAL_SETTINGS_TITLE
        );
        break;
      case USER_URLS.PROFILE_ORDER_HISTORY.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_ORDER_HISTORY.NAME,
          titles.PROFILE_ORDER_HISTORY
        );
        break;
      case USER_URLS.PROFILE_ACTIVATE_LICENSE.URL:
        pageName = getPageName(
          lang,
          USER_URLS.PROFILE_ACTIVATE_LICENSE.NAME,
          titles.PROFILE_ACTIVATE_LICENSE
        );
        break;
      case USER_URLS.PROFILE_MY_CHANNELS.URL:
        pageName = getPageName(lang, USER_URLS.PROFILE_MY_CHANNELS.NAME, titles.MY_CHANNELS_TITLE);
        break;
      case USER_URLS.WISHLIST.URL:
        pageName = getPageName(lang, USER_URLS.WISHLIST.NAME, titles.WISHLIST_TITLE);
        break;
      case USER_URLS.EDITORIAL_CAROUSEL:
        pageName = getPageName(
          lang,
          USER_URLS.EDITORIAL_CAROUSEL.NAME,
          titles.EDITORIAL_CAROUSEL_TITLE
        );
        break;
      case USER_URLS.LT_PAGE:
        pageName = getPageName(lang, USER_URLS.LT_PAGE.NAME, titles.LT_PAGE_TITLE);
        break;
      case USER_URLS.NOTIFICATIONS.URL:
        pageName = getPageName(lang, USER_URLS.NOTIFICATIONS.NAME, titles.NOTIFICATIONS_TITLE);
        break;
      case USER_URLS.CONTACT_US.URL:
        pageName = getPageName(lang, USER_URLS.CONTACT_US.NAME, titles.CONTACT_US_TITLE);
        break;
      case USER_URLS.MANAGE_COOKIES.URL:
        pageName = getPageName(lang, USER_URLS.MANAGE_COOKIES.NAME, titles.MANAGE_COOKIES);
        break;
      case USER_URLS.LEGAL.URL:
        pageName = getPageName(lang, USER_URLS.LEGAL.NAME, titles.LEGAL_TITLE);
        break;
      case USER_URLS.HOWTO.URL:
        pageName = getPageName(lang, USER_URLS.HOWTO.NAME, titles.HOWTO_TITLE);
        break;
      case USER_URLS.DELETE_PROFILE.URL:
        pageName = getPageName(lang, USER_URLS.DELETE_PROFILE.NAME, titles.DELETE_PROFILE_TITLE);
        break;
      case PUBLIC_URLS.DELETE_USER_SUCCESS.URL:
        pageName = translate(PUBLIC_URLS.DELETE_USER_SUCCESS.NAME);
        break;
      case USER_URLS.SUBCATALOGUE_TP.URL:
        pageName = getPageName(lang, USER_URLS.SUBCATALOGUE_TP.NAME, titles.TPS_TITLE);
        break;
      case USER_URLS.PLAN.URL:
        pageName = getPageName(lang, USER_URLS.PLAN.NAME, titles.PLAN);
        break;
      case USER_URLS.COMMERCIAL_OFFER.URL:
        pageName = getPageName(lang, USER_URLS.COMMERCIAL_OFFER.NAME, titles.COMMERCIAL_OFFER);
        break;
      case USER_URLS.CART.URL:
        pageName = getPageName(lang, USER_URLS.CART.NAME, titles.CART);
        break;
      case USER_URLS.CHECKOUT.URL:
        pageName = getPageName(lang, USER_URLS.CHECKOUT.NAME, titles.CHECKOUT);
        break;
      case USER_URLS.ONBOARDING_COURSE.URL:
        pageName = getPageName(lang, USER_URLS.ONBOARDING_COURSE.NAME, titles.ONBOARDING_COURSE);
      case USER_URLS.LATEST_COLLECTIONS.URL:
        pageName = getPageName(lang, USER_URLS.LATEST_COLLECTIONS.NAME, titles.LATEST_COLLECTIONS);
        break;
      case USER_URLS.EDUCATIONAL_PATHS.URL:
        pageName = getPageName(lang, USER_URLS.EDUCATIONAL_PATHS.NAME, titles.EDUCATIONAL_PATHS);
        break;
      case USER_URLS.EMPTY_PAGE:
        pageName = getPageName(lang, USER_URLS.EMPTY_PAGE, titles.EMPTY_PAGE);
      default:
        break;
    }

    if (
      location.pathname.includes(USER_URLS.DETAILS_TP.URL.replace(':tpId', '')) &&
      location.pathname !== USER_URLS.SUBCATALOGUE_TP.URL
    ) {
      pageName = catalogueDocTitle ? catalogueDocTitle : titles.TP_DETAILS_TITLE;
    }

    if (
      isBrandPage(location.pathname) ||
      isBrandSubcataloguePage(location.pathname) ||
      isBrandTPSubcataloguePage(location.pathname) ||
      isBrandCarouselPage(location.pathname) ||
      isBrandL2Page(location.pathname)
    ) {
      return;
    }

    if (location.pathname.includes(USER_URLS.PROFILE_SEND_TOKEN.NORMAL_URL)) {
      pageName = getPageName(lang, USER_URLS.PROFILE_SEND_TOKEN.TITLE, titles.SEND_TOKEN);
    }

    if (
      location.pathname.includes(USER_URLS.PROFILE_ORDER_HISTORY_DETAILS.NORMAL_URL) &&
      location.pathname !== USER_URLS.PROFILE_ORDER_HISTORY_DETAILS.NORMAL_URL
    ) {
      pageName = getPageName(
        lang,
        USER_URLS.PROFILE_ORDER_HISTORY_DETAILS.NAME,
        titles.YOUR_TOKENS
      );
    }
    if (location.pathname.includes(USER_URLS.ORDER_CONFIRMATION.NORMAL_URL)) {
      pageName = getPageName(lang, USER_URLS.ORDER_CONFIRMATION.NAME, titles.ORDER_CONFIRMATION);
    }
    if (location.pathname.includes(USER_URLS.ONBOARDING_COURSE_ID.NORMAL_URL)) {
      //pageName = getPageName(lang, USER_URLS.ONBOARDING_COURSE.NAME, titles.ONBOARDING_COURSE);
      return;
    }

    if (location.pathname.includes(USER_URLS.PROFILE_MY_TEAM_MEMBER.NORMAL_URL)) {
      pageName = getMyTeamMemberPageName(lang);
    }

    if (location.pathname.includes(USER_URLS.COLLECTION.NORMAL_URL)) {
      const collectionId = location.pathname.replace(/.*\//, '');
      return getPageName(lang, USER_URLS.COLLECTION.NAME, titles.COLLECTION) + ' - ' + collectionId;
    }
  }

  //add notification number
  let title = '';
  if (num && num > 0) {
    title += '(' + num + ') ';
  }

  //add page number
  title += pageName;

  if (showAppName) {
    if (pageName) {
      title += ' - ';
    }

    //add application name (luxottica or essilor)
    title += appName;
  }

  document.title = title;
};

export const getBrowserAndVersion = () => {
  const ua = navigator.userAgent;
  let tem;
  const isChromeOnIos = ua.indexOf('CriOS') >= 0; //chrome on ios is normally detected as safari
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join('__').toLowerCase() + (isChromeOnIos ? ' isChromeOnIos' : '');
};

export const isMac = () => {
  return navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC';
};

// Get binary file using XMLHttpRequest
export const getBinary = file => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', file, false);
  xhr.overrideMimeType('text/plain; charset=x-user-defined');
  xhr.send(null);
  return xhr.responseText;
};

export const base64Encode = str => {
  var CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  var out = '',
    i = 0,
    len = str.length,
    c1,
    c2,
    c3;
  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff;
    if (i == len) {
      out += CHARS.charAt(c1 >> 2);
      out += CHARS.charAt((c1 & 0x3) << 4);
      out += '==';
      break;
    }
    c2 = str.charCodeAt(i++);
    if (i == len) {
      out += CHARS.charAt(c1 >> 2);
      out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4));
      out += CHARS.charAt((c2 & 0xf) << 2);
      out += '=';
      break;
    }
    c3 = str.charCodeAt(i++);
    out += CHARS.charAt(c1 >> 2);
    out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4));
    out += CHARS.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6));
    out += CHARS.charAt(c3 & 0x3f);
  }
  return out;
};

export const isExternalUrl = (linkUrl, targetWindow) => {
  const currentDomain = window.location.hostname;
  if (!linkUrl.startsWith('http://') && !linkUrl.startsWith('https://') && targetWindow?.toLowerCase() !== '_blank') {
    return false;
  } else if (targetWindow?.toLowerCase() === '_blank') {
    return true;
  } else {
    const linkDomain = new URL(linkUrl).hostname;
    return currentDomain !== linkDomain;
  }
}

export const getRelativeOrFullUrl = linkUrl => {

  try {
      if (!linkUrl.startsWith('http://') && !linkUrl.startsWith('https://')) {
          return linkUrl; //it's already a relative path
      }

      const url = new URL(linkUrl);

      if (!isExternalUrl(linkUrl)) {
          return url.pathname + url.search + url.hash; //if linkUrl has same domain as current, take the relative path (last part of url)
      } else {
          return linkUrl;
      }
  } catch (e) {
      return linkUrl;
  }
}