import React, { useEffect, useState } from "react";
import { Course, LangMap } from "@model/CoursesClass";
import { useSelector } from "@hooks/redux";
import CircleProgress from "@components/UI/CircleProgress";
import { getOnboardingStatistics, ValuesChart } from "@utility/onBoardingCourseUtility";
import { handleOnEnterKeyPress, isCompletedCourse } from "@utility/Api";
import LazySVG from "@components/LazySvg";
import CardLoader from "@components/UI/CardLoader";
import clsx from "@utility/clsx";

type OnBoardingChartProps = {
  className?: string;
  course: Course;
  variant: 'total' | 'lessons' | 'vc' | 'assessment' | 'saving progress';
  showRightSection?: boolean;
  onClick?: (e?) => void;
  showTick?: boolean;
  isInSavingCourse?: boolean
};

const OnBoardingChart = ({ className, course, variant, showRightSection = true, onClick, showTick = false, isInSavingCourse }: OnBoardingChartProps) => {
  const lang: LangMap = useSelector((state) => state.utils.lang);

  const [values, setValues] = useState<ValuesChart>(new ValuesChart());

  useEffect(() => {
    let valuesTemp = new ValuesChart();

    if (course) {
      valuesTemp = getOnboardingStatistics(course, variant);
    }

    setValues(valuesTemp);
  }, [course]);

  //Separate the "Saving progress" text in two so it always shows up in column
  const savingProgressLabelPt1 = lang?.COURSE_SAVING_PROGRESS.split(' ')[0];
  const savingProgressLabelPt2 = lang?.COURSE_SAVING_PROGRESS.split(' ').slice(1).join(' ');

  return (
    <div
      className={"onboarding-chart focus-outline " + (className ? className : '')}
      onClick={!!onClick ? onClick : null}
      onKeyDown={!!onClick ? (e) => handleOnEnterKeyPress(e, onClick, true) : null}
      tabIndex={!!onClick ? 0 : null}
    >
      <CircleProgress
        className={clsx({
          'hidden-circle' : isInSavingCourse
        })}
        firstPercentageValue={!isInSavingCourse ? (variant === 'vc' ? values.bookedPercentage : values.inProgressPercentage) : 0} //in progress
        secondPercentageValue={!isInSavingCourse ? (values.completedPercentage) : 0} //completed
        firstColor="#009BFF" //blue
        secondColor={className === "role-specific-program educational-paths__chart" || "onboardingcourse__chart" ? "#51ff77" : "#00FF9F"} //green
      >
        <section className="onboarding-chart__info-in-chart">
          { variant === "assessment" ?
            isCompletedCourse(course)
                ? <LazySVG src="/images/svg-icons/tick-2.svg" alt={lang.COMPLETED_CONTENT} className="tick-svg"/>
                : <LazySVG src={"/images/svg-icons/play-2.svg"} alt={lang.START_LEARNING} className="play-svg"/>
              : null
          }
          {variant !== "assessment" ?
            isInSavingCourse 
            ? (
                <>
                  <div className="onboarding-chart-saving-wrapper">
                    <span>{savingProgressLabelPt1}</span> 
                    <span>{savingProgressLabelPt2}</span> 
                    <CardLoader className="card-loader--with-chart"/>
                  </div>
                </>
              )
            :
            (isCompletedCourse(course) && showTick)
              ? <LazySVG src="/images/svg-icons/tick-2.svg" alt={lang.COMPLETED_CONTENT} className="tick-svg"/>
              :<>
                <span className="onboarding-chart__info-in-chart-numbers">
                  <span className="completed">{values.completed}</span>
                  <span className="slash">/</span>
                  <span className="total">{values.total}</span>
                </span>
                <span className="onboarding-chart__info-in-chart-completed-label">{lang?.ONBOARDINGCOURSE_GRAPH_COMPLETED}</span>
              </>
            : null
          }
        </section>
      </CircleProgress>
      {showRightSection &&
        <section className="onboarding-chart__info" aria-label={variant === 'vc' ? lang?.ONBOARDINGCOURSE_GRAPH_VC_STATUS : lang?.ONBOARDINGCOURSE_GRAPH_LESSON_STATUS}>
          <span className="onboarding-chart__title">
            {variant === 'vc' ? lang?.ONBOARDINGCOURSE_GRAPH_VC_STATUS : lang?.ONBOARDINGCOURSE_GRAPH_LESSON_STATUS}
          </span>
          <span className="onboarding-chart__statistic">
            {`${lang?.ONBOARDINGCOURSE_GRAPH_COMPLETED}:`}{" "}
            <span className="onboarding-chart__statistic-number completed">{values.completed}</span>
          </span>
          {variant === 'vc'
            ?
            <span className="onboarding-chart__statistic">
              {`${lang?.ONBOARDINGCOURSE_GRAPH_BOOKED}:`}{" "}
              <span className="onboarding-chart__statistic-number blue">{values.booked}</span>
            </span>
            :
            <span className="onboarding-chart__statistic">
              {`${lang?.ONBOARDINGCOURSE_GRAPH_INPROGRESS}:`}{" "}
              <span className="onboarding-chart__statistic-number blue">{values.inProgress}</span>
            </span>
          }
          <span className="onboarding-chart__statistic">
            {`${lang?.ONBOARDINGCOURSE_GRAPH_TODO}:`}{" "}
            <span className="onboarding-chart__statistic-number">{values.todo}</span>
          </span>
        </section>
      }
    </div>
  );
};

export default OnBoardingChart;