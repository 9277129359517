import React, { useEffect, useState } from "react";
import ModalEvolution from "./ModalEvolution";
import { useSelector } from "@hooks/redux";
import { useDispatch } from "react-redux";
import * as actions from '@redux-actions/';
import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { setRecoveringLicenseConfirmationState } from "@redux-actions/";

type ModalRecoveringLicenseProps = {
    show: boolean;
    email: string;
    token: string[];
    close: () => void;
};

const ModalRecoveringLicense = ({ show, close, email, token  }: ModalRecoveringLicenseProps) => {
    const breakpoint = useBreakpointBoolean();
    const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();


    const confirm = () => {
        setIsLoading(true);
        dispatch(actions.resetSendedToken(token))
        setIsLoading(false);
        dispatch(actions.setRecoveringLicenseConfirmationState(true))
        close();
    }

    let descriptionLabel = lang.POP_UP_RECOVER_DESCR.replace("{email}", email);
    descriptionLabel = descriptionLabel.replace("? ", "?\n");
    descriptionLabel = descriptionLabel.replace(". ", ".\n")

    return (
        <ModalEvolution
            close={close}
            show={show}
            hideX={breakpoint.isDesktopOrTabletLandscape}
            modalClassName="recovering-license-confirmation"
            title={lang?.POP_UP_RECOVER_LICENSE}
            description={
                breakpoint.isDesktopOrTabletLandscape ?
                    descriptionLabel :
                    descriptionLabel
            }
            mainButtonLabel={lang?.POP_UP_RECOVER_CONFIRM}
            mainButtonAction={confirm}
            mainButtonIsLoading={isLoading}
            secondaryButtonLabel={lang?.POP_UP_RECOVER_CANCEL}
            secondaryButtonAction={close}
        />
    );
}

export default ModalRecoveringLicense;