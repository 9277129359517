import React, { MouseEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as actions from '@redux-actions/';
import { CoursesMap, LangMap } from '@model/CoursesClass';
import { Notification } from '@model/Notification';
import {
  getElapsedTime,
  getNotificationsToShow,
  getNotifInfoByType,
} from '@utility/firebaseUtility';
import ButtonV2 from '@components/UI/ButtonV2';
import { UserProfile } from '@model/User';
import AnimationSlide, { animationSlideToTop } from '@components/animations/AnimationSlide';
import { useBreakpoint } from '@hooks/createBreakpoint';
import CardLoader from '@components/UI/CardLoader';
import useLivestreamCountdown from '@hooks/useLivestreamCountdown';
import { handleOnEnterKeyPress, printHTML } from '@utility/Api';
import { RootState } from 'src/store/store';
import { useHistory } from 'react-router-dom';
import ModalRight from '@components/UI/ModalRight';
import useInViewCarousel from '@hooks/useInViewCarousel';
import useModalTypes from '@hooks/useModalByType';
import AnimationExpand from './animations/AnimationExpand';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';

type Props = PropsFromRedux & {
  show: boolean;
  lang: LangMap;
  langNotif: LangMap;
  coursesMap: CoursesMap;
  isGetCourseCompleted: boolean;
  notifications: Array<Notification>;
  isNotifReady: boolean;
  isWritingDb: boolean;
  error: string;
  userProfile: UserProfile;
  toggleNotifAsRead: (notif: Notification) => void;
  markAllNotifsAsRead: (notifs: Array<Notification>) => void;
  handleNotifAction: (notif: Notification, action: string, history) => void;
  setShowNotifPanel: (show: boolean) => void;
};

const MAX_DESCR_CHARS = {
  desktop: 215,
  tablet: 143,
  phone: 100,
};

const NotificationsRightModal = (props: Props) => {
  const history = useHistory();

  const breakpoint = useBreakpoint();
  const [maxDescrChars, setMaxDescrChars] = useState(MAX_DESCR_CHARS.desktop);
  const [readMore, setReadMore] = useState({});

  const [timezone, setTimezone] = useState('UTC');
  const [showNotifs, setShowNotifs] = useState(false);
  const [showMarkAllAsRead, setShowMarkAllAsRead] = useState(false);

  const [notificationsToShow, setNotificationsToShow] = useState([]);
  const [notifsNotReadNum, setNotifsNotReadNum] = useState(0);
  const dispatch = useDispatch()

  useEffect(() => {
    setTimezone(props.userProfile ? props.userProfile.timezone : 'UTC');
  }, [props.userProfile]);

  useEffect(() => {
    if (breakpoint && MAX_DESCR_CHARS[breakpoint]) {
      setMaxDescrChars(MAX_DESCR_CHARS[breakpoint]);
    } else {
      setMaxDescrChars(MAX_DESCR_CHARS.desktop);
    }
  }, [breakpoint]);

  useEffect(() => {
    const notifsToShow = getNotificationsToShow(
      props.notifications,
      props.coursesMap,
      props.userProfile
    );
    setNotificationsToShow(notifsToShow);
    const notifsNotRead = getNotifsNotRead(notifsToShow);
    setNotifsNotReadNum(notifsNotRead.length);

    const showNotifsTemp = props.isNotifReady && props.userProfile && props.isGetCourseCompleted;
    setShowNotifs(showNotifsTemp);
    setShowMarkAllAsRead(showNotifsTemp && notifsNotRead.length > 0);
  }, [
    props.isNotifReady,
    props.userProfile,
    props.isGetCourseCompleted,
    props.userProfile,
    props.notifications,
    props.coursesMap,
  ]);

  const getNotifsNotRead = notifsToShow => {
    //when notifications to show change --> check if there is at least one notification not read
    if (!notifsToShow) {
      notifsToShow = getNotificationsToShow(
        props.notifications,
        props.coursesMap,
        props.userProfile
      );
    }

    if (notifsToShow && notifsToShow.length > 0) {
      return notifsToShow.filter(notif => !notif.read);
    }
    return [];
  };

  const toggleReadMore = (event: MouseEvent, id: string) => {
    event.stopPropagation();

    let readMoreTemp = { ...readMore };

    if (!readMoreTemp[id]) {
      readMoreTemp[id] = true;
    } else {
      readMoreTemp[id] = !readMoreTemp[id];
    }

    setReadMore(readMoreTemp);
  };

  const handleActionButton = (event, notif: Notification, action) => {
    event.stopPropagation();

    props.handleNotifAction(notif, action, history);
  };

  const modalTypes = useModalTypes();

  return (
    <ModalRight
      show={props.show}
      close={modalTypes.close}
      eventCloseOnClick={modalTypes.close}
      className="notifications-side-modal"
      animateSlide
      ignoreBlock={true}
    >
      <section aria-label={props.lang.NOTIFICATIONS_PAGE}>
        <h1 className="notifications-side-modal__title">
          <span>{props.lang?.NOTIFICATIONS_PAGE}</span>
          {!props.error && notifsNotReadNum > 0 ? (
            <span className="notifications-side-modal__number">
              {notifsNotReadNum < 100 ? notifsNotReadNum : '99+'}
            </span>
          ) : null}
        </h1>

        {props.error && (
          <div className="wrapper notificationV3__error">
            <p>{props.lang.ERROR_MESSAGE}</p>
          </div>
        )}

        {!showNotifs && !props.error && (
          <div className="wrapper notificationV3__loader">
            {/* <ThreeDots className="white double-size" /> */}
            <CardLoader />
          </div>
        )}

        {showNotifs && !props.error && (
          <>
            {notificationsToShow?.length > 0 ? (
              <>
                <div className="wrapper notifications">
                  <div
                    className={'notifications-list' + (showMarkAllAsRead ? ' show-mark-all' : '')}
                  >
                    {notificationsToShow.map(notif => {
                      const notifInfo = getNotifInfoByType(
                        notif,
                        props.coursesMap,
                        props.lang,
                        props.langNotif,
                        timezone
                      );
                      return (
                        <AnimationSlide
                          {...animationSlideToTop}
                          key={notif.id + notif.timestart}
                          className="notifications-side-modal__card"
                        >
                          <SingleNotification
                            lang={props.lang}
                            notif={notif}
                            notifInfo={notifInfo}
                            toggleNotifAsRead={props.toggleNotifAsRead}
                            timezone={timezone}
                            maxDescrChars={maxDescrChars}
                            readMore={readMore}
                            toggleReadMore={toggleReadMore}
                            handleActionButton={handleActionButton}
                          />
                        </AnimationSlide>
                      );
                    })}
                  </div>
                  {showNotifs && !props.error && notificationsToShow?.length > 0 && (
                    <div className="wrapper notifications-side-modal__mark-read">
                      {showMarkAllAsRead && (
                        <div className="notifications-side-modal__mark-read-wrapper">
                          <ButtonV2
                            variant="tertiary"
                            small
                            handleClick={() => {
                              props.markAllNotifsAsRead(notificationsToShow);
                            }}
                          >
                            {props.lang.MARK_ALL_AS_READ_LABEL}
                          </ButtonV2>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="notifications-side-modal__no-notifications">
                <p>{props.lang.NO_NOTIFICATIONS_LABEL}</p>
              </div>
            )}
          </>
        )}
      </section>
    </ModalRight>
  );
};

const SingleNotification = ({
  lang,
  notif,
  notifInfo,
  toggleNotifAsRead,
  timezone,
  maxDescrChars,
  readMore,
  toggleReadMore,
  handleActionButton,
}) => {
  const [ref, isVisible] = useInViewCarousel('.notifications-list', true, true);
  const [countdown] = useLivestreamCountdown(notifInfo, notif);

  return (
    <div
      className={
        'notifications-side-modal__single-notification focus-outline' +
        (!notif.read ? ' to-read' : '') +
        (!isVisible ? ' notification-opacity' : '')
      }
      id={notif.id}
      key={notif.id}
      onClick={() => {
        toggleNotifAsRead(notif);
      }}
      onKeyDown={event => {
        handleOnEnterKeyPress(event, () => toggleNotifAsRead(notif));
      }}
      tabIndex={0}
      ref={ref}
    >
      <div className="title-row flex justify-between">
        <p
          className={'title ' + (notifInfo.titleClassName ? notifInfo.titleClassName : '')}
          dangerouslySetInnerHTML={printHTML(
            notifInfo?.title?.replace(
              '{countdown}',
              `<span class="livestream-red">${countdown}</span>`
            )
          )}
        />
        <div className="timestamp">
          <span>{getElapsedTime(notif, lang, timezone)}</span>
          <div className={'not-read-marker ' + (notif.read ? 'read' : 'not-read')}></div>
        </div>
      </div>

      <div className="content">
        <div className="width-100">
          <p className="description">
            {((notifInfo.title && notifInfo.title.length) || 0) + notifInfo.description.length <
            maxDescrChars ? (
              notifInfo.description
            ) : (
              <>
                {readMore[notif.id]
                  ? notifInfo.description + ' '
                  : notifInfo?.description?.slice(
                      0,
                      maxDescrChars - ((notifInfo.title && notifInfo.title.length) || 0)
                    ) + '... '}
                <ButtonV2
                  variant="text-btn-no-arrow"
                  small
                  handleClick={e => {
                    toggleReadMore(e, notif.id);
                  }}
                >
                  {readMore[notif.id] ? lang.READ_LESS_LABEL : lang.READ_MORE_LABEL}
                </ButtonV2>
              </>
            )}
          </p>
        </div>
      </div>
      {notifInfo.action && (
        <ButtonV2
          variant="text-btn"
          small
          className="button-action"
          handleClick={e => {
            buildClickDataTrackingObject("mainNav_notifications_"+camelCase(notifInfo.actionText))
            handleActionButton(e, notif, notifInfo.action);
            window?.sessionStorage?.removeItem("Products_ContextId_Array");
          }}
        >
          {notifInfo.actionText}
        </ButtonV2>
      )}
      {!!notifInfo.valueBar && (
        <div className="card-evolution__progress">
          <AnimationExpand
            key={notifInfo.valueBar}
            initialWidth="0"
            finalWidth={notifInfo?.valueBar + '%'}
            animationEase="easeOut"
          ></AnimationExpand>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.utils.lang,
    langNotif: state.utils.langNotif,
    coursesMap: state.course.coursesMap,
    isGetCourseCompleted: state.course.isGetCourseCompleted,
    notifications: state.firebase.notifications,
    isNotifReady: state.firebase.isNotifReady,
    isWritingDb: state.firebase.isWritingDb,
    error: state.firebase.error,
    userProfile: state.user.userProfile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleNotifAsRead: notif => dispatch(actions.toggleNotifAsRead(notif)),
    markAllNotifsAsRead: notifs => dispatch(actions.markAllNotifsAsRead(notifs)),
    handleNotifAction: (notif, action, history) =>
      dispatch(actions.handleNotifAction(notif, action, history)),
    setShowNotifPanel: show => dispatch(actions.setShowNotifPanel(show)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NotificationsRightModal);
